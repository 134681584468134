import { LoadingSpinner } from '@axo/deprecated/util/ui-components';
import { OnAccept, Steps } from '@axo/insurance/feature/providers';
import {
  AcceptedQuoteContextProvider,
  getInitialInsuranceContextState,
  InsuranceProvider,
  OnEnd,
  StepProviderNorway,
} from '@axo/insurance/feature/providers/no';
import { AcceptModal, BankInfo, Redirecting } from '@axo/mypage/ui/atom';
import { breadcrumbs, MyPageFlags } from '@axo/mypage/util';
import { useGoToBank } from '@axo/mypage/util/dom';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_accept } from '@axo/shared/data-access/types';
import { useToastActions } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useFlags } from '@axo/shared/services/feature-flags';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import styles from './AcceptOfferModalWithInsurance.module.scss';

const PAUSE_LENGTH_TO_SHOW_REDIRECTING_TEXT = 2000;

const stepperClasses = {
  stepper: styles.stepper,
};

export function AcceptOfferModalWithInsurance({
  offer,
  closeModal,
  marketCountry,
  initialStep,
  onAcceptInsurance,
  onDeclineInsurance,
}: loan_quote_accept.TAcceptOfferModal & {
  initialStep?: keyof Steps;
}) {
  const log = useEventLogger(...breadcrumbs(offer));
  const goToBank = useGoToBank();
  const { displayToast } = useToastActions();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { 'offer-insurance-on-accept': flag_offer_insurance_on_accept } =
    useFlags(MyPageFlags);

  type EndPageType = 'redirectToBank' | 'bankInfo' | null;
  const [endPage, setEndPage] = useState<EndPageType>(null);
  const insuranceSourceTag = 'my-page-select-offer';

  useEventResource(KnownResourceType.Lender, offer?.LenderID);
  useEventResource(KnownResourceType.LoanRequest, offer?.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, offer?.ID);

  useEffect(() => {
    track({ event: 'My Page Insurance Viewed' });
    log(EventCode.OpenAcceptOfferModal);
  }, [log]);

  function onClose() {
    log(EventCode.CloseAcceptOfferModal);
    closeModal();
  }

  const onInsuranceAccept: OnAccept = {
    action: () => {
      track({ event: 'My Page Insurance Accepted' });
      onAcceptInsurance?.();
    },
  };

  const onInsuranceEnd: OnEnd = {
    action: async () => {
      track({ event: 'My Page Insurance Completed' });
      onDeclineInsurance?.();

      if (offer?.SigningLink) {
        setEndPage(() => 'redirectToBank');
        setTimeout(() => {
          setEndPage(() => null);
          goToBank(offer);
          closeModal();
        }, PAUSE_LENGTH_TO_SHOW_REDIRECTING_TEXT);
      } else {
        setEndPage(() => 'bankInfo');
      }
    },
    summaryButtonText:
      endPage === 'redirectToBank'
        ? t('Proceed to bank')?.toString()
        : t('Next')?.toString(),
  };

  const onInsuranceError = () => {
    displayToast({
      header: t('An error occured'),
      content: (
        <p>
          {t(
            'An error has occured while processing your insurance. Please continue with your loan and contact customer service at'
          )}{' '}
          <a href={`tel:${t('01234 56789')}`}>{t('01234 56789')}</a>
        </p>
      ),
      variety: 'error',
    });
    onClose();
  };

  const search = new URLSearchParams(window.location.search);
  const modalClasses = useMemo(() => {
    if (!flag_offer_insurance_on_accept) {
      return undefined;
    }
    return {
      content: styles.modal,
    };
  }, [flag_offer_insurance_on_accept]);

  return (
    <AcceptModal classes={modalClasses}>
      <AcceptedQuoteContextProvider acceptedQuote={offer}>
        <InsuranceProvider
          onEnd={onInsuranceEnd}
          spinner={
            <div className={styles.modalSpinner} role="progressbar">
              <LoadingSpinner colorVariable="--info-300" />
            </div>
          }
          sourceTag={insuranceSourceTag}
          initialState={getInitialInsuranceContextState(search)}
        >
          {endPage === 'redirectToBank' && <Redirecting />}
          {endPage === 'bankInfo' && (
            <div className={styles.bankLogo}>
              <BankInfo
                offer={offer}
                closeModal={closeModal}
                marketCountry={marketCountry}
              />
            </div>
          )}
          {!endPage && (
            <div
              className={clsx(
                styles.container,
                flag_offer_insurance_on_accept && styles.containerPadding
              )}
            >
              <StepProviderNorway
                onAccept={onInsuranceAccept}
                onEnd={onInsuranceEnd}
                onError={onInsuranceError}
                isEmbedded={true}
                sourceTag="my-page-select-offer"
                onClose={onClose}
                classes={
                  flag_offer_insurance_on_accept ? stepperClasses : undefined
                }
                initialStep={initialStep || 'selectCoverage'}
              />
            </div>
          )}
        </InsuranceProvider>
      </AcceptedQuoteContextProvider>
    </AcceptModal>
  );
}
