import { Icons } from '@axo/deprecated/util/ui-components';
import styles from './Footer.module.scss';
import { ReactNode } from 'react';

interface IFooter {
  email: string;
  tel: string;
  orgInfo: ReactNode;
  trustpilot: ReactNode;
}

export const Footer = ({ email, tel, orgInfo, trustpilot }: IFooter) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.trustpilot}>{trustpilot}</div>
        <div className={styles.contactInfo}>
          <a href={`tel:${tel}`} className={styles.detail}>
            <Icons.Telephone size="sm" />
            {tel}
          </a>
          <a href={`mailto:${email}`} className={styles.detail}>
            <Icons.Email size="sm" />
            {email}
          </a>
        </div>
        <div className={styles.orgInfo}>{orgInfo}</div>
      </div>
    </div>
  );
};
