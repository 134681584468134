import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getLoginOtp, verifyLoginOtp } from './api/otp';

export function useGetOTP() {
  return useMutation(getLoginOtp, { mutationKey: 'getOTP' });
}

export function useVerifyOTP() {
  const navigate = useNavigate();
  return useMutation(verifyLoginOtp, {
    mutationKey: 'verifyOTP',
    onSuccess: (response) => {
      const { ID } = response;
      navigate(`/?id=${ID}`);
    },
  });
}
