'use client';

import { useAnalytics } from '@axo/shared/services/analytics';
import { experiments } from './config/experiments.config';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

const trackedExperiments = new Set<string>();
export const _clearTrackedExperiments = () => {
  trackedExperiments.clear();
};

/**
 *
 *
 * @see https://docs.mixpanel.com/docs/tracking-methods/integrations/launchdarkly
 *
 */

export const useExperimentFlag = (
  experimentKey: keyof typeof experiments,
  defaultValue: unknown = null
) => {
  const [variation, setVariation] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);
  const client = useLDClient();
  const { trackExperiment } = useAnalytics();

  useEffect(() => {
    const fetchVariation = async () => {
      if (!client || !isLoading) return;

      await client.waitUntilReady();
      const variationValue = client.variation(
        experiments[experimentKey],
        defaultValue
      );

      if (!trackedExperiments.has(experimentKey)) {
        await trackExperiment({
          name: experimentKey,
          variation: variationValue,
        });
        trackedExperiments.add(experimentKey);
      }

      setVariation(variationValue);
      setIsLoading(false);
    };

    fetchVariation();
  }, [client, experimentKey, defaultValue, trackExperiment]);

  return { variation, isLoading };
};
