import { QuoteState } from '@axo/mypage/ui/atom';
import { useGoToBank } from '@axo/mypage/util/dom';
import {
  loan_quote,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { ListItem } from '../../ListItem/ListItem';

type TAcceptedQuoteProps = {
  acceptedQuote: loan_quote_presentation.EnrichedLoanQuote;
  marketCountry: string;
  offersUnlocked?: boolean;
};

export const AcceptedQuote = ({
  acceptedQuote,
  marketCountry,
  offersUnlocked,
}: TAcceptedQuoteProps) => {
  const goToBank = useGoToBank();

  return (
    <ListItem
      quote={acceptedQuote}
      state={
        acceptedQuote.Status ===
        loan_quote.LoanQuoteStatus.LoanApprovedForDisbursementDisbursedByBank
          ? QuoteState.Disbursed
          : QuoteState.Selected
      }
      action={() => goToBank(acceptedQuote)}
      marketCountry={marketCountry}
      offersUnlocked={offersUnlocked}
    />
  );
};
