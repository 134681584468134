import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';
import { send } from '../../lib/send';

export function getLoanQuotes({ url: { integration: baseURL }, token }: IAPI) {
  if (!baseURL)
    throw new Error(
      'No integration URL found! Configure integration URL with <DataAccessContext.Provider>'
    );
  return async (
    applicationID?: string
  ): Promise<loan_quote_presentation.GetQuotesResponse> =>
    send({
      baseURL,
      token,
      method: 'GET',
      path: `/loan-quote-presentation/application/${applicationID}/quotes`,
    });
}
