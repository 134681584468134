export enum WSReadyState {
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}
export enum CustomWSStatus {
  Error = 4,
  None = 5,
  Initiated = 6,
}

export type Status = WSReadyState | CustomWSStatus;
