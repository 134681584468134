'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { Theme } from './themes.types.ts';

type IThemeContext = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const ThemeContext = createContext<IThemeContext | null>(null);

type IThemeProviderProps = {
  theme: Theme;
  children: React.ReactNode;
};

/**
 * Load the theme stylesheet dynamically and provide the theme context
 *
 * **note:** for optimal performance, consider importing the theme stylesheet in the root of your application
 * @example import '@axo/ui-core/styles/base.axo-finans.scss';
 */

export const ThemeProvider = ({
  theme: initialTheme,
  children,
}: IThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(initialTheme);

  useEffect(() => {
    import(`../styles/base.${theme}.scss`);
  }, [theme]);

  useEffect(() => {
    setTheme(initialTheme);
  }, [initialTheme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div data-theme={theme}>{children}</div>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === null) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};
