import { MyPageFlags, useCustomerAnalytics } from '@axo/mypage/util';
import { useCreateMyPageLogin } from '@axo/shared/data-access/hooks';
import {
  loan_application,
  loan_quote_presentation,
  mypage_login,
  Product,
} from '@axo/shared/data-access/types';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useFlags } from '@axo/shared/services/feature-flags';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AuthMethod } from '../../AuthProvider/auth.types';
import { useAuthState } from '../../AuthProvider/useAuth';

type QuotePresentation = Pick<
  loan_quote_presentation.GetQuotesResponse,
  'PresentationHash'
>;

type Application = Pick<
  loan_application.LoanApplication,
  'ID' | 'MarketCountry' | 'CustomerID' | 'PersonID'
>;

export function useQuoteViewLogger(
  loanQuotePresentation: QuotePresentation | null | undefined,
  creditCardApplication: Application | null,
  unsecuredLoanApplication: Application | null
) {
  const { 'export-login': flag_export_login } = useFlags(MyPageFlags);

  const createLogin = useCreateMyPageLogin();
  const productForLastlogin = useRef<Set<Product>>(new Set());
  const { authMethod, isInternal } = useAuthState();
  const { track } = useAnalytics();
  const { trackApplication } = useCustomerAnalytics();

  const [pendingProductLog, setPendingProductLog] = useState<Product | null>(
    null
  );

  const logQuoteView = useCallback(
    async (product: Product) => {
      if (!flag_export_login) return;
      if (!loanQuotePresentation || !authMethod) {
        setPendingProductLog(product);
        return;
      }

      if (productForLastlogin.current.has(product)) return;
      productForLastlogin.current.add(product);

      const authMethodMap: Record<
        AuthMethod,
        mypage_login.CreateLogin['AuthKind']
      > = {
        'customer token': 'Direct',
        'application magic token': 'Token',
        'person sso token': 'BankId',
      };
      const currentAuthMethod: mypage_login.CreateLogin['AuthKind'] = isInternal
        ? 'Internal'
        : authMethodMap[authMethod];

      const generateMypageLogin = (application: Application | null) => {
        if (!application) return null;

        return {
          MarketCountry: application.MarketCountry,
          LoanApplicationID: application.ID,
          CustomerID: application.CustomerID,
          PersonID: application.PersonID,
          LoanQuotePresentationHash: loanQuotePresentation.PresentationHash,
          AuthKind: currentAuthMethod,
        } as mypage_login.CreateLogin;
      };

      let mypageLogin;
      if (product === Product.CreditCard && creditCardApplication?.PersonID) {
        mypageLogin = generateMypageLogin(creditCardApplication);
      } else if (
        product === Product.UnsecuredLoan &&
        unsecuredLoanApplication?.PersonID
      ) {
        mypageLogin = generateMypageLogin(unsecuredLoanApplication);
      }

      if (mypageLogin) {
        let error;

        try {
          await createLogin.mutateAsync(mypageLogin);
        } catch (e) {
          error = (e as Error).message;
        }

        await track({
          event: 'My Page Logger',
          params: {
            data: mypageLogin,
            error: error,
          },
        });

        await trackApplication({
          application: (product === Product.UnsecuredLoan
            ? unsecuredLoanApplication
            : creditCardApplication) as loan_application.LoanApplication,
        });
      }
    },
    [
      loanQuotePresentation,
      creditCardApplication,
      unsecuredLoanApplication,
      authMethod,
      createLogin,
      flag_export_login,
    ]
  );

  useEffect(() => {
    if (loanQuotePresentation && authMethod && pendingProductLog) {
      logQuoteView(pendingProductLog);
      setPendingProductLog(null);
    }
  }, [loanQuotePresentation, authMethod, logQuoteView, pendingProductLog]);

  return { logQuoteView };
}
