import { MyPageFlags } from '@axo/mypage/util';
import { useDurationFormatter } from '@axo/mypage/util/formatters';
import { useTranslation } from '@axo/mypage/util/translation';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { useMemo } from 'react';
import { useLocaleContext } from '../../../LocaleProvider';
import { getLoanDetailValues } from '../utils/getLoanQuoteDetailValues';
import styles from './QuoteDetail.module.scss';

interface IQuoteDetail {
  quote: loan_quote_presentation.EnrichedLoanQuote;
}

export function QuoteDetail({ quote }: IQuoteDetail) {
  const { t } = useTranslation();
  const durationFormatter = useDurationFormatter();
  const { currency } = useLocaleContext();
  const { flag_show_effective_interest_rate_column } =
    useTypedFlags(MyPageFlags);

  const quoteValues = useMemo(
    () => getLoanDetailValues({ quote, currency, durationFormatter }),
    [quote, currency, durationFormatter]
  );

  return (
    <div className={styles.detailsGrid}>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Loan amount')}</span>
        <span className={styles.value}>{quoteValues.quoteAmount}</span>
      </div>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Loan period')}</span>
        <span className={styles.value}>{quoteValues.duration}</span>
      </div>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Nominal interest rate')}</span>
        <span className={styles.value}>{quoteValues.nominalInterestRate}</span>
      </div>
      {flag_show_effective_interest_rate_column ? (
        <div className={styles.detailItem}>
          <span className={styles.label}>{t('Effective interest rate')}</span>
          <span className={styles.value}>
            {quoteValues.effectiveInterestRate}
          </span>
        </div>
      ) : (
        <div className={styles.detailItem}>
          <span className={styles.label}>{t('Loan cost')}</span>
          <span className={styles.value}>{quoteValues.loanCost}</span>
        </div>
      )}
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Monthly pay')}</span>
        <span className={styles.value}>{quoteValues.monthlyPay}</span>
      </div>
    </div>
  );
}
