import * as Sentry from '@sentry/react';

const isEnabled =
  import.meta.env.MODE !== 'development' &&
  import.meta.env.NODE_ENV !== 'test' &&
  import.meta.env.CI !== 'true';

console.info(
  `initialise Sentry... (enabled: ${isEnabled} for ${import.meta.env.MODE})`
);

Sentry.init({
  enabled: isEnabled,
  environment: import.meta.env.MODE,

  dsn: import.meta.env.VITE_SENTRY_DSN,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
      stickySession: true,
      networkDetailAllowUrls: [
        'https://api.axofinance.com',
        'https://integration.axofinance.com',
        'wss://ws.axofinance.com',
      ],
    }),
    Sentry.httpClientIntegration(),
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
