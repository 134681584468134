import {
  useApplicationStatusUtils,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import { loan_application, Product } from '@axo/shared/data-access/types';
import { useEffect, useState } from 'react';

type IQuoteTabs = {
  isLoading: boolean;
  unsecuredLoanApplication: loan_application.LoanApplication | null | undefined;
  creditCardApplication: loan_application.LoanApplication | null | undefined;
  setTab: (tab: Product) => void;
  requestedProduct?: Product;
};

export const useFindDefaultTab = ({
  isLoading,
  unsecuredLoanApplication,
  creditCardApplication,
  setTab,
  requestedProduct,
}: IQuoteTabs) => {
  const { isInAllApplicationStatusGroups, isInAnyApplicationStatusGroup } =
    useApplicationStatusUtils();
  const { data: unsecuredLoanQuotes, isSuccess: isSuccessUL } =
    useLoanQuotePresentation(unsecuredLoanApplication?.ID);
  const { data: creditCardQuotes, isSuccess: isSuccessCC } =
    useLoanQuotePresentation(creditCardApplication?.ID);
  const [hasRedirected, setHasRedirected] = useState(false);
  const acceptedQuoteUL = unsecuredLoanQuotes?.AcceptedLoanQuotes[0];
  const acceptedQuoteCC = creditCardQuotes?.AcceptedLoanQuotes[0];
  const acceptableQuotesCC = creditCardQuotes?.AcceptableLoanQuotes;

  useEffect(() => {
    const findTab = () => {
      if (requestedProduct) {
        return requestedProduct;
      }

      if (!unsecuredLoanApplication && creditCardApplication) {
        return Product.CreditCard;
      }
      if (!creditCardApplication && unsecuredLoanApplication) {
        return Product.UnsecuredLoan;
      }

      if (
        // UL is inactive (or not disbursed) and CC is active (or disbursed)
        (!isInAnyApplicationStatusGroup(
          unsecuredLoanApplication?.Status as loan_application.ApplicationStatus,
          [
            loan_application.StatusGroup.Active,
            loan_application.StatusGroup.Disbursed,
          ]
        ) &&
          isInAnyApplicationStatusGroup(
            creditCardApplication?.Status as loan_application.ApplicationStatus,
            [
              loan_application.StatusGroup.Active,
              loan_application.StatusGroup.Disbursed,
            ]
          )) ||
        // No UL offers but has active UL application and offers for CC.
        (!acceptedQuoteUL?.DisbursedAt &&
          !unsecuredLoanQuotes?.AcceptableLoanQuotes.length &&
          (!!acceptableQuotesCC?.length || !!acceptedQuoteCC) &&
          isInAllApplicationStatusGroups(
            unsecuredLoanApplication?.Status as loan_application.ApplicationStatus,
            loan_application.StatusGroup.Active
          )) ||
        // UL is disbursed + CC has unaccepted offers.
        (!!acceptedQuoteUL?.DisbursedAt &&
          !!acceptableQuotesCC?.length &&
          !acceptedQuoteCC) ||
        // UL and CC are disbursed. CC is last disbursed product.
        Date.parse(String(acceptedQuoteCC?.DisbursedAt)) >
          Date.parse(String(acceptedQuoteUL?.DisbursedAt))
      ) {
        return Product.CreditCard;
      } else {
        return Product.UnsecuredLoan;
      }
    };
    const isReady = !!unsecuredLoanApplication || !!creditCardApplication;
    const isDoneUL = isSuccessUL || !unsecuredLoanApplication?.ID;
    const isDoneCC = isSuccessCC || !creditCardApplication?.ID;

    if (isDoneUL && isDoneCC && !hasRedirected && (isReady || !isLoading)) {
      setTab(findTab());
      setHasRedirected(true);
    }
  }, [
    acceptableQuotesCC?.length,
    acceptedQuoteCC,
    acceptedQuoteUL?.DisbursedAt,
    hasRedirected,
    isInAllApplicationStatusGroups,
    isInAnyApplicationStatusGroup,
    isSuccessCC,
    isSuccessUL,
    setTab,
    unsecuredLoanQuotes?.AcceptableLoanQuotes.length,
    creditCardApplication,
    unsecuredLoanApplication,
    isLoading,
  ]);
  return { isLoading: !hasRedirected };
};
