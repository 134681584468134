import { useTranslation } from '@axo/mypage/util/translation';
import { Modal } from '@axo/ui-core/components/Modal';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Heading, Text } from '@axo/ui-core/components/typography';
import styles from './ContactModal.module.scss';

export function ContactModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      hasClose
      onClose={onClose}
      className={styles.contactModal}
    >
      <Stack gap="xs" className={styles.content}>
        <Heading size="s">{t('Need help?')}</Heading>
        <Text as="p">
          {t('Choosing a loan can be difficult, but we are here for you')}
        </Text>
      </Stack>
      <Modal.Actions className={styles.footer}>
        <Button
          href={`tel:${t('01234 56789')}`}
          prefix={<Icon name="phone-ring-solid" size="xs" />}
        >
          {t('01234 56789')}
        </Button>
        <Button
          variant="tertiary"
          href={`mailto:${t('customerservice@axofinance.com')}`}
          prefix={<Icon name="envelope-solid" size="xs" />}
        >
          {t('customerservice@axofinance.com')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
