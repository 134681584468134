import { Button, H4 } from '@axo/deprecated/util/ui-components';
import { IAcceptQuote } from '@axo/mypage/data-access';
import { AcceptModal } from '@axo/mypage/ui/atom';
import { breadcrumbs } from '@axo/mypage/util';
import { useGoToBank } from '@axo/mypage/util/dom';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import { useEffect } from 'react';
import styles from './AcceptOfferModal.module.scss';

export interface StoredOffer {
  offer: loan_quote_presentation.PresentedLoanQuote;
  acceptableIndex: number;
}

interface IAcceptOfferModal {
  offer: StoredOffer;
  closeModal: () => void;
  accept: IAcceptQuote;
  marketCountry: string;
  doAccept?: (offer: StoredOffer) => Promise<void>;
}

export function AcceptOfferModal({
  offer,
  closeModal,
  accept,
  marketCountry,
  doAccept,
}: IAcceptOfferModal) {
  const goToBank = useGoToBank();
  const log = useEventLogger(...breadcrumbs(offer.offer));
  const { t } = useTranslation();

  useEventResource(KnownResourceType.Lender, offer.offer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, offer.offer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, offer.offer.ID);

  async function handleAccept() {
    if (doAccept) {
      await doAccept(offer);
      if (offer.offer.SigningLink) {
        await goToBank(offer.offer);
      }
      closeModal();
    }
  }

  useEffect(() => {
    log(EventCode.OpenAcceptOfferModal);
  }, [log]);

  function onClose() {
    log(EventCode.CloseAcceptOfferModal);
    closeModal();
  }

  return (
    <AcceptModal
      actionBar={{
        title: (
          <>
            {t('Do you want to continue with')}{' '}
            <span className={styles.bold}>{offer.offer.Lender?.Name}</span>?
          </>
        ),
        buttons: (
          <>
            <Button variant="secondary" onClick={onClose}>
              {t('Go back')}
            </Button>
            <Button
              variant="primary"
              onClick={handleAccept}
              disabled={accept.isLoading}
              loading={accept.isLoading}
            >
              {t('Confirm and proceed to bank page')}
            </Button>
          </>
        ),
      }}
      classes={{
        root: styles.modalRoot,
        content: styles.modalContent,
        actionBar: { root: styles.actionContainer, buttons: styles.buttons },
      }}
    >
      <H4>{t('Confirm your selection')}</H4>
      <span className={styles.subtitle}>
        {t('You have chosen the offer from')}
      </span>
      <div className={styles.lenderLogoContainer}>
        <div className={styles.lenderLogo}>
          <LenderLogo
            lender={offer.offer.Lender}
            marketCountry={marketCountry}
          />
        </div>
      </div>
    </AcceptModal>
  );
}
