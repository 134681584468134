import { createPortal } from 'react-dom';
import { ReactNode } from 'react';

const headRoot = document.head;

interface HeadProps {
  children: ReactNode;
}

export const HeadPortal = ({ children }: HeadProps) => {
  return createPortal(children, headRoot);
};
