import { Dispatch } from 'react';
import { AuthAction, AuthActionPayload } from './auth.types';

export const login = (
  dispatch: Dispatch<AuthAction>,
  payload: AuthActionPayload
) => {
  dispatch({
    type: 'login',
    payload,
  });
};

export const loggedIn = (
  dispatch: Dispatch<AuthAction>,
  payload: AuthActionPayload
) => {
  dispatch({
    type: 'loggedIn',
    payload,
  });
};

export const logout = (dispatch: Dispatch<AuthAction>) => {
  dispatch({ type: 'logout' });
};

export const loggedOut = (dispatch: Dispatch<AuthAction>) => {
  dispatch({ type: 'loggedOut' });
};
