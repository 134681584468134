import { ReactNode } from 'react';
import { lender } from '@axo/shared/data-access/types';
import * as fi_logos from './logo/fi';
import * as no_logos from './logo/no';
import * as se_logos from './logo/se';

const logoListByMarketCountry = (marketCountry: string) => {
  switch (marketCountry) {
    case 'FI':
      return fi_logos;
    case 'NO':
      return no_logos;
    case 'SE':
      return se_logos;
    default:
      return undefined;
  }
};

const loadLenderLogo = (
  lenderId: string,
  marketCountry: string,
  preferWide: boolean,
  aceSpecificLogo: boolean
): string | undefined => {
  try {
    const logoMap = logoListByMarketCountry(marketCountry) as {
      [k: string]: string | undefined;
    };
    if (!logoMap) return undefined;
    const aceLogoname = `${lenderId}_ace`;
    if (aceSpecificLogo && logoMap[aceLogoname]) {
      return logoMap[aceLogoname];
    }
    const wideLogoName = `${lenderId}_wide`;
    if (preferWide && logoMap[wideLogoName]) {
      return logoMap[wideLogoName];
    }
    if (logoMap[lenderId]) {
      return logoMap[lenderId];
    }
    return undefined;
  } catch (error) {
    console.error('Error loading lender logo:', error);
    return undefined;
  }
};

export type ILender = Pick<lender.Lender, 'ShortName' | 'Name'>;

export type ILenderLogo = {
  lender: ILender;
  className?: string;
  grayscale?: boolean;
  marketCountry: string;
  fallback?: ReactNode;
  preferWide?: boolean;
  aceSpecificLogo?: boolean;
};

export const LenderLogo = ({
  lender,
  className,
  grayscale = false,
  marketCountry,
  fallback = null,
  preferWide = false,
  aceSpecificLogo = false,
}: ILenderLogo) => {
  const src = loadLenderLogo(
    lender.ShortName,
    marketCountry,
    preferWide,
    aceSpecificLogo
  );
  return (
    <>
      {!src ? (
        fallback
      ) : (
        <img
          src={src}
          alt={`Logo ${lender?.Name}`}
          className={className}
          style={{ ...(grayscale ? { filter: 'grayscale(100%)' } : {}) }}
        />
      )}
    </>
  );
};
