import { Button, H3 } from '@axo/deprecated/util/ui-components';
import styles from './NoApplication.module.scss';
import { ReactNode } from 'react';

interface NoApplicationProps {
  icon: ReactNode;
  title: string;
  description: string;
  buttonLabel: string;
  action?: () => void;
}

export const NoApplication = ({
  icon,
  title,
  description,
  buttonLabel,
  action,
}: NoApplicationProps) => {
  return (
    <div className={styles.container}>
      {icon}
      <div className={styles.text}>
        <H3 className={styles.title}>{title}</H3>
        <p>{description}</p>
      </div>
      <Button onClick={action}>{buttonLabel}</Button>
    </div>
  );
};
