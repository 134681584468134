import { Icons } from '@axo/shared/ui/atoms';
import styles from './LoadingGears.module.scss';

interface LoadingGearsWithTextProps {
  text: string;
}

export const LoadingGears = ({ text }: LoadingGearsWithTextProps) => {
  return (
    <div className={styles.loadingContainer} role="progressbar">
      <Icons.LoadingGears classes={{ root: styles.loadingGears }} />
      <span className={styles.loadingText}>{text}</span>
    </div>
  );
};
