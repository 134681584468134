import { usePuzzelChat } from '@axo/shared/services/puzzel-chat';
import { useEffect } from 'react';

export function PuzzelWrapper({
  marketCountry,
  active,
  currentUrl,
  applicationID,
}: {
  marketCountry: 'no' | 'se' | 'fi' | 'dk';
  active: boolean;
  currentUrl: string;
  applicationID?: string;
}) {
  const { updateChatVariables } = usePuzzelChat({
    country: marketCountry,
    active: active,
    delay: 15000,
  });

  useEffect(() => {
    const variables: Record<string, unknown> = {};
    variables.url = currentUrl;
    if (applicationID) variables.applicationID = applicationID;
    if (variables.url || variables.applicationID)
      updateChatVariables(variables);
  }, [currentUrl, updateChatVariables, applicationID]);

  return null;
}
