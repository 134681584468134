import { Button } from '@axo/deprecated/util/ui-components';
import { QuoteItemContainer, QuoteState } from '@axo/mypage/ui/atom';
import { useTranslation } from '@axo/mypage/util/translation';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { CreditCardOverviewCard } from '@axo/shared/ui/core';
import { classNames } from '@axo/shared/util/dom';
import { currencyFormatter } from '@axo/shared/util/formatters';

import { useLocaleContext } from '../../index';

import styles from '../LoanQuoteListItem/LoanQuoteListItem.module.scss';

interface ILoanQuoteListItem {
  quote: loan_quote_presentation.EnrichedLoanQuote;
  action?: () => void;
  state: QuoteState;
  isLoadingAction?: boolean;
  disableUserAccept?: boolean;
  offersUnlocked?: boolean;
}

const creditCardInfoMap = [
  {
    lenderShortName: 'BN',
    title: 'Bank Norwegian',
    subtitle: 'Cashpoints eller cashback på alle kjøp',
    sellingPoints: [
      'Cashpoints eller cashback på alle kjøp',
      'Gebyrfritt kontantuttak',
      'Reise og dagligvareforsikring inkludert',
      'Gebyrfri bruk',
    ],
    costExample:
      'Eff. rente 24,4%, kr. 15 000 over 1 år. Kostnad: kr. 1 849. Total: kr. 16 849',
  },
  {
    lenderShortName: 'BBB',
    title: 'TF Bank Mastercard',
    subtitle: 'Helt gebyrfritt',
    sellingPoints: [
      'Omfattende fordelsprogram',
      'Fleksibel betaling og ingen gebyrer',
      'Gratis reise- og avbestilingsforsikring',
    ],
    costExample:
      'Eff. rente 26,69%, kr. 25 000 over 1 år. Kostnad: kr. 3 337 kr. Totalt kr. 28 337',
  },
  {
    lenderShortName: 'KB',
    title: 'Morrow Bank Mastercard',
    subtitle: 'Bonus på alle kjøp',
    sellingPoints: [
      'Helt gebyrfritt',
      'Reise- og avbestillingsforsikring, ID- tyveriforsikring og kjøpsforsikring inkludert',
      'Tjen bonuspoeng på kjøp',
    ],
    costExample:
      'Eff. rente 28,77%, kr. 12 000 over 1 år. Kostnad: kr. 1 726 kr. Totalt: kr. 13 726',
  },
  {
    lenderShortName: 'REM',
    title: 're:member',
    subtitle: 'Rabatter i over 200 butikker',
    sellingPoints: [
      'Apple Pay og Google Pay for enkel betaling',
      'Gode rabatter i over 200 nettbutikker\n',
      'Gratis reise- og avbestillingsforsikring',
    ],
    costExample:
      'Eff. rente 30,33%, kr. 15 000 over 1 år. Kostnad kr. 1 717. Totalt kr. 16 717',
  },
  {
    lenderShortName: 'IB',
    title: 'Instapay',
    subtitle: 'Markedets laveste rente',
    sellingPoints: [
      'En av markedets laveste renter',
      'Kortet for deg som betaler ned litt over tid',
      'Få eksklusive tilbud og rabatter',
    ],
    costExample:
      'Eff. rente 21,66%, kr. 45 000 over 1 år. Kostnad kr. 4 963. Totalt kr. 49 963',
  },
];

export function CreditCardListItem({
  quote,
  action,
  state,
  isLoadingAction,
  disableUserAccept,
  offersUnlocked,
}: ILoanQuoteListItem) {
  const { t } = useTranslation();
  const { currency } = useLocaleContext();

  const isSelected = state === QuoteState.Selected;
  const isPreviouslySelected = state === QuoteState.PreviouslySelected;

  const creditCardInfo = creditCardInfoMap.find(
    (info) => info.lenderShortName === quote.Lender.ShortName
  );

  const lender = quote.Lender;
  const isDisbursed = state === QuoteState.Disbursed;

  const getText = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return `${t('Recommended')}`;
      case QuoteState.Selected:
        return `${t('Selected')}`;
      case QuoteState.PreviouslySelected:
        return `${t('Previously selected')}`;
      case QuoteState.Disbursed:
        return `${t('Dispatched')}`;
      case QuoteState.Cancelled:
        return `${t('Cancelled')}`;
      default:
        return '';
    }
  };

  const getSelectButtonText = () => {
    if (isSelected || isPreviouslySelected) return t('Continue');
    if (!offersUnlocked) return t('Waiting for more offers');
    return t('Choose');
  };

  return (
    <QuoteItemContainer state={state} getText={getText}>
      <CreditCardOverviewCard
        variant={'comparison'}
        button={
          <Button
            variant="primary"
            onClick={action}
            loading={isLoadingAction}
            classes={{
              root: classNames(
                styles.button,
                styles.actionButton,
                isPreviouslySelected && styles.black
              ),
            }}
            disabled={isDisbursed || isLoadingAction || disableUserAccept}
          >
            {getSelectButtonText()}
          </Button>
        }
        cardSection={{
          creditCardProviderShortName: quote.Lender.ShortName,
          title: creditCardInfo?.title ?? '',
          subtitle: creditCardInfo?.subtitle ?? '',
          tagPosition: 'left',
        }}
        benefitsSection={{
          benefits: creditCardInfo?.sellingPoints ?? [],
          info: creditCardInfo?.costExample,
        }}
        infoSection={[
          {
            title: `${t('Effective interest rate')}`,
            value: `${quote.Lender.CreditCardInterestRate ?? ''}%`,
          },
          {
            title: `${t('Credit limit')}`,
            value: currencyFormatter(quote.Amount, currency),
          },
          {
            title: `${t('Interest free period')}`,
            value: `${lender.CreditCardInterestFreeDays ?? ''} ${t('days')}`,
          },
        ]}
      />
    </QuoteItemContainer>
  );
}
