import { useContext } from 'react';
import { AuthStateContext, AuthDispatchContext } from './auth.context';
import { AuthActionPayload } from './auth.types';
import * as actions from './auth.actions';

export const useAuthState = () => {
  const state = useContext(AuthStateContext);
  if (state === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return state;
};

export const useAuthDispatch = () => {
  const dispatch = useContext(AuthDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useAuthDispatch must be used within an AuthProvider');
  }

  return {
    dispatch,
    login: (payload: AuthActionPayload) => actions.login(dispatch, payload),
    loggedIn: (payload: AuthActionPayload) =>
      actions.loggedIn(dispatch, payload),
    logout: () => actions.logout(dispatch),
    loggedOut: () => actions.loggedOut(dispatch),
  };
};
