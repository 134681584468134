import { Button, H4, Icons } from '@axo/deprecated/util/ui-components';
import { IAcceptQuote } from '@axo/mypage/data-access';
import { AcceptModal } from '@axo/mypage/ui/atom';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import styles from './ChangeAcceptOfferModal.module.scss';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { breadcrumbs } from '@axo/mypage/util';
import { useEffect } from 'react';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { useTranslation } from '@axo/mypage/util/translation';
import { useGoToBank } from '@axo/mypage/util/dom';

interface IAcceptOfferModal {
  oldOffer: loan_quote_presentation.EnrichedLoanQuote;
  newOffer: loan_quote_presentation.PresentedLoanQuote;
  acceptableQuoteIndex: number;
  marketCountry: string;
  closeModal: () => void;
  accept: IAcceptQuote;
}

export function ChangeAcceptOfferModal({
  oldOffer,
  newOffer,
  acceptableQuoteIndex,
  marketCountry,
  closeModal,
  accept,
}: IAcceptOfferModal) {
  const goToBank = useGoToBank();
  const { t } = useTranslation();
  const oldLender = oldOffer.Lender;
  const newLender = newOffer.Lender;
  const log = useEventLogger(
    ...breadcrumbs(newOffer),
    ...breadcrumbs(oldOffer)
  );

  useEventResource(KnownResourceType.Lender, newOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, newOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, newOffer.ID);
  useEventResource(KnownResourceType.Lender, oldOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, oldOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, oldOffer.ID);

  useEffect(() => {
    log(EventCode.OpenChangeOfferModal, { newQuoteID: newOffer.ID });
  }, [log, newOffer, oldOffer]);

  async function handleAccept() {
    await log(EventCode.ChangeOffer, { newQuoteID: newOffer.ID });
    await accept.acceptAndWait(marketCountry, newOffer, acceptableQuoteIndex);
    if (newOffer.SigningLink) {
      await goToBank(newOffer);
    }
    closeModal();
  }

  function onClose() {
    log(EventCode.CloseChangeOfferModal, { newQuoteID: newOffer.ID });
    closeModal();
  }

  return (
    <AcceptModal
      onBackgroundClick={onClose}
      onCloseButton={onClose}
      actionBar={{
        title: t('Are you sure you want to change your selection?'),
        buttons: (
          <>
            <Button variant="secondary" onClick={onClose}>
              {t('Go back')}
            </Button>
            <Button
              variant="primary"
              onClick={handleAccept}
              loading={accept.isLoading}
              disabled={accept.isLoading}
            >
              {t('Confirm and proceed')}
            </Button>
          </>
        ),
      }}
    >
      <H4>{t('Change your selection')}</H4>
      <span className={styles.subtitle}>
        {t(
          'You are changing your loan offer. Please notice that the previous selection will be closed/cancelled and you can not reselect it'
        )}
      </span>
      <div className={styles.lenders}>
        <div className={styles.lenderLogoContainer}>
          <div className={styles.lenderLogo}>
            {oldLender && (
              <LenderLogo lender={oldLender} marketCountry={marketCountry} />
            )}
          </div>
          {t('Currently selected')}
        </div>
        <Icons.ArrowRight
          size="sm"
          color={{ primary: '--success-500' }}
          classes={{ root: styles.arrow }}
        />
        <div className={styles.lenderLogoContainer}>
          <div className={styles.lenderLogo}>
            {newLender && (
              <LenderLogo lender={newLender} marketCountry={marketCountry} />
            )}
          </div>
          {t('New selection')}
        </div>
      </div>
    </AcceptModal>
  );
}
