export const MyPageFlags = {
  flag_show_insurance_on_accept: 'show-insurance-on-accept',
  flag_show_change_accepted_offer: 'show-change-accept-offer',
  flag_show_split_quote_details: 'show-split-quote-details',
  flag_show_next_steps: 'show-next-steps',
  flag_show_legacy_norway_status_messages: 'show-legacy-norway-status-messages',
  flag_show_lender_info: 'show-lender-info',
  flag_show_loan_type_explanation: 'show-loan-type-explanation',
  flag_show_credit_cards: 'show-credit-cards',
  // flag_show_text_in_loading_gears: 'show-text-in-loading-gears',
  flag_show_cancelled_offers: 'show-cancelled-offers',
  flag_show_recommended_offer_after_accept:
    'show-recommended-offer-after-accept',
  flag_show_recommended_offer_on_credit_card:
    'show-recommended-offer-on-credit-card',
  flag_show_kundo_chat: 'show-kundo-chat',
  flag_show_credit_score_when_no_offers: 'show-credit-score-when-no-offers',
  flag_show_insurance_overview: 'show-insurance-overview',
  flag_show_header_text: 'show-header-text',
  flag_show_see_more_offers_collapse: 'show-see-more-offers-collapse',
  flag_show_change_payment_card: 'show-change-payment-card',
  // FIXME `useFlags` vs `useTypedFlags`
  flag_export_login: 'export-login',
  'export-login': 'export-login',
  //
  flag_show_otp_login: 'flag_show_otp_login',
  flag_show_bankID_login: 'flag_show_bankID_login',
  flag_show_effective_interest_rate_column:
    'show-effective-interest-rate-column',
  flag_show_puzzel_chat: 'show-puzzel-chat',
  flag_show_inline_offer_explanation: 'show-inline-offer-explanation',
  'unlock-offers-on-progress-update': 'unlock-offers-on-progress-update',
  'show-my-page-communication-elements': 'show-my-page-communication-elements',
  'offer-insurance-on-accept': 'offer-insurance-on-accept',
  'show-login-button-on-my-page-header-info':
    'show-login-button-on-my-page-header-info',
  'accept-insurance-widget': 'accept-insurance-widget',
};
