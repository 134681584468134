import { MarketCountryCode } from '@axo/shared/types';
import { Button } from '@axo/ui-core/components/Button';
import BankIDSwedenLogo from '../@assets/bank-id-sweden.svg?react';
import BankIDLogo from '../@assets/bank-id.svg?react';
import { CriiptoProvidersProps } from './criiptoProviderMapping';
import styles from './providers.module.scss';

export const BankID = ({
  country,
  label,
  href,
  onClick,
}: CriiptoProvidersProps) => {
  const Icon =
    country === MarketCountryCode.Sweden ? BankIDSwedenLogo : BankIDLogo;

  return (
    <Button
      className={styles.bankId}
      href={href}
      target="_self"
      rel="noreferrer"
      onClick={onClick}
      data-testid="criipto-login"
      data-country={country}
    >
      <Icon />
      <span className={styles.label}>{label}</span>
    </Button>
  );
};
