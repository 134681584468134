import {
  EventCode,
  Log,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { breadcrumbs, useCustomerAnalytics } from '@axo/mypage/util';

export function useGoToBank() {
  const log = useEventLogger();
  const { trackOffer } = useCustomerAnalytics();
  return goToBank(log, trackOffer);
}

function goToBank(
  log: Log,
  trackOffer: ReturnType<typeof useCustomerAnalytics>['trackOffer']
) {
  return async (quote: loan_quote_presentation.EnrichedLoanQuote) => {
    if (quote.SigningLink) {
      await trackOffer({
        event: 'My Page Offer Redirected to bank',
        offer: quote as loan_quote_presentation.PresentedLoanQuote,
      });
      await log(EventCode.GoToBank, {}, ...breadcrumbs(quote));
      window.location.href = quote.SigningLink;
    }
  };
}
