import { useQuery } from 'react-query';
import { rootKeys } from '../cache-keys/rootKeys';
import { useAPI } from '@axo/shared/data-access/provider';
import { loan_application_api } from '@axo/shared/data-access/api';

export function useApplicationStatuses() {
  const api = useAPI();
  const cacheKey = rootKeys.applicationStatuses();

  return useQuery(cacheKey, loan_application_api.getApplicationStatuses(api));
}
