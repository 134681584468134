import {
  applicationKeys,
  useCustomer,
  useLoanApplication,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import {
  customer,
  LoanApplication,
  permissions,
} from '@axo/shared/data-access/types';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAuthDispatch } from '../useAuth';

/**
 *  Handles the direct access Auth with jwt token and application id
 *
 *  loads the application, stores on loan application context
 *  loads the customer, stores on data access context
 */
export function useCustomerAuthToken(
  jwt: null | string,
  applicationId: string | null,
  onSuccess?: (data: LoanApplication) => void,
  onError?: () => void
) {
  const { state, dispatch } = useContext(DataAccessContext);
  const { dispatch: appDispatch } = useContext(LoanApplicationContext);
  const client = useQueryClient();
  const { identify } = useAnalytics();
  const { loggedIn } = useAuthDispatch();
  const [customer, setCustomer] = useState<{ id: string }>();

  // set the jwt token on the data access context to allow to make the api calls
  // jwt contains a `customerID` and has the `Customer` role
  useEffect(() => {
    !!jwt &&
      !!applicationId &&
      dispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: { JWT: jwt },
      });
  }, [jwt, applicationId]);

  const _onError = () => {
    onError?.();
  };

  /**
   * `LoanApplication`
   */

  const onSuccessLoanApplication = (response: LoanApplication) => {
    // cache
    client.setQueryData(
      applicationKeys.root({ applicationID: applicationId ?? '' }),
      response
    );

    // set the application data on the loan application context
    appDispatch({
      type: 'Set application',
      scope: { parentType: 'application' },
      payload: response,
    });

    onSuccess?.(response);
  };

  // the useQuery hook will only call the api if there is an applicationId
  const loanApplicationResult = useLoanApplication(applicationId);

  useEffect(() => {
    if (loanApplicationResult.isError) _onError();
    if (loanApplicationResult.isSuccess)
      onSuccessLoanApplication(loanApplicationResult.data);
  }, [loanApplicationResult.isError, loanApplicationResult.isSuccess]);

  /**
   * `Customer`
   */

  const onSuccessCustomer = async (response: customer.Customer) => {
    await identify({
      uuid: response.ID,
      email: response.Email,
    });

    loggedIn({
      user: {
        id: response.ID,
      },
      authMethod: 'customer token',
    });
  };

  const customerResult = useCustomer(customer?.id);

  // only set the `customer.id` for a `Customer` role (avoiding other tokens to trigger)
  useEffect(() => {
    state.user.customerID &&
      state.user.roles.some(
        (role) => role.Name === permissions.RoleName.Customer
      ) &&
      setCustomer({ id: state.user.customerID });
  }, [state.user.customerID, state.user.roles]);

  useEffect(() => {
    if (customerResult.isError) _onError();
    if (customerResult.isSuccess) onSuccessCustomer(customerResult.data);
  }, [customerResult.isError, customerResult.isSuccess]);

  return {
    isLoading: loanApplicationResult.isLoading || customerResult.isLoading,
    isError: loanApplicationResult.isError || customerResult.isError,
  };
}
