export enum RoleName {
  Lender = 'Lender',
  Affiliate = 'Affiliate',
  Partner = 'Partner',
  Service = 'Service',
  Customer = 'Customer',
  CustomerVerifiedEmail = 'CustomerVerifiedEmail',
  Person = 'Person',
  Developer = 'Developer',
  InsuranceBroker = 'InsuranceBroker',
  LegacyPlatform = 'LegacyPlatform',
  ServiceDesk = 'ServiceDesk',
  Sales = 'Sales',
  Telemarketing = 'Telemarketing',
  Subscriber = 'Subscriber',
  DeadLetterQueue = 'DeadLetterQueue',
  Super = 'Super',
  Manager = 'Manager',
  BusinessManager = 'BusinessManager',
  TelemarketingLimited = 'TelemarketingLimited',
}

export interface Role {
  Name: RoleName | string;
  Country?: string;
}
