import { mypage_login } from '@axo/shared/data-access/types';
import { send } from '../../lib/send';
import { IAPI } from '../../lib/models/api';

export function createMyPageLogin({ url: { service: baseURL }, token }: IAPI) {
  return (login: mypage_login.CreateLogin): Promise<mypage_login.Login> =>
    send({
      baseURL,
      token,
      path: `/mypage-login`,
      method: 'POST',
      body: login,
    });
}
