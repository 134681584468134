import { Modal } from '@axo/deprecated/util/ui-components';
import styles from './AcceptModal.module.scss';
import { ComponentProps, ReactNode } from 'react';
import { AcceptModalActionBar } from './AcceptModalActionBar';
import clsx from 'clsx';

interface IAcceptModal
  extends Omit<ComponentProps<typeof Modal>, 'topBorderColor'> {
  actionBar?: {
    title?: ReactNode;
    buttons: ReactNode;
  };
  classes?: {
    root?: string;
    box?: string;
    content?: string;
    actionBar?: {
      root?: string;
      buttons?: string;
    };
  };
}

export function AcceptModal({
  actionBar,
  children,
  classes,
  ...props
}: IAcceptModal) {
  return (
    <Modal
      classes={{ root: styles.modalRoot, box: styles.modal, ...classes }}
      {...props}
    >
      <div className={clsx(styles.modalContent, classes?.content)}>
        {children}
        {actionBar && (
          <AcceptModalActionBar
            actionBar={actionBar}
            classes={{ ...classes?.actionBar }}
          />
        )}
      </div>
    </Modal>
  );
}
