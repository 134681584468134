import { P } from '@axo/deprecated/util/ui-components';
import { Icons, IconType } from '@axo/shared/ui/atoms';
import { classNames } from '@axo/shared/util/dom';
import { ReactNode } from 'react';
import { CheckList } from '../../components/CheckList';

import style from './CreditCardOverviewCard.module.scss';

export type ICreditCards =
  | 'remember'
  | 'tfbank'
  | 'morrow'
  | 'norwegian'
  | 'instabank';

export type ICreditCardOverviewProps = {
  cardSection: {
    creditCardProvider?: ICreditCards;
    creditCardProviderShortName?: string;
    title: string;
    subtitle: string;
    tagPosition?: 'top' | 'left' | 'right';
  };
  infoSection: {
    title: string;
    value: string;
  }[];
  benefitsSection: {
    benefits: string[];
    info?: string;
  };
  variant?: 'default' | 'comparison';
  button?: ReactNode;
};

export const CreditCardOverviewCard = ({
  cardSection,
  infoSection,
  benefitsSection,
  variant = 'default',
  button,
}: ICreditCardOverviewProps) => {
  const getCreditCardProviderName = (): ICreditCards | undefined => {
    if (cardSection.creditCardProvider) {
      return cardSection.creditCardProvider;
    }
    if (cardSection.creditCardProviderShortName) {
      switch (cardSection.creditCardProviderShortName) {
        case 'REM':
          return 'remember';
        case 'BN':
          return 'norwegian';
        case 'BBB':
          return 'tfbank';
        case 'IB':
          return 'instabank';
        case 'KB':
          return 'morrow';
        default:
      }
    }
  };

  const creditCardProvider = getCreditCardProviderName();

  return (
    <div
      className={classNames(style.container, !!button && style.hasAction)}
      data-variant={variant}
    >
      <div className={classNames(style.section, style.center)}>
        <div
          className={classNames(
            style.tag,
            style[cardSection.tagPosition ?? 'top']
          )}
        >
          <Icons.PuzzlePiece size="xs" color={{ primary: '--success-900' }} />
          <P>{cardSection.subtitle}</P>
        </div>

        <div className={style.creditCardImage}>
          {creditCardProvider && (
            <img
              src={`/images/kreditkortlogoer/${creditCardProvider}.svg`}
              aria-label={`${creditCardProvider} logo`}
            />
          )}
        </div>

        <P className={style.cardSectionTitle}>{cardSection.title}</P>
      </div>

      <div className={style.sectionGroup}>
        <div className={style.section}>
          {infoSection.map((section) => (
            <div
              className={classNames(style.infoItem, style.divide)}
              key={section.title}
            >
              <P className={style.fontDark}>{section.title}</P>
              <P className={style.semiBold}>{section.value}</P>
            </div>
          ))}
        </div>

        <div className={classNames(style.section, style.highlight)}>
          <CheckList className={classNames(style.checklist, style.semiBold)}>
            {benefitsSection.benefits.map(
              (benefit) =>
                !!benefit && (
                  <CheckList.Item
                    className={style.benefits}
                    iconType={IconType.Badge}
                    key={benefit}
                    label={benefit}
                    state={'valid'}
                  />
                )
            )}
          </CheckList>

          {benefitsSection.info && (
            <p className={classNames(style.fontDark, style.benefitsInfo)}>
              {benefitsSection.info}
            </p>
          )}
        </div>
      </div>
      {!!button && <div className={style.buttonContainer}>{button}</div>}
    </div>
  );
};
