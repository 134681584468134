import { ReactNode, useReducer } from 'react';
import { AuthDispatchContext, AuthStateContext } from './auth.context';
import { authReducer, initialAuthState } from './auth.reducer';
import { useAuthInitialization } from './hooks/useAuthInitialization';
import { useJWTExpiration } from './hooks/useJWTExpiration';
import { useUnAuthRedirection } from './hooks/useUnAuthRedirection';

type AuthContextParams = {
  children: ReactNode;
};

/**
 *
 */

export const AuthContext = ({ children }: AuthContextParams) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        <AuthContextInitialization />
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const AuthContextInitialization = () => {
  // TODO replace `AuthProvider` with `useAuthInitialization`
  useAuthInitialization();

  useJWTExpiration();
  useUnAuthRedirection();

  return null;
};
