import {
  getToken as getStoredCookieAuthOfPerson,
  removeToken,
} from '@axo/mypage/util';
import { useCrossDomainAuth } from '@axo/shared/auth/crossdomain/useCrossDomainAuth';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoadedLoanApplication } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import * as Sentry from '@sentry/browser';
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from '../pages/Loading';
import { AuthSessionState } from './auth.types';
import { useApplicationMagicAuthToken } from './data-access/useApplicationMagicAuthToken';
import { useCustomerAuthToken } from './data-access/useCustomerAuthToken';
import { usePersonAuthToken } from './data-access/usePersonAuthToken';
import { usePersistAuth } from './hooks/usePersistAuth';
import { useAuthDispatch } from './useAuth';

export const loginURL = '/login';
const anonymousRoutes = [loginURL];

const AUTH_TOKEN_KEY = 'jwt';
const APPLICATION_ID_KEY = 'applicationID';

interface ILogin {
  children: ReactNode;
  loginPageShown: boolean;
}

/**
 * @wip will be refactored in `AuthContext` with `useAuthInitialization` handling this initializing logic
 */

export function AuthProvider({ children, loginPageShown }: ILogin) {
  const { state } = useContext(DataAccessContext);
  const location = useLocation();
  const { getPersistedAuth, setPersistedAuth } = usePersistAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  /**
   * Currently supports multiple approaches
   *
   * 1. magic token : uses `?id` as token → role : `CustomerVerifiedEmail`
   * 2. direct access
   *   a. (deprecated) : `?jwt=...&applicationID=...` → role : `Customer`
   *   b. (standardised) : `?id=...#token=...` with `id` as application id  → role : `Customer`
   * 3. sso / cookie token : stored in cookie by sso / bankid on login page → role : `Person`
   *
   * As the `id` has dual purpose (used as magic token or application id),
   * we need to differentiate between the two, therefore this convoluted approach.
   *
   * note: option 3 is managed in the `Login` component (cookie management)
   *
   * @todo standardise this when Auth is sorted out in the back-end (rename magic token, remove 2.a.)
   *
   */
  const searchParams = new URLSearchParams(window.location.search);

  const initialNonConfirmedMagicToken = searchParams.get('id');
  const initialDeprecatedJwt = searchParams.get(AUTH_TOKEN_KEY);
  const initialDeprecatedApplicationID = searchParams.get(APPLICATION_ID_KEY);

  const initialPersonAuth = getStoredCookieAuthOfPerson();

  const initialIsInternal = searchParams.get('traffic') === 'internal';

  /**
   * grabs `?id=...#token=...` from url (and removes the `#token` and stores it in session storage),
   * or loads it from session storage (if present)
   */
  const { authData } = useCrossDomainAuth();

  const isInitializing = useRef(true);
  const [initialState, setInitialState] = useState<AuthSessionState>({
    magicToken: null,
    jwt: null,
    applicationId: null,
    isInternal: false,
  });

  useEffect(() => {
    isInitializing.current && createInitialStateOnSessionStart();
  }, []);

  useEffect(() => {
    isInitializing.current = false;
  }, [initialState]);

  /**
   * determine if it's a magic token initially
   * if a `#token` is not present initially, then the `?id` is a magic token
   * – don't set magicToken if we have a jwt
   */
  const createInitialStateOnSessionStart = () => {
    isInitializing.current = true;

    const sessionFromLocalStorage = getPersistedAuth();

    const authState = {
      magicToken: !authData?.token
        ? initialNonConfirmedMagicToken || sessionFromLocalStorage.magicToken
        : null,
      jwt:
        initialDeprecatedJwt ||
        authData?.token ||
        (!initialNonConfirmedMagicToken ? initialPersonAuth?.JWT : null) ||
        sessionFromLocalStorage.jwt ||
        null,
      applicationId:
        initialDeprecatedApplicationID ||
        authData?.applicationId ||
        sessionFromLocalStorage.applicationId ||
        null,
      isInternal: initialIsInternal || sessionFromLocalStorage.isInternal,
    };

    setInitialState(authState);

    setPersistedAuth(authState);

    // unset the cookie if scenario 1. or 2.
    if (
      initialNonConfirmedMagicToken ||
      initialDeprecatedJwt ||
      authData?.token
    ) {
      removeToken();
    }
  };

  const updateInitialStateFromLoginAuth = () => {
    isInitializing.current = true;
    const personAuth = getStoredCookieAuthOfPerson();

    if (personAuth?.JWT && !initialState.jwt) {
      setInitialState({
        magicToken: null,
        jwt: personAuth.JWT,
        applicationId: null,
        isInternal: initialIsInternal,
      });

      return;
    }

    isInitializing.current = false;
  };

  const { logout } = useAuthDispatch();

  const handleNoToken = useCallback(() => {
    logout();
    setIsLoading(false);
  }, [setIsLoading, logout]);

  const handleJwtAuth = (app: LoadedLoanApplication) => {
    // deprecated, can be removed once migrated to direct access 2.b.
    if (!window.location.href) return;
    const url = new URL(window.location.href);
    url.searchParams.delete(AUTH_TOKEN_KEY);
    url.searchParams.delete(APPLICATION_ID_KEY);
    window.history.replaceState(window.history.state, '', url);
  };

  /**
   * 3
   */

  const applicationMagicToken = useApplicationMagicAuthToken(
    // issue with how the test are created, `initialNonConfirmedMagicToken` should not be used, then again, it will not be called if auth token is available
    initialState.magicToken ?? initialNonConfirmedMagicToken,
    null,
    handleNoToken,
    initialState.isInternal
  );

  const customerAuthToken = useCustomerAuthToken(
    initialState.jwt,
    initialState.applicationId,
    handleJwtAuth,
    handleNoToken
  );

  const personAuthToken = usePersonAuthToken(initialPersonAuth, handleNoToken);

  const isAnonymousRoute = useMemo(
    () => anonymousRoutes.includes(location.pathname),
    [location.pathname]
  );

  // check again on path changes
  useEffect(() => {
    updateInitialStateFromLoginAuth();
    setIsAuthenticated(false);
    setIsLoading(true);
  }, [location.pathname]);

  useEffect(() => {
    if (isAnonymousRoute) {
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    // FF not available yet
    if (loginPageShown === undefined) return;

    /**
     * auth loading is complete when
     * 1. we have a token
     * 2. none of the hooks are still loading
     */
    if (
      !!state.user.token &&
      !applicationMagicToken.isLoading &&
      !customerAuthToken.isLoading &&
      !personAuthToken.isLoading
    ) {
      setIsAuthenticated(true);
      setIsLoading(false);
      return;
    }

    const { magicToken, jwt, applicationId } = initialState;
    if (!isInitializing.current && !jwt && !magicToken && !applicationId) {
      handleNoToken();
      return;
    }

    // 1. only hook with mutate api call
    if (
      !isInitializing.current &&
      magicToken &&
      !applicationMagicToken.isLoading &&
      !applicationMagicToken.isError
    ) {
      // trigger if magicToken is set (not unset because of direct access)
      applicationMagicToken.mutateAsync();
      return;
    }
  }, [
    initialState.jwt,
    initialState.applicationId,
    initialState.magicToken,
    state.user.token,
    applicationMagicToken.isLoading,
    customerAuthToken.isLoading,
    personAuthToken.isLoading,
    isLoading,
    isInitializing.current,
    isAnonymousRoute,
    loginPageShown, // slow FF
  ]);

  // catch-all fallback
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { track } = useAnalytics();

  useEffect(() => {
    if (isLoading) {
      timeoutIdRef.current = setTimeout(() => {
        console.log('AuthProvider : Timeout expired, performing cleanup');

        Sentry.captureMessage(
          'AuthProvider : Loading took too long, executing fallback.',
          {
            level: 'warning',
            extra: {
              data: {
                initialState: { ...initialState },
              },
              isLoading: {
                applicationMagicToken: applicationMagicToken.isLoading,
                customerAuthToken: customerAuthToken.isLoading,
                personAuthToken: personAuthToken.isLoading,
              },
            },
          }
        );

        track({
          event: 'My Page Login Errored',
          params: { reason: 'Timeout' },
          options: {
            send_immediately: true,
          },
        });
        handleNoToken();
      }, 15000);
    }

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }
    };
  }, [isLoading, handleNoToken]);

  return isLoading ? (
    <Loading />
  ) : isAuthenticated || isAnonymousRoute ? (
    children
  ) : null;
}
