import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { loan_application_api } from '@axo/shared/data-access/api';
import { applicationKeys } from '../cache-keys/applicationKeys';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { MagicOutput } from '@axo/shared/data-access/types';

export function useMagicToken(
  magicToken: string | null,
  onSuccess?: (magic: MagicOutput) => void,
  onError?: () => void,
  persistAuth = true
) {
  const { dispatch } = useContext(DataAccessContext);
  const client = useQueryClient();
  const api = useAPI();

  return useMutation(
    () => {
      if (!magicToken) {
        throw new Error('No magic token');
      }

      return loan_application_api.createToken(api)(magicToken);
    },
    {
      mutationKey: ['magicToken'],
      onError: () => {
        onError && onError();
      },
      onSuccess: (response) => {
        persistAuth &&
          dispatch({
            type: 'Set auth',
            scope: { parentType: 'user' },
            payload: { JWT: response.JWT },
          });
        onSuccess && onSuccess(response);
        client.setQueryData(
          applicationKeys.root({
            applicationID: response.ID,
          }),
          response
        );
      },
    }
  );
}
