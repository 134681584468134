import { Footer } from '@axo/mypage/ui/atom';
import { MyPageFlags, useCustomerAnalytics } from '@axo/mypage/util';
import {
  HeadPortal,
  publish,
  QueryErrorEvents,
  subscribe,
  unsubscribe,
} from '@axo/mypage/util/dom';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  EventLogProvider,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import {
  DataAccessContext,
  DataAccessProvider,
} from '@axo/shared/data-access/provider';
import { ErrorBoundary } from '@axo/shared/feature/error-boundary';
import {
  LoanApplicationContext,
  loanApplicationInitialState,
  reducer,
  ToastList,
  useToastActions,
} from '@axo/shared/feature/providers';
import { WSSubscriberProvider } from '@axo/shared/feature/web-socket';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { Trustpilot } from '@axo/shared/ui/core';
import { getLocaleAndCountry } from '@axo/shared/util/string';
import { ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { QueryCache, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet } from 'react-router-dom';
import { ApplicationProgressProvider } from '../../ApplicationProgressProvider';
import { ApplicationStatusProvider } from '../../ApplicationStatusProvider';
import { AuthProvider } from '../../AuthProvider';
import { AuthContext } from '../../AuthProvider/AuthContext';
import { ContactModal } from '../../ContactModal';
import { HelpPage } from '../../HelpPage';
import { useLocaleContext } from '../../LocaleProvider/context';
import { Navigation } from '../../Navigation/Navigation';
import { PuzzelWrapper } from '../../PuzzelWrapper';
import { WebSocketSubscriber } from '../../WebSocketSubscriber';
import { AppErrorProvider } from './AppErrorProvider';
import styles from './Main.module.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => publish(QueryErrorEvents.failedToFetch, error),
  }),
});

function RenderEventLogger({
  children,
}: {
  children: (log: ReturnType<typeof useEventLogger>) => ReactNode;
}) {
  const log = useEventLogger();
  return <>{children(log)}</>;
}

function AuthenticatedEventLogProvider({
  children,
  marketCountry,
}: {
  children: ReactNode;
  marketCountry: string;
}) {
  const { state } = useContext(DataAccessContext);
  return (
    <EventLogProvider
      apiURL={import.meta.env.VITE_APP_API_URL}
      token={state.user.token}
      marketCountry={marketCountry}
      source="mypage"
    >
      {children}
    </EventLogProvider>
  );
}

export function Main({ loginPageShown }: { loginPageShown: boolean }) {
  const [state, dispatch] = useReducer(reducer, loanApplicationInitialState);
  const { t } = useTranslation();
  const { displayToast } = useToastActions();
  const { marketCountry, trustPilot } = useLocaleContext();
  const [showContactModal, setShowContactModal] = useState(false);
  const { flag_show_puzzel_chat } = useTypedFlags(MyPageFlags);
  const { trackInitiated } = useCustomerAnalytics();

  useEffect(() => {
    function handleError(e: CustomEventInit) {
      displayToast({
        header: t('An error occured'),
        content: (
          <p>
            {t(
              'There was an issue fetching your offers. Please try again or contact support at'
            )}{' '}
            <a href={`tel:${t('01234 56789')}`}>{t('01234 56789')}</a>
          </p>
        ),
        variety: 'error',
      });
    }

    subscribe(QueryErrorEvents.failedToFetch, handleError);
    return () => unsubscribe(QueryErrorEvents.failedToFetch, handleError);
  });

  const locale = getLocaleAndCountry(marketCountry);

  useEffect(() => {
    trackInitiated();
  }, []);

  return (
    <DataAccessProvider
      queryClient={queryClient}
      url={{
        api: import.meta.env.VITE_APP_API_URL,
        ws: import.meta.env.VITE_APP_WS_URL,
        integration: import.meta.env.VITE_APP_INTEGRATION_URL,
      }}
    >
      <LoanApplicationContext.Provider value={{ state, dispatch }}>
        <HeadPortal>
          <title>{t('My Page')}</title>
          <meta
            name="description"
            content={t('Axo Finance: My Page') as string}
          />
        </HeadPortal>
        <AuthContext>
          <AuthProvider loginPageShown={loginPageShown}>
            <ApplicationStatusProvider>
              <ApplicationProgressProvider>
                <WSSubscriberProvider>
                  <WebSocketSubscriber />
                  <AuthenticatedEventLogProvider marketCountry={marketCountry}>
                    <Navigation
                      loginPageShown={loginPageShown}
                      setShowContactModal={setShowContactModal}
                    />
                    <ToastList />
                    <div className={styles.container}>
                      <RenderEventLogger>
                        {(log) => (
                          <ErrorBoundary
                            fallback={<HelpPage />}
                            preventMultipleOnErrorCalls={true}
                            onError={(error, errorInfo) => {
                              displayToast({
                                header: t('An error occured'),
                                content: (
                                  <p>
                                    {t(
                                      'A technical error has occured. Please try again or contact support at'
                                    )}{' '}
                                    <a href={`tel:${t('01234 56789')}`}>
                                      {t('01234 56789')}
                                    </a>
                                  </p>
                                ),
                                variety: 'error',
                              });
                              log(EventCode.UnhandledError, {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                componentStack:
                                  errorInfo?.componentStack || undefined,
                              });
                            }}
                          >
                            <AppErrorProvider>
                              <ContactModal
                                isOpen={showContactModal}
                                onClose={() => setShowContactModal(false)}
                              />
                              <Outlet />
                            </AppErrorProvider>
                          </ErrorBoundary>
                        )}
                      </RenderEventLogger>
                    </div>
                    {flag_show_puzzel_chat && (
                      <ErrorBoundary>
                        <PuzzelWrapper
                          active={flag_show_puzzel_chat}
                          marketCountry={
                            marketCountry.toLowerCase() as
                              | 'no'
                              | 'dk'
                              | 'se'
                              | 'fi'
                          }
                          currentUrl={window.location.href}
                        />
                      </ErrorBoundary>
                    )}
                    <Footer
                      tel={t('01234 56789') as string}
                      email={t('customerservice@axofinance.com') as string}
                      orgInfo={
                        <>
                          {`${new Date().getFullYear().toString()} ${t(
                            '© Axo Finance'
                          )}`}{' '}
                          <br />
                          {t('VAT')}
                        </>
                      }
                      trustpilot={
                        locale && (
                          <Trustpilot
                            businessId={trustPilot.businessId}
                            templateId={trustPilot.templateId}
                            height="150px"
                            locale={locale}
                            link={trustPilot.link}
                          />
                        )
                      }
                    />
                  </AuthenticatedEventLogProvider>
                </WSSubscriberProvider>
              </ApplicationProgressProvider>
            </ApplicationStatusProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
        </AuthContext>
      </LoanApplicationContext.Provider>
    </DataAccessProvider>
  );
}
