import { useContext, useReducer } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { loan_quote_accept_api } from '@axo/shared/data-access/api';
import { loan_quote, loan_quote_accept } from '@axo/shared/data-access/types';
import {
  LoadedLoanApplication,
  LoanApplicationContext,
  useToastActions,
} from '@axo/shared/feature/providers';
import {
  applicationKeys,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { useTranslation } from '@axo/mypage/util/translation';

const apiBaseUrl = import.meta.env.VITE_APP_API_URL;

export interface IAcceptQuote {
  acceptAndWait: (
    marketCountry: string,
    quote: loan_quote.LoanQuote,
    acceptableQuoteIndex: number
  ) => Promise<loan_quote.LoanQuote | null>;
  isLoading: boolean;
}

export interface IAcceptRequest {
  quote: loan_quote.LoanQuote;
  presentationHash: string;
  acceptableQuoteIndex: number;
  numAcceptableQuotes: number;
  numPreviouslyAcceptedQuotes: number;
  marketCountry: string;
}

const TIMEOUT_MS = 1_000 * 10;

interface Deferred<T> {
  resolve(result: T): void;

  reject(error?: Error): void;
}

function hasTimedOut(accept: loan_quote_accept.LoanQuoteAccept | undefined) {
  return accept
    ? +new Date() - +new Date(accept.CreatedAt) > TIMEOUT_MS
    : false;
}

interface State {
  isLoading: boolean;
  acceptCompleted?: Deferred<loan_quote.LoanQuote>;
}

type Action =
  | { type: 'start'; deferred: Deferred<loan_quote.LoanQuote> }
  | { type: 'success'; quote: loan_quote.LoanQuote }
  | { type: 'timeOut' }
  | { type: 'failed' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start':
      if (state.acceptCompleted) {
        state.acceptCompleted.reject(new Error('New accept was started'));
      }
      return { ...state, isLoading: true, acceptCompleted: action.deferred };
    case 'success':
      if (state.acceptCompleted) {
        state.acceptCompleted.resolve(action.quote);
      }
      return { ...state, isLoading: false };
    case 'timeOut':
      if (state.acceptCompleted) {
        state.acceptCompleted.reject(new Error('Waiting for accept timed out'));
      }
      return { isLoading: false };
    case 'failed':
      if (state.acceptCompleted) {
        state.acceptCompleted.reject();
      }

      return { isLoading: false };
  }
}

export interface IPresentationParameters {
  hash: string;
  numAcceptableQuotes: number;
  numPreviouslyAcceptedQuotes: number;
}

export function useAcceptQuote(
  presentation: IPresentationParameters,
  loanApplication: LoadedLoanApplication
): IAcceptQuote {
  const { dispatch: appDispatch } = useContext(LoanApplicationContext);
  const [state, dispatch] = useReducer(reducer, { isLoading: false });
  const { displayToast } = useToastActions();
  const { t } = useTranslation();

  const accept = useAcceptMutation(loanApplication);
  useAcceptQuery(
    accept.data,
    (quote) => dispatch({ type: 'success', quote }),
    () => dispatch({ type: 'timeOut' }),
    () => {
      dispatch({ type: 'failed' });
      displayToast({
        header: t('An error occured'),
        content: (
          <p>
            {t(
              'The offer you have selected is no longer available. Please select another offer.'
            )}
          </p>
        ),
        variety: 'error',
      });
    }
  );

  async function acceptAndWait(
    marketCountry: string,
    quote: loan_quote.LoanQuote,
    acceptableQuoteIndex: number
  ) {
    const promise = new Promise<loan_quote.LoanQuote>((resolve, reject) =>
      dispatch({ type: 'start', deferred: { resolve, reject } })
    );
    accept.mutate({
      quote: quote,
      marketCountry,
      presentationHash: presentation.hash,
      acceptableQuoteIndex: acceptableQuoteIndex,
      numAcceptableQuotes: presentation.numAcceptableQuotes,
      numPreviouslyAcceptedQuotes: presentation.numPreviouslyAcceptedQuotes,
    });
    try {
      const updatedQuote = await promise;
      return updatedQuote;
    } catch (error) {
      if (error instanceof Error) {
        appDispatch({
          type: 'Set error',
          scope: { parentType: 'error' },
          payload: { error: error },
        });
      }
      return null;
    }
  }

  return {
    acceptAndWait,
    isLoading: state.isLoading,
  };
}

function useAcceptQuery(
  acceptResult: loan_quote_accept.LoanQuoteAccept | undefined,
  onAcceptCompleted: (quote: loan_quote.LoanQuote) => void,
  onTimedOut: () => void,
  onFailed: () => void
) {
  const client = useQueryClient();
  const host = useAPI();
  const presentation = useLoanQuotePresentation(
    acceptResult?.LoanApplicationID
  );
  useQuery(
    ['loan-quote-accept', acceptResult?.ID],
    ({ queryKey: [, acceptID] }) =>
      loan_quote_accept_api.getLoanQuoteAccept(host)(acceptID ?? ''),
    {
      enabled: acceptResult !== undefined,
      select: (response) =>
        response.Status !== loan_quote_accept.Status.Pending ? response : null,
      refetchInterval: (data) =>
        data || hasTimedOut(acceptResult) ? false : 500,
      onSuccess: async (accepted) => {
        if (accepted) {
          if (accepted.Status === loan_quote_accept.Status.Failed) {
            onFailed();
            client.refetchQueries(
              applicationKeys.loanQuotes({
                applicationID: accepted.LoanApplicationID,
              })
            );
            return;
          }

          client.invalidateQueries(
            applicationKeys.loanQuotes({
              applicationID: accepted.LoanApplicationID,
            })
          );
          const updatedPresentation = await presentation.refetch();
          const updatedQuote =
            updatedPresentation.data?.AcceptedLoanQuotes.find(
              (quote) => quote.ID === accepted.LoanQuoteID
            );
          if (!updatedQuote)
            throw new Error('Could not find the selected quote!');
          onAcceptCompleted(updatedQuote);
        } else if (hasTimedOut(acceptResult)) {
          onTimedOut();
        }
      },
      onError: () => {
        if (hasTimedOut(acceptResult)) {
          onTimedOut();
        }
      },
    }
  );
}

function useAcceptMutation(application: LoadedLoanApplication) {
  const client = useQueryClient();
  const {
    state: {
      user: { token },
    },
  } = useContext(DataAccessContext);
  const {
    state: { application: magicTokenApplication },
  } = useContext(LoanApplicationContext);
  const {
    state: {
      user: { customerID, personID },
    },
  } = useContext(DataAccessContext);

  const isAuthenticatedByPerson = !magicTokenApplication?.ID;

  return useMutation(
    (request: IAcceptRequest) => {
      return loan_quote_accept_api.createLoanQuoteAccept(apiBaseUrl, token, {
        AcceptQuoteInput: {
          MarketCountry: request.marketCountry,
          AcceptedBy: 'MyPage',
          AcceptedByCustomer: true,
        },
        CustomerID: !isAuthenticatedByPerson && customerID ? customerID : '',
        PersonID: isAuthenticatedByPerson && personID ? personID : '',
        LoanQuoteID: request.quote.ID,
        LoanApplicationID: application.ID ?? '',
        PresentationHash: request.presentationHash,
        AcceptableQuoteIndex: request.acceptableQuoteIndex,
        NumAcceptableQuotes: request.numAcceptableQuotes,
        NumPreviouslyAcceptedQuotes: request.numPreviouslyAcceptedQuotes,
      });
    },
    {
      onSuccess: () => {
        client.invalidateQueries(
          applicationKeys.loanQuotes({
            applicationID: application.ID ?? '',
          })
        );
      },
    }
  );
}
