// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./@types/puzzelChat.d.ts" />
import { useEffect } from 'react';
import { IDs } from './config';
import { ApplicationBridge, EUWA } from '@puzzel/euwa-wrapper';
import './styles/styles.css';

/**
  https://help.puzzel.com/product-documents/user-guide/puzzel-contact-centre/puzzel-administration-portal/services/chat-configuration/chat-front-end-api
  https://help.puzzel.com/product-documents/user-guide/puzzel-contact-centre/puzzel-administration-portal/services/chat-configuration/euwa-wrapper-interface
  Limitations:
  1. It can not detect page changes & update variables

  Use:
  const { updateChatVariables } = usePuzzelChat({
    country: 'fi',
    active: ldEnableChat,
  });

  updateChatVariables({
    key1: value1,
    key2: value2
  });
 */

interface IPuzzelChat {
  country: 'no' | 'se' | 'fi' | 'dk';
  delay?: number;
  active: boolean;
}

type Variable = Record<string, unknown>;

// Singleton for the chat instance and pending variables
const chatSingleton = {
  instance: null as ApplicationBridge | null,
  pendingVariables: {} as Variable,
  setInstance(instance: ApplicationBridge) {
    this.instance = instance;
    this.instance.subscribe('chatStarts', () => {
      this.instance?.api.updateVariables(this.pendingVariables);
      this.pendingVariables = {};
    });
  },
  addPendingVariable(variable: Variable) {
    for (const [key, value] of Object.entries(variable)) {
      this.pendingVariables[key] = value;
    }
    if (typeof this.instance?.api?.updateVariables === 'function') {
      this.instance.api.updateVariables(this.pendingVariables);
      this.pendingVariables = {};
    }
  },
};

export const usePuzzelChat = ({
  country,
  delay = 15000,
  active,
}: IPuzzelChat) => {
  const chatIsRunning = () =>
    document && !!document.querySelector('[data-puzzel-chat]');

  const updateChatVariables = (variable: Variable) => {
    chatSingleton.addPendingVariable(variable);
  };

  const handleChatInit = async () => {
    if (chatSingleton.instance) return;
    const euwa = new EUWA(
      {
        configId: IDs.ID[country],
        customerKey: IDs.CUSTOMER_NUMBER,
      },
      {
        hooks: {
          onBeforeLoad: subscribeToChatInit,
        },
        settings: {},
      }
    );

    async function subscribeToChatInit() {
      const chat = await euwa.getApplication(EUWA.APPS.CHAT);
      chatSingleton.setInstance(chat);
    }
  };

  useEffect(() => {
    if (chatIsRunning() || !active) {
      return;
    }
    const delayedShowPuzzelTimeout = setTimeout(handleChatInit, delay);

    return () => {
      clearTimeout(delayedShowPuzzelTimeout);
    };
  }, [active, country, delay, chatIsRunning]);

  return { updateChatVariables };
};
