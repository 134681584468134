import { useAcceptQuote } from '@axo/mypage/data-access';
import { MyPageFlags, useCustomerAnalytics } from '@axo/mypage/util';
import { useGoToBank } from '@axo/mypage/util/dom';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventField,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { useApplicationStatusUtils } from '@axo/shared/data-access/hooks';
import {
  loan_application,
  loan_quote_accept,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { LoadedLoanApplication } from '@axo/shared/feature/providers';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { useExperimentFlag } from '@axo/shared/services/feature-flags/useExperimentFlag';
import {
  ComponentProps,
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AcceptedQuote } from '../components/AcceptedQuote';
import { StoredOffer } from '../LoanQuotePresentation';
import {
  getStoredAcceptedOffer,
  removeStoredAcceptedOffer,
  storeAcceptedOffer,
} from '../LoanQuotePresentation.utils';

import { Steps } from '@axo/insurance/feature/providers';
import { MarketCountryCode } from '@axo/shared/types';
import * as Sentry from '@sentry/browser';
import { AcceptOfferModal } from '../../AcceptOfferModal';
import { ChangeAcceptOfferModal } from '../../ChangeAcceptOfferModal';
import { CreditScore } from '../../CreditScore';
import InsuranceOffer from '../../InsuranceOffer/InsuranceOffer';
import { OfferSelectionModal } from '../../OfferSelectionModal';
import { CancelledLoanQuotes } from '../components/CancelledLoanQuotes';
import { ChangeMySelection } from '../components/ChangeMySelection';
import { SelectableLoanQuotes } from '../components/SelectableLoanQuotes';
import { clearInsuranceStepFromSearchParams } from '../utils/clearInsuranceModalQueryParams';

export const Presentation = ({
  application,
  quotes,
  AcceptOfferModalWithInsurance,
  isUnlockStep,
  isEligibleForInsurance,
  isLoadingIsEligibleForInsurance,
}: {
  application: LoadedLoanApplication;
  quotes: loan_quote_presentation.GetQuotesResponse;
  AcceptOfferModalWithInsurance: ComponentType<
    loan_quote_accept.TAcceptOfferModal & {
      initialStep?: keyof Steps;
    }
  >;
  isUnlockStep?: boolean;
  isEligibleForInsurance?: boolean;
  isLoadingIsEligibleForInsurance?: boolean;
}) => {
  const { t } = useTranslation();
  const search = new URLSearchParams(window.location.search);
  const isRedirectFromAvtalegiro = search.has('state');

  const log = useEventLogger();
  const { trackOffer } = useCustomerAnalytics();

  const insuranceModalInitialStepQueryParams = new URLSearchParams(
    window.location.search
  ).get('insuranceStep') as keyof Steps;

  const [acceptModalOffer, setAcceptModalOffer] = useState<
    StoredOffer | undefined
  >(undefined);
  const [changeSelectionOpen, setChangeSelectionOpen] = useState(false);
  const [acceptInsurance, setAcceptInsurance] = useState(
    !!insuranceModalInitialStepQueryParams
  );
  const [
    insuranceModalVisibleInitialStep,
    setInsuranceModalVisibleInitialStep,
  ] = useState<
    | undefined
    | ComponentProps<typeof AcceptOfferModalWithInsurance>['initialStep']
  >(
    isRedirectFromAvtalegiro ? undefined : insuranceModalInitialStepQueryParams
  );
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);

  const { isInAllApplicationStatusGroups } = useApplicationStatusUtils();
  const status = application.Status as loan_application.ApplicationStatus;
  const applicationDisbursing = isInAllApplicationStatusGroups(
    status,
    loan_application.StatusGroup.Disbursing
  );
  const applicationCanceled = isInAllApplicationStatusGroups(
    status,
    loan_application.StatusGroup.Cancelled
  );
  const applicationAccepted = isInAllApplicationStatusGroups(
    status,
    loan_application.StatusGroup.Accepted
  );
  const offersUnlocked = isUnlockStep || applicationAccepted;

  const accept = useAcceptQuote(
    {
      hash: quotes.PresentationHash,
      numAcceptableQuotes: quotes.AcceptableLoanQuotes.length,
      numPreviouslyAcceptedQuotes: quotes.AcceptedLoanQuotes.length,
    },
    application
  );

  const {
    flag_show_insurance_on_accept,
    flag_show_change_accepted_offer,
    flag_show_cancelled_offers,
    flag_show_credit_score_when_no_offers,
    flag_show_see_more_offers_collapse,
    'accept-insurance-widget': flag_accept_insurance_widget,
  } = useTypedFlags(MyPageFlags);
  const { variation: flag_offer_insurance_on_accept } = useExperimentFlag(
    'mypage-offer-insurance-on-accept',
    false
  );

  const goToBank = useGoToBank();

  const [isAcceptQuoteButtonClicked, setIsAcceptQuoteButtonClicked] =
    useState(false);

  const redirectToBank = useCallback(async () => {
    if (!acceptModalOffer?.offer) return;

    if (!flag_offer_insurance_on_accept) {
      await trackOffer({
        event: 'My Page Offer Accepted',
        offer: acceptModalOffer.offer,
        params: {
          offerIndex: acceptModalOffer.acceptableIndex,
        },
        options: {
          send_immediately: true,
        },
      });
      await accept.acceptAndWait(
        application.MarketCountry,
        acceptModalOffer.offer,
        acceptModalOffer.acceptableIndex
      );
    }
    clearInsuranceStepFromSearchParams();
    goToBank(acceptModalOffer.offer);
  }, [
    goToBank,
    acceptModalOffer,
    accept,
    application.MarketCountry,
    trackOffer,
    flag_offer_insurance_on_accept,
  ]);

  const handleQuoteAccept = useCallback(
    async (
      offer: loan_quote_presentation.PresentedLoanQuote,
      acceptableIndex: number
    ) => {
      setIsAcceptQuoteButtonClicked(true);
      if (application.MarketCountry) {
        storeAcceptedOffer(application.ID, { offer, acceptableIndex });
      }

      if (isEligibleForInsurance || !flag_offer_insurance_on_accept)
        setAcceptModalOffer({ offer, acceptableIndex });

      await trackOffer({
        event: 'My Page Offer Selected',
        offer,
        params: {
          offerIndex: acceptableIndex,
        },
        options: {
          send_immediately: true,
        },
      });

      await log(
        EventCode.AcceptOffer,
        {},
        { Type: KnownResourceType.LoanRequest, ID: offer.LoanRequestID },
        { Type: KnownResourceType.Lender, ID: offer.LenderID },
        { Type: KnownResourceType.LoanQuote, ID: offer.ID }
      );

      if (flag_offer_insurance_on_accept) {
        await trackOffer({
          event: 'My Page Offer Accepted',
          offer,
          params: {
            offerIndex: acceptableIndex,
          },
          options: {
            send_immediately: true,
          },
        });
        await accept.acceptAndWait(
          application.MarketCountry,
          offer,
          acceptableIndex
        );
      }
    },
    [
      accept,
      application.ID,
      application.MarketCountry,
      log,
      trackOffer,
      flag_offer_insurance_on_accept,
      isEligibleForInsurance,
    ]
  );

  const handleAcceptInsurance = useCallback(async () => {
    setAcceptInsurance(true);

    if (!flag_offer_insurance_on_accept && acceptModalOffer) {
      await trackOffer({
        event: 'My Page Insurance Accepted',
        offer: acceptModalOffer.offer,
      });

      await accept.acceptAndWait(
        application.MarketCountry,
        acceptModalOffer.offer,
        acceptModalOffer.acceptableIndex
      );
    }
  }, [
    trackOffer,
    accept,
    acceptModalOffer,
    application.MarketCountry,
    flag_offer_insurance_on_accept,
  ]);

  const closeModal = useCallback(() => {
    setAcceptModalOffer(undefined);
    setAcceptInsurance(false);
    setIsSummaryVisible(false);
    setInsuranceModalVisibleInitialStep(undefined);
    clearInsuranceStepFromSearchParams();
  }, [setAcceptModalOffer, setAcceptInsurance, setIsSummaryVisible]);

  const closeModalInsurance = useCallback(() => {
    removeStoredAcceptedOffer(application.ID);
    closeModal();
  }, [application.ID, closeModal]);

  useEventResource(KnownResourceType.LoanApplication, application.ID);
  useEventResource(KnownResourceType.Customer, application.CustomerID);
  useEventResource(KnownResourceType.Person, application.PersonID);
  useEventField('PresentationHash', quotes.PresentationHash);

  const loaded = useRef<loan_quote_presentation.GetQuotesResponse>();
  useEffect(() => {
    if (quotes.PresentationHash !== loaded.current?.PresentationHash) {
      loaded.current = quotes;
      log(EventCode.LoanQuotePresentationLoaded);
    }
  }, [log, quotes]);

  useEffect(() => {
    Sentry.captureEvent({
      message: 'MP-Insurance Presentation mounted',
      extra: {
        flag_accept_insurance_widget,
        flag_offer_insurance_on_accept,
        isRedirectFromAvtalegiro,
        url: search.toString(),
      },
      level: 'info',
    });

    if (!flag_accept_insurance_widget && !flag_offer_insurance_on_accept)
      return;
    // TODO: Get stateQueryString from insurance provider (ONE-8062).

    if (isRedirectFromAvtalegiro || insuranceModalInitialStepQueryParams) {
      Sentry.captureEvent({
        message: 'MP-Insurance Presentation isRedirectFromAvtalegiro',
        extra: {
          flag_accept_insurance_widget,
          flag_offer_insurance_on_accept,
          isRedirectFromAvtalegiro,
          url: search.toString(),
        },
        level: 'info',
      });

      const acceptedQuote = getStoredAcceptedOffer(application.ID);

      if (flag_accept_insurance_widget) {
        // it is a redirect from avtalegiro and we had a modal open
        if (isRedirectFromAvtalegiro && search.has('insuranceStep')) {
          setInsuranceModalVisibleInitialStep('summary');
        }

        return;
      }

      if (acceptedQuote) {
        if (isRedirectFromAvtalegiro) setIsSummaryVisible(true);

        setAcceptModalOffer(acceptedQuote);
        setAcceptInsurance(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag_offer_insurance_on_accept, flag_accept_insurance_widget]);

  const acceptedQuote = quotes.AcceptedLoanQuotes[0];
  const isAbleToChangeOffer =
    acceptedQuote && acceptedQuote.ID !== acceptModalOffer?.offer.ID;
  const showLegacyInsuranceModal = flag_offer_insurance_on_accept
    ? acceptInsurance
    : flag_show_insurance_on_accept && isEligibleForInsurance;

  const isLoanQuoteButtonDisabled = useMemo(() => {
    return (
      (isLoadingIsEligibleForInsurance && isAcceptQuoteButtonClicked) ||
      (!!acceptedQuote && !flag_show_change_accepted_offer)
    );
  }, [
    acceptedQuote,
    flag_show_change_accepted_offer,
    isLoadingIsEligibleForInsurance,
    isAcceptQuoteButtonClicked,
  ]);

  const isInsuranceOfferVisible = useMemo(
    () =>
      flag_accept_insurance_widget &&
      !isLoadingIsEligibleForInsurance &&
      isEligibleForInsurance,
    [
      isEligibleForInsurance,
      isLoadingIsEligibleForInsurance,
      flag_accept_insurance_widget,
    ]
  );

  const onInsuranceOfferAccept = useCallback(() => {
    setInsuranceModalVisibleInitialStep('selectCoverage');
  }, []);

  return (
    <>
      {insuranceModalVisibleInitialStep && (
        <AcceptOfferModalWithInsurance
          initialStep={insuranceModalVisibleInitialStep}
          onAcceptInsurance={handleAcceptInsurance}
          onDeclineInsurance={closeModal}
          closeModal={closeModal}
          marketCountry={application.MarketCountry as MarketCountryCode}
          offer={acceptModalOffer?.offer}
        />
      )}
      {!applicationCanceled && (
        <>
          {acceptedQuote && (
            <>
              <AcceptedQuote
                acceptedQuote={acceptedQuote}
                marketCountry={application.MarketCountry}
                offersUnlocked={offersUnlocked}
              />
              {flag_show_see_more_offers_collapse &&
                !applicationDisbursing &&
                (quotes.AcceptableLoanQuotes.length > 0 ||
                  quotes.AcceptedLoanQuotes.length > 1) && (
                  <ChangeMySelection
                    toggleShowChangeSelection={() =>
                      setChangeSelectionOpen((prev) => !prev)
                    }
                  />
                )}
            </>
          )}

          {(!acceptedQuote ||
            changeSelectionOpen ||
            !flag_show_see_more_offers_collapse) && (
            <>
              <SelectableLoanQuotes
                acceptedLoanQuotes={quotes.AcceptedLoanQuotes}
                acceptableLoanQuotes={quotes.AcceptableLoanQuotes}
                acceptQuote={handleQuoteAccept}
                isLoading={accept.isLoading}
                acceptedQuote={acceptModalOffer?.offer ?? null}
                marketCountry={application.MarketCountry}
                disableUserAccept={isLoanQuoteButtonDisabled}
                offersUnlocked={offersUnlocked}
              />
              {flag_show_cancelled_offers && (
                <CancelledLoanQuotes
                  cancelledLoanQuotes={quotes.CancelledLoanQuotes}
                  marketCountry={application.MarketCountry}
                  offersUnlocked={offersUnlocked}
                />
              )}
            </>
          )}
        </>
      )}

      {!acceptedQuote &&
        quotes.AllRegisteredLendersResponded &&
        !quotes.AcceptableLoanQuotes.length &&
        flag_show_credit_score_when_no_offers && (
          <CreditScore
            text={t(
              'Please consult the guides below on how to improve your credit score'
            )}
          />
        )}

      {applicationDisbursing && (
        <CreditScore
          text={t(
            "Discover your credit score for free and in less than 2 minutes - don't wait any longer to take control of your finances."
          )}
        />
      )}

      {flag_accept_insurance_widget && isInsuranceOfferVisible && (
        <InsuranceOffer onAcceptInsurance={onInsuranceOfferAccept} />
      )}

      {acceptModalOffer && !isLoadingIsEligibleForInsurance && (
        <>
          {isAbleToChangeOffer && (
            // Change offer feature is not in use due to BE limitations
            <ChangeAcceptOfferModal
              accept={accept}
              oldOffer={acceptedQuote}
              newOffer={acceptModalOffer.offer}
              acceptableQuoteIndex={acceptModalOffer.acceptableIndex}
              marketCountry={application.MarketCountry as MarketCountryCode}
              closeModal={closeModal}
            />
          )}

          {!isAbleToChangeOffer && (
            <>
              {showLegacyInsuranceModal && (
                // Show legacy Insurance modal
                <AcceptOfferModalWithInsurance
                  offer={acceptModalOffer.offer}
                  closeModal={closeModalInsurance}
                  marketCountry={application.MarketCountry as MarketCountryCode}
                  onAcceptInsurance={handleAcceptInsurance}
                  onDeclineInsurance={redirectToBank}
                  initialStep={
                    isSummaryVisible
                      ? 'summary'
                      : insuranceModalInitialStepQueryParams || undefined
                  }
                />
              )}

              {!showLegacyInsuranceModal && (
                <>
                  {flag_offer_insurance_on_accept && isEligibleForInsurance ? (
                    // New AcceptOffer flow (AcceptOffer + Insurance)
                    <OfferSelectionModal
                      offer={acceptModalOffer}
                      marketCountry={application.MarketCountry}
                      onAcceptInsurance={handleAcceptInsurance}
                      onDeclineInsurance={redirectToBank}
                      closeModal={closeModal}
                      isLoadingAccept={accept.isLoading}
                    />
                  ) : (
                    // Show legacy AcceptOffer modal
                    <AcceptOfferModal
                      accept={accept}
                      offer={acceptModalOffer}
                      marketCountry={
                        application.MarketCountry as MarketCountryCode
                      }
                      closeModal={() => setAcceptModalOffer(undefined)}
                      doAccept={redirectToBank}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
