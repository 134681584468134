import { Fragment, ReactNode } from 'react';
import { H3, H4 } from '@axo/deprecated/util/ui-components';
import styles from './Process.module.css';
import { Icons } from '@axo/shared/ui/atoms';
import { classNames } from '@axo/shared/util/dom';

type Props = {
  title: ReactNode;
  steps: IProcessStep[];
};

export interface IProcessStep {
  id: string | number;
  title: ReactNode;
  content: ReactNode;
  icon: ReactNode;
}

export function Process({ title, steps }: Props) {
  return (
    <div className={classNames(styles.container)}>
      <H3 className={styles.title}>{title}</H3>
      <div className={styles.steps}>
        {steps.map((step, index) => (
          <Fragment key={step.id}>
            <div className={styles.step} data-testid="step">
              <div className={styles.icon}>{step.icon}</div>
              <div className={styles.stepContent}>
                <H4>{step.title}</H4>
                <p>{step.content}</p>
              </div>
            </div>
            {index === steps.length - 1 ? null : (
              <Icons.ArrowRight
                classes={{ root: styles.arrow }}
                size={'sm'}
                color={{ primary: '--success-500' }}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
