import { ApplicationWSData } from '@axo/shared/data-access/types';
import { ReactNode, useCallback, useRef } from 'react';
import { WSSubscriberContext } from './context';
import { useWebSocket } from './useWebSocket';
import {
  IPublishFunction,
  ISubscribeFunction,
  ISubscriberCallback,
} from './types';

export function WSSubscriberProvider({ children }: { children: ReactNode }) {
  const DEFAULT_KEY = '_default';

  // Using nested Maps to hold subscribers, with source as key for the outer Map,
  // and code as key for the inner Map
  const subscribers = useRef<Map<string, Map<string, ISubscriberCallback[]>>>(
    new Map()
  );

  const subscribe: ISubscribeFunction = useCallback(
    ({ subscriberCallback, source = DEFAULT_KEY, code = DEFAULT_KEY }) => {
      if (!subscribers.current.has(source)) {
        subscribers.current.set(source, new Map());
      }
      const codeSubscribers = subscribers.current.get(source);
      if (!codeSubscribers?.has(code)) {
        codeSubscribers?.set(code, []);
      }
      const subscribersList = codeSubscribers?.get(code);
      subscribersList?.push(subscriberCallback);

      return () => {
        const codeSubscribers = subscribers.current.get(source);
        if (!codeSubscribers) return;

        const subscribersList = codeSubscribers.get(code);
        if (!subscribersList) return;

        const index = subscribersList.indexOf(subscriberCallback);
        if (index === -1) return;

        // Remove the subscriber
        subscribersList.splice(index, 1);

        // Clean up if the subscribers list is now empty
        if (subscribersList.length === 0) {
          codeSubscribers.delete(code);

          // Further clean up if there are no more codes under this source
          if (codeSubscribers.size === 0) {
            subscribers.current.delete(source);
          }
        }
      };
    },
    []
  );

  const publish: IPublishFunction = useCallback(
    ({ source = DEFAULT_KEY, code = DEFAULT_KEY, ...data }) => {
      const { Event = null } =
        data.latestMessage as unknown as ApplicationWSData;
      const sourceMap = subscribers.current.get(Event ?? source);
      if (sourceMap) {
        const subscribersList = sourceMap.get(Event || code);
        if (subscribersList) {
          subscribersList.forEach((subscriber) => {
            subscriber({ ...data });
          });
        }
      }
    },
    [subscribers]
  );

  // Receives events from web socket, and publishes the returned message.
  const { manualRetry, status } = useWebSocket(publish);

  return (
    <WSSubscriberContext.Provider value={{ subscribe, manualRetry, status }}>
      {children}
    </WSSubscriberContext.Provider>
  );
}
