import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthProvider } from '../AuthProvider/useAuthProvider';

type UseNavigation = {
  navigateToLogin: () => void;
};

export const useNavigation = (): UseNavigation => {
  const navigate = useNavigate();
  const { logout } = useAuthProvider();

  const navigateToLogin = useCallback(() => {
    logout();
    navigate('/login');
    // brute force clear caches
    window.location.href = '/login';
  }, [logout]);

  return {
    navigateToLogin,
  };
};
