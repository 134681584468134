import { Button } from '@axo/deprecated/util/ui-components';
import { InfoBar } from '@axo/mypage/ui/atom';
import { useTranslation } from '@axo/mypage/util/translation';

interface ICreditScore {
  text: string;
}

export const CreditScore = ({ text }: ICreditScore) => {
  const { t } = useTranslation();

  return (
    <InfoBar
      infoText={text}
      button={
        <Button
          variant="primary"
          onClick={() =>
            window.open('https://uscore.fi/?utm_source=Axo&utm_medium=MyPage')
          }
        >
          {t('Check credit score')}
        </Button>
      }
    />
  );
};
