import {
  loan_application,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { AnalyticsEvent, useAnalytics } from '@axo/shared/services/analytics';
import { Events } from '@axo/shared/services/analytics/Events.taxonomy';
import { useRef } from 'react';
import { getApplicationData } from './utils/get-application-data';
import { getOfferData } from './utils/get-offer-data';

type FilterMyPageEvents<T> = T extends `${'My Page'} ${string}` ? T : never;
type MyPageEvents = FilterMyPageEvents<Events>;

type TrackApplicationParams = {
  application: loan_application.LoanApplication;
  params?: Record<string, unknown>;
  options?: AnalyticsEvent['options'];
};

type TrackOfferParams = {
  event: MyPageEvents;
  offer: loan_quote_presentation.PresentedLoanQuote;
  params?: Record<string, unknown>;
  options?: AnalyticsEvent['options'];
};

type TrackOfferProgressParams = {
  event: MyPageEvents;
  progress: {
    amountOfOffers: number;
    currentStep: number;
  };
  params?: Record<string, unknown>;
  options?: AnalyticsEvent['options'];
};

export const useCustomerAnalytics = () => {
  const { track } = useAnalytics();
  const initiatedRef = useRef(false);
  const previousApplicationRef = useRef<string>();

  const trackInitiated = async () => {
    if (!initiatedRef.current) {
      initiatedRef.current = true;
      return track({
        event: 'My Page Initiated',
      });
    }
  };

  const trackApplication = async ({
    application,
    params,
    options,
  }: TrackApplicationParams) => {
    if (previousApplicationRef.current !== application.ID) {
      previousApplicationRef.current = application.ID;

      return track({
        event: 'My Page Application Viewed',
        params: {
          ...getApplicationData({ application }),
          ...params,
        },
        options,
      });
    }
  };

  const trackOffer = async ({
    event,
    offer,
    params,
    options,
  }: TrackOfferParams) => {
    return track({
      event,
      params: {
        ...getOfferData({ offer }),
        ...params,
      },
      options,
    });
  };

  const trackOfferProgress = async ({
    event,
    progress,
    params,
    options,
  }: TrackOfferProgressParams) => {
    return track({
      event,
      params: {
        ...progress,
        ...params,
      },
      options,
    });
  };

  return { trackInitiated, trackApplication, trackOffer, trackOfferProgress };
};
