import { ReactNode } from 'react';
import { ILocaleContext, LocaleContext } from './context';

export interface ILocaleContextProps extends ILocaleContext {
  children: ReactNode;
}

export function LocaleProvider({
  marketCountry,
  locale,
  trustPilot,
  children,
  currency,
}: ILocaleContextProps) {
  return (
    <LocaleContext.Provider
      value={{ marketCountry, locale, trustPilot, currency }}
    >
      {children}
    </LocaleContext.Provider>
  );
}
