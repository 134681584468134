import { loan_quote_accept } from '@axo/shared/data-access/types';

export async function createLoanQuoteAccept(
  baseURl: string,
  token: string,
  accept: loan_quote_accept.Create
) {
  const url = new URL(`/loan-quote-accept/accept`, baseURl);
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(accept),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK: ${response.status}`);
  }
  return (await response.json()) as loan_quote_accept.LoanQuoteAccept;
}
