import {
  loan_application,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { CreditCardListItem } from './CreditCardListItem';
import { LoanQuoteListItem } from './LoanQuoteListItem';
import { QuoteState } from '@axo/mypage/ui/atom';

interface ILoanQuoteListItem {
  quote: loan_quote_presentation.EnrichedLoanQuote;
  action?: () => void;
  state: QuoteState;
  isLoadingAction?: boolean;
  marketCountry: string;
  disableUserAccept?: boolean;
  offersUnlocked?: boolean;
}

export function ListItem({ ...props }: ILoanQuoteListItem) {
  return (
    <>
      {props.quote.Product === loan_application.Product.CreditCard ? (
        <CreditCardListItem {...props} />
      ) : (
        <LoanQuoteListItem {...props} />
      )}
    </>
  );
}
