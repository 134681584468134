import { AuthAction, AuthState } from './auth.types';

export const initialAuthState: AuthState = {
  user: null,
  isAuthenticating: false,
  isUnAuthenticating: false,
  isAuthenticated: false,
  authMethod: undefined,
  token: undefined,
  isInternal: false,
};

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticating: true,
        isAuthenticated: false,
        authMethod: action.payload.authMethod,
        token: action.payload.token,
        ...(action.payload.isInternal && {
          isInternal: action.payload.isInternal,
        }),
      };
    case 'loggedIn':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticating: false,
        isAuthenticated: true,
        authMethod: action.payload.authMethod,
        ...(action.payload.token && { token: action.payload.token }),
        ...(action.payload.isInternal && {
          isInternal: action.payload.isInternal,
        }),
      };
    case 'logout':
      return {
        ...state,
        isAuthenticating: false,
        isUnAuthenticating: true,
      };
    case 'loggedOut':
      return {
        ...initialAuthState, // Revert to initial state
      };
    default:
      return state;
  }
};
