import { MarketCountryCode } from '@axo/shared/types';
import { Header, MenuItem } from '../Header';
import { Icons } from '@axo/shared/ui/atoms';

export type AllowedMarketCountry = Extract<
  MarketCountryCode,
  | MarketCountryCode.Norway
  | MarketCountryCode.Sweden
  | MarketCountryCode.Finland
>;

function getLogo(marketCountry: AllowedMarketCountry) {
  switch (marketCountry) {
    case MarketCountryCode.Norway:
      return <Icons.AxoFinans size="xxlg" />;
    case MarketCountryCode.Sweden:
      return <Icons.AxoFinans size="xxlg" />;
    case MarketCountryCode.Finland:
      return <Icons.AxoLaina size="xxlg" />;
    default:
      return null;
  }
}

export function HeaderWithLogo({
  marketCountry,
  menuItems,
  url,
}: {
  marketCountry: AllowedMarketCountry;
  menuItems?: MenuItem[];
  url?: string;
}) {
  return (
    <Header
      logo={
        <a href={url ?? '/'} aria-label="Axo Finans logo">
          {getLogo(marketCountry)}
        </a>
      }
      menuItems={menuItems}
    />
  );
}
