import { useTranslation } from '@axo/mypage/util/translation';
import { LoadingSpinner } from '@axo/deprecated/util/ui-components';
import styles from './Redirecting.module.scss';

export function Redirecting() {
  const { t } = useTranslation();
  return (
    <div className={styles.modalSpinner} role="progressbar">
      <div>
        <LoadingSpinner colorVariable="--primary-500" />
        <strong>{t('Redirecting...')}</strong>
      </div>
    </div>
  );
}
