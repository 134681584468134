import { createContext, useContext } from 'react';
import { IWSSubscriberContext } from './types';

// Define the context with a default throw error for when it's not provided
export const WSSubscriberContext = createContext<IWSSubscriberContext | null>(
  null
);

// Provide a hook for easy context usage
export function useWSSubscriberContext() {
  const context = useContext(WSSubscriberContext) as IWSSubscriberContext;
  if (!context) {
    throw new Error(
      'useWSSubscriberContext must be used within a WSSubscriberContext'
    );
  }
  return context;
}
