import { loan_quote_presentation } from '@axo/shared/data-access/types';

type getOfferDataArgs = {
  offer: loan_quote_presentation.PresentedLoanQuote;
};

export const getOfferData = ({ offer }: getOfferDataArgs) => ({
  quoteId: offer?.ID,
  productType: offer?.Product,
  loanType: offer?.LoanType,
  loanRequestId: offer?.LoanRequestID,
  lender: offer?.Lender.Name,
  lenderId: offer?.Lender.ID,
  recommended: offer?.Recommended,
  appliedAmount: offer?.Amount,
});
