import { MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import { useApplicationStatusUtils } from '@axo/shared/data-access/hooks';
import {
  loan_application,
  loan_quote_presentation,
  Product,
} from '@axo/shared/data-access/types';
import { LoadedLoanApplication } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useFlags } from '@axo/shared/services/feature-flags';
import { THeaderInfoState } from '@axo/ui-feature/components/HeaderInfo/HeaderInfo.types';
import { useCallback, useContext, useEffect } from 'react';
import {
  ApplicationStatusContext,
  StatusData,
} from '../../ApplicationStatusProvider';
import { useNavigation } from '../../Navigation/useNavigation';

const APP_FORM_URL = import.meta.env.VITE_APP_FORM_URL;
const APP_FORM_CC_URL = import.meta.env.VITE_APP_FORM_CC_URL;
const APP_SITE_URL = import.meta.env.VITE_APP_SITE_URL;
const APP_SITE_CC_URL = import.meta.env.VITE_APP_SITE_CC_URL;

const PRODUCT_URL: Record<Product, string> = {
  [Product.UnsecuredLoan]: APP_FORM_URL,
  [Product.CreditCard]: APP_FORM_CC_URL,
};

let timeoutStatusRef: NodeJS.Timeout | null = null;
const TIMEOUT_STATUS_DELAY = 1000 * 60 * 3;
const handleTimeoutStatus = (callback: () => void) =>
  setTimeout(callback, TIMEOUT_STATUS_DELAY);

export const useHeaderInfo = ({
  application,
  quotes,
}: {
  application: LoadedLoanApplication | undefined;
  quotes: loan_quote_presentation.GetQuotesResponse | undefined;
}) => {
  const { state, dispatch } = useContext(ApplicationStatusContext);
  const { t } = useTranslation();
  const { isInApplicationStatusList } = useApplicationStatusUtils();
  const { navigateToLogin } = useNavigation();
  const {
    'show-login-button-on-my-page-header-info':
      flag_show_login_button_on_my_page_header_info,
  } = useFlags(MyPageFlags);
  const { track } = useAnalytics();

  const setAppState = useCallback(
    (payload: StatusData) => {
      dispatch({ type: 'Set status', payload });
    },
    [dispatch]
  );

  useEffect(() => {
    if (timeoutStatusRef) {
      clearTimeout(timeoutStatusRef);
      timeoutStatusRef = null;
    }

    const status = application?.Status as loan_application.ApplicationStatus;

    const siteUrl =
      application?.Product === Product.CreditCard
        ? APP_SITE_CC_URL
        : APP_SITE_URL;

    // TODO is there a magicToken if logged in by bankId ?
    const appFormUrl = application?.MagicToken
      ? `${PRODUCT_URL[application?.Product]}?token=${application.MagicToken}`
      : PRODUCT_URL[application?.Product ?? Product.UnsecuredLoan];

    // Customer has an incomplete active application
    if (isInApplicationStatusList(status, [100, 155])) {
      if (state.state !== THeaderInfoState.applicationIncomplete) {
        track({ event: 'My Page Application Incomplete Info Shown' });
      }

      setAppState({
        state: THeaderInfoState.applicationIncomplete,
        title: t('Complete your application'),
        description: t('Complete your application to receive offers'),
        actionButtonText: t('Click here'),
        onActionButtonClick: () => (window.location.href = appFormUrl),
      });
      return;
    }

    // Application is registered to banks, no offers available yet. Grooming can be done
    // Application is registered to banks, offers available. still waiting for banks to respond
    if (
      isInApplicationStatusList(
        status,
        [160, 161, 162, 180, 200, 210, 300, 305, 310]
      )
    ) {
      if (
        isInApplicationStatusList(status, [305]) &&
        state.state !== THeaderInfoState.applicationCreditCheckOngoing
      ) {
        track({ event: 'My Page Application Grooming Info Shown' });
      }

      setAppState({
        state: THeaderInfoState.applicationCreditCheckOngoing,
        title: t('Please wait'),
        description: t(
          'We have received your application and are awaiting responses'
        ),
      });

      // Customer is stuck in the statuses above
      // After a designated time (3 min.), the status times out.
      if (!timeoutStatusRef) {
        timeoutStatusRef = handleTimeoutStatus(() => {
          if (state.state !== THeaderInfoState.applicationCreditCheckTimeout) {
            track({
              event: 'My Page Application Credit Check Timeout Info Shown',
            });
          }

          setAppState({
            state: THeaderInfoState.applicationCreditCheckTimeout,
            title: t('We will contact you'),
            description: t(
              'It takes longer than expected to process your application'
            ),
          });
        });
      }

      return;
    }

    // Customer submitted an application that is cancelled or expired
    if (
      isInApplicationStatusList(
        status,
        [189, 190, 289, 290, 389, 390, 489, 490, 589, 590, 689, 690]
      )
    ) {
      setAppState({
        state: THeaderInfoState.applicationExpiredOrCancelled,
        title: t('Start a new application'),
        description: t('You have no active application. Start new'),
        actionButtonText: t('Start the application'),
        onActionButtonClick: () => (window.location.href = siteUrl),
      });
      return;
    }

    // Customer has submitted the application, but got rejected due to payment remark or fraud before registered to banks
    // Application is registered to banks, all banks responded, all banks rejected, application status is in final reject status
    if (isInApplicationStatusList(status, [270, 370, 570])) {
      setAppState({
        title: t('Get in touch with us'),
        description: t('Unfortunately, you did not receive any loan offers'),
        state: THeaderInfoState.applicationHelp,
        contactUsLabel: t('Contact us'),
      });
      return;
    }

    // Customer has submitted an application, but it's a duplicate.
    if (isInApplicationStatusList(status, [201, 209, 299])) {
      if (state.state !== THeaderInfoState.applicationDuplicate) {
        track({ event: 'My Page Application Duplicate Info Shown' });
      }

      setAppState({
        title: t('You already have an active application'),
        description: t('You already have an active application with us'),
        state: THeaderInfoState.applicationDuplicate,
        contactUsLabel: t('Contact us'),
        actionButtonText: flag_show_login_button_on_my_page_header_info
          ? t('Login to My Page')
          : null,
        onActionButtonClick: navigateToLogin,
      });
      return;
    }

    // Application is registered to banks, offers available. all banks responded
    if (isInApplicationStatusList(status, [400, 410])) {
      setAppState({
        title: t('You have received an offer'),
        description: t('See available offers and make your choice'),
        state: THeaderInfoState.applicationInProgressValidOffer,
      });
      return;
    }

    // Offer accepted BY CUSTOMER  offer not rejected/cancelled or disbursed
    if (isInApplicationStatusList(status, [500, 510])) {
      setAppState({
        title: t('Complete the agreement by signing'),
        description: t('To complete the agreement, you must sign'),
        state: THeaderInfoState.applicationOfferAccepted,
      });
      return;
    }

    // Accepted offer is canceled or rejected.
    if (
      isInApplicationStatusList(
        status,
        [199, 280, 380, 388, 470, 480, 580, 588, 670, 680, 688]
      )
    ) {
      setAppState({
        title: t('Get in touch with us'),
        description: t('Your application is closed. Please contact us'),
        state: THeaderInfoState.applicationHelp,
        contactUsLabel: t('Contact us'),
      });
      return;
    }

    // Application is disbursed
    // TODO: Check 800 status. It is in "cancelled" group.
    if (isInApplicationStatusList(status, [700, 600, 610, 800])) {
      setAppState({
        title: t('Thank you for choosing Axo Finans'),
        description: t('Your application has been processed'),
        state: THeaderInfoState.applicationDisbursed,
      });
    }
  }, [application, quotes]);
};
