export const clearHashedParameter = () => {
  const hash = window.location.hash?.slice(1) ?? '';
  const hashParams = new URLSearchParams(
    hash.includes('&') ? hash : `?${hash}`
  );

  const newHash = hashParams.toString() ? `#${hashParams.toString()}` : '';

  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}${window.location.search}${newHash}`
  );
};
