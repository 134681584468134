import styles from './Header.module.scss';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Button } from '@axo/deprecated/util/ui-components';
import { Icons } from '@axo/shared/ui/atoms';

export type IHeader = {
  logo?: ReactNode;
  menuItems?: MenuItem[];
};

export type MenuItem = {
  children?: ReactElement | string;
  action?: () => void;
  isShown: boolean;
  id: number;
};

const BurgerMenu = ({
  onClick,
  isMenuActive,
}: {
  onClick: () => void;
  isMenuActive: boolean;
}) => {
  return (
    <div className={styles.menuContainer}>
      <button className={styles.menuContact} onClick={onClick}>
        {!isMenuActive && <Icons.Burger size="sm" />}
        {isMenuActive && <Icons.Cross size="sm" />}
      </button>
    </div>
  );
};

const InlineMenu = ({ menuItems = [] }: { menuItems: MenuItem[] }) => {
  return (
    <div className={styles.menuContainerInline}>
      {menuItems.map((item) => {
        if (!item.isShown) return null;
        return (
          <button
            className={styles.menuContact}
            onClick={item.action}
            key={item.id}
          >
            {item.children}
          </button>
        );
      })}
    </div>
  );
};

const DrawerButton = ({ children, action, isShown }: MenuItem) => {
  if (!isShown) return null;
  return (
    <button
      className={styles.navButton}
      onClick={() => {
        action?.();
      }}
    >
      <div className={styles.verticalBar} />
      <span className={styles.navButtonLabel}>{children}</span>
    </button>
  );
};

export function Header({ logo, menuItems = [] }: IHeader) {
  const [drawerOffset, setDrawerOffset] = useState(0);
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth <= 768) return;
      setDrawerOffset(0);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (drawerRef.current === null) return;
      if (event.target === null) return;
      if (drawerRef.current.contains(event.target as Node)) return;
      setDrawerOffset(0);
    };
    window.addEventListener('mousedown', onClick);
    return () => {
      window.removeEventListener('mousedown', onClick);
    };
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.contentHolder + ' container no-padding'}>
        <div className={styles.drawerContainer}>
          <div
            className={styles.drawer}
            ref={drawerRef}
            style={{
              bottom: `${-drawerOffset}px`,
            }}
          >
            {menuItems.map((item) => {
              return (
                <DrawerButton
                  {...item}
                  action={() => {
                    item.action?.();
                    setDrawerOffset(0);
                  }}
                  key={item.id}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.background} />
        {logo && <div className={styles.logoHolder}>{logo}</div>}
        <BurgerMenu
          isMenuActive={drawerOffset > 0}
          onClick={() =>
            setDrawerOffset(
              drawerOffset === 0 ? drawerRef.current?.clientHeight ?? 0 : 0
            )
          }
        />
        <InlineMenu menuItems={menuItems} />
      </div>
    </header>
  );
}
