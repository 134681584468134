import { useTranslation } from '@axo/mypage/util/translation';
import { Icons } from '@axo/shared/ui/atoms';
import { IProcessStep, Process } from '@axo/shared/ui/core';
import { useMemo } from 'react';

export function NextSteps() {
  const { t } = useTranslation();
  const steps: IProcessStep[] = useMemo(
    () => [
      {
        id: 1,
        title: t('Select an offer'),
        content: t('Select the bank and the offer that matches'),
        icon: (
          <Icons.CursorClick
            size={'sm'}
            color={{ primary: '--white-100', secondary: '--white-100' }}
          />
        ),
      },
      {
        id: 2,
        title: t('Complete the process'),
        content: t("Complete the process on the bank's web site"),
        icon: (
          <Icons.House
            size={'sm'}
            color={{ primary: '--white-100', secondary: '--white-100' }}
          />
        ),
      },
      {
        id: 3,
        title: t('Have the loan paid out'),
        content: t('Follow the instructions to have the loan paid out'),
        icon: (
          <Icons.Money
            size={'sm'}
            color={{ primary: '--white-100', secondary: '--white-100' }}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <div>
      <Process steps={steps} title="Nå er du snart i mål" />
    </div>
  );
}
