import { loan_application } from '@axo/shared/data-access/types';

type getApplicationDataArgs = {
  application: loan_application.LoanApplication;
};

export const getApplicationData = ({
  application,
}: getApplicationDataArgs) => ({
  appId: application?.ID,
  productType: application?.Product,
  appliedAmount: application?.AppliedAmount,
  status: application?.Status,
  waitingForBanks: application?.WaitingForBanks,
});
