import { useDurationFormatter } from '@axo/mypage/util/formatters';
import { useTranslation } from '@axo/mypage/util/translation';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { classNames } from '@axo/shared/util/dom';
import { useMemo } from 'react';
import { useLocaleContext } from '../../../LocaleProvider';
import { getLoanDetailValues } from '../utils/getLoanQuoteDetailValues';
import styles from './QuoteDetailSplit.module.scss';

interface IQuoteDetail {
  quote: loan_quote_presentation.EnrichedLoanQuote;
  showSecondRow: boolean;
}

export function QuoteDetailSplit({ quote, showSecondRow }: IQuoteDetail) {
  const { t } = useTranslation();
  const durationFormatter = useDurationFormatter();
  const { currency } = useLocaleContext();

  const quoteValues = useMemo(
    () => getLoanDetailValues({ quote, currency, durationFormatter }),
    [quote, currency, durationFormatter]
  );

  return (
    <div className={styles.detailsGrid}>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Loan amount')}</span>
        <span className={styles.value}>{quoteValues.quoteAmount}</span>
      </div>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Loan period')}</span>
        <span className={styles.value}>{quoteValues.duration}</span>
      </div>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Nominal interest rate')}</span>
        <span className={styles.value}>{quoteValues.nominalInterestRate}</span>
      </div>
      <div className={styles.detailItem}>
        <span className={styles.label}>{t('Monthly pay')}</span>
        <span className={styles.value}>{quoteValues.monthlyPay}</span>
      </div>
      {showSecondRow && (
        <>
          <div className={styles.divider}></div>
          <div className={styles.detailItem}>
            <span className={classNames(styles.label, styles.twoLines)}>
              {t('Effective interest rate')}
            </span>
            <span className={styles.value}>
              {quoteValues.effectiveInterestRate}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={classNames(styles.label, styles.twoLines)}>
              {t('Startup fee')}
            </span>
            <span className={styles.value}>{quoteValues.startupFee}</span>
          </div>
          <div className={styles.detailItem}>
            <span className={classNames(styles.label, styles.twoLines)}>
              {t('Administration fee')}
            </span>
            <span className={styles.value}>
              {quoteValues.administrationFee}
            </span>
          </div>
          <div className={styles.detailItem}>
            <span className={classNames(styles.label, styles.twoLines)}>
              {t('Documentation required')}
            </span>
            <span className={styles.value}>
              {quote.RequiresDocumentation ? t('Yes') : t('No')}
            </span>
          </div>
        </>
      )}
    </div>
  );
}
