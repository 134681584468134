import styles from '../pages/Login/Login.module.scss';
import {
  Button,
  EmailInput,
  ErrorModel,
  Icons,
  PinInput,
} from '@axo/deprecated/util/ui-components';
import { ChangeEvent, useState } from 'react';
import { useGetOTP, useVerifyOTP } from '@axo/mypage/data-access';
import { Card } from '@axo/shared/ui/core';

export const OTPLoginCard = () => {
  const [loginCode, setLoginCode] = useState('');
  const [errors, setErrors] = useState<ErrorModel[]>([]);
  const [email, setEmail] = useState('');
  const [loginCodeSent, setLoginCodeSent] = useState(false);
  const [RequestID, setRequestId] = useState<string | null>(null);
  const { mutateAsync: getOTP, isLoading } = useGetOTP();
  const { mutateAsync: verifyOTP, isLoading: isLoadingVerify } = useVerifyOTP();

  async function submitLoginCode() {
    if (!loginCodeSent) {
      try {
        const otp = await getOTP(email);
        setRequestId(otp.RequestID);
        setLoginCodeSent(true);
      } catch {
        setErrors([{ message: 'Failed to send Code' }]);
      }
    } else {
      if (loginCode.length < 6) {
        setErrors([{ message: 'Code is too short' }]);
        return;
      }
      if (!RequestID) return;
      try {
        await verifyOTP({ RequestID, code: loginCode });
      } catch {
        setErrors([{ message: 'Failed to verify the code' }]);
      }
    }
  }

  function handleChangeCode(code: string) {
    setErrors([]);
    setLoginCode(code);
  }

  function handleChangeEmail(event: ChangeEvent<HTMLInputElement>) {
    setErrors([]);
    setEmail(event.target.value);
  }
  const loading = isLoading || isLoadingVerify;
  return (
    <Card
      title={loginCodeSent ? 'Introduce the code' : 'Log in with Henkilötunnus'}
      icon={<Icons.Email size="xlg" />}
      className={styles.card}
    >
      {loginCodeSent ? (
        <p>
          We sent you an <span className={styles.bold}>email</span> and{' '}
          <span className={styles.bold}>sms</span> with a 6 digit code to acess
          My Page, it will expire after 5 minutes. If you have not received the
          code, please click{' '}
          <span
            className={styles.goBack}
            onClick={() => setLoginCodeSent(false)}
          >
            here.
          </span>
        </p>
      ) : (
        <p>
          You will receive a link via <span className={styles.bold}>email</span>{' '}
          and <span className={styles.bold}>sms</span> that you can use to log
          in and see your offers{' '}
          <span className={styles.light}>
            (Remember to check the spam folder)
          </span>
        </p>
      )}

      <span
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitLoginCode();
          }
        }}
      >
        {loginCodeSent ? (
          <PinInput length={6} onChange={handleChangeCode} errors={errors} />
        ) : (
          <EmailInput
            autoComplete="off"
            label="Your Henkilötunnus"
            value={email}
            onChange={handleChangeEmail}
            errors={errors}
          />
        )}
      </span>
      <Button
        variant="ghost"
        width="full-width"
        onClick={submitLoginCode}
        disabled={loading}
        loading={loading}
      >
        Log in with Email
      </Button>
    </Card>
  );
};
