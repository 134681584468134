import { Button, ButtonSet } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import clsx from 'clsx';
import { useMemo } from 'react';
import styles from './HeaderInfo.module.scss';
import { HeaderInfoSkeleton } from './HeaderInfo.skeleton';
import { THeaderInfoProps, THeaderInfoState } from './HeaderInfo.types';
import { stateProperties } from './state-properties';

export const HeaderInfo = ({
  title,
  description,
  state,
  actionButtonText,
  contactUsLabel,
  onActionButtonClick,
  onContactButtonClick,
  className,
}: THeaderInfoProps) => {
  const {
    imageSrc,
    imageSrcSet,
    theme,
    isContactUsButtonVisible,
    isActionButtonVisible,
    actionButtonText: defaultActionButtonText,
    contactUsLabel: defaultContactUsLabel,
    title: defaultTitle,
    description: defaultDescription,
  } = useMemo(() => stateProperties[state], [state]);

  const _title = title || defaultTitle;
  const _description = description || defaultDescription;
  const _actionButtonText = actionButtonText || defaultActionButtonText;
  const _contactButtonText = contactUsLabel || defaultContactUsLabel;

  const isTextVisible = !!(_title || _description);
  const isButtonsVisible = !!(_actionButtonText || isContactUsButtonVisible);

  if (state === THeaderInfoState.loading)
    return <HeaderInfoSkeleton state={state} className={className} />;

  return (
    <div
      className={clsx(styles.headerInfo, className)}
      data-state={theme}
      data-info-state={state}
      data-testid={'header-info'}
    >
      {imageSrc && (
        <div className={styles.imageContainer}>
          <div className={styles.background} />
          <img
            src={!imageSrcSet ? imageSrc : undefined}
            srcSet={imageSrcSet}
            className={styles.image}
            alt={imageSrc}
          />
        </div>
      )}
      <div className={styles.right}>
        {isTextVisible && (
          <>
            {_title && <span className={styles.title}>{_title}</span>}
            {_description && (
              <span className={styles.description}>{_description}</span>
            )}
          </>
        )}
        {isButtonsVisible && (
          <ButtonSet spacing="small">
            {isActionButtonVisible && actionButtonText !== null && (
              <Button
                size="small"
                className={styles.actionButton}
                onClick={onActionButtonClick}
              >
                {_actionButtonText}
              </Button>
            )}
            {isContactUsButtonVisible && (
              <Button
                size="small"
                className={styles.contactUsButton}
                variant="tertiary"
                prefix={<Icon name="phone-ring-solid" size="xs" />}
                onClick={onContactButtonClick}
              >
                {_contactButtonText}
              </Button>
            )}
          </ButtonSet>
        )}
      </div>
    </div>
  );
};
