import { useTranslation } from '@axo/mypage/util/translation';

export function useDurationFormatter() {
  const { t } = useTranslation();
  return (durationYears?: number, durationMonths?: number) => {
    const yearPart = durationYears
      ? `${durationYears} ${durationYears === 1 ? t('year') : t('years')}`
      : null;
    const monthPart = durationMonths
      ? `${durationMonths} ${durationMonths === 1 ? t('month') : t('months')}`
      : null;

    const includeParts = [yearPart, monthPart].filter((part) => !!part);

    if (includeParts.length === 0) {
      return `0 ${t('years')}`;
    }

    return includeParts.join(', ');
  };
}
