import {
  Product,
  loan_application,
  loan_quote,
} from '@axo/shared/data-access/types';
import {
  useApplicationStatusUtils,
  useLoanApplication,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import { StatusBar } from '@axo/shared/ui/core';
import { useTranslation } from '@axo/mypage/util/translation';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { MyPageFlags } from '@axo/mypage/util';

const APP_FORM_URL = import.meta.env.VITE_APP_FORM_URL;
const APP_FORM_CC_URL = import.meta.env.VITE_APP_FORM_CC_URL;
const APP_SITE_URL = import.meta.env.VITE_APP_SITE_URL;
const APP_SITE_CC_URL = import.meta.env.VITE_APP_SITE_CC_URL;

/**
 * @deprecated Replaced by @axo ui-feature HeaderInfo
 */
export function OfferStatusBar({ applicationID }: { applicationID: string }) {
  const { t } = useTranslation();
  const {
    isInAllApplicationStatusGroups,
    isInAnyApplicationStatusGroup,
    isInApplicationStatusList,
    isFinalApplicationStatus,
  } = useApplicationStatusUtils();
  const loanApplication = useLoanApplication(applicationID);
  const loanQuotes = useLoanQuotePresentation(applicationID);
  const { flag_show_legacy_norway_status_messages } =
    useTypedFlags(MyPageFlags);
  const status = loanApplication.data
    ?.Status as loan_application.ApplicationStatus;

  const siteUrl =
    loanApplication.data?.Product === Product.CreditCard
      ? APP_SITE_CC_URL
      : APP_SITE_URL;

  const appFormUrl =
    loanApplication.data?.Product === Product.CreditCard
      ? `${APP_FORM_CC_URL}?token=${loanApplication.data?.MagicToken}`
      : `${APP_FORM_URL}?token=${loanApplication.data?.MagicToken}`;

  const applicationIsCancelled = isInAllApplicationStatusGroups(
    status,
    loan_application.StatusGroup.Cancelled
  );
  const applicationIsAccepted = isInAllApplicationStatusGroups(
    status,
    loan_application.StatusGroup.Accepted
  );
  const applicationIsStarted = isInApplicationStatusList(status, [100]);
  const applicationIsNotFinalized = isInApplicationStatusList(
    status,
    [155, 160, 180]
  );
  const applicationIsFraud = isInApplicationStatusList(status, [199]);
  const noActiveApplication = isInApplicationStatusList(
    status,
    [189, 190, 270, 280, 289, 290]
  );
  const applicationIsRegistering = isInApplicationStatusList(
    status,
    [200, 201, 209, 210]
  );
  const applicationIsDuplicate = isInApplicationStatusList(status, [299]);
  const applicationIsDisbursed = isInApplicationStatusList(status, [700]);

  const isCreditCardApplication =
    loanApplication.data?.Product === loan_application.Product.CreditCard;

  const isCancelledAfterApproval =
    !isInAnyApplicationStatusGroup(status, [
      loan_application.StatusGroup.Active,
      loan_application.StatusGroup.Accepted,
    ]) &&
    isInAllApplicationStatusGroups(status, [
      loan_application.StatusGroup.Registered,
      loan_application.StatusGroup.Approved,
    ]);

  function getStatusText() {
    const {
      AcceptableLoanQuotes,
      AcceptedLoanQuotes,
      AllRegisteredLendersResponded,
      CancelledLoanQuotes,
    } = loanQuotes.data || {};
    const hasCancelledQuotes =
      CancelledLoanQuotes && CancelledLoanQuotes.length > 0;
    const hasAcceptableQuotes =
      AcceptableLoanQuotes && AcceptableLoanQuotes.length > 0;
    const hasAcceptedQuotes =
      AcceptedLoanQuotes && AcceptedLoanQuotes.length > 0;

    // Start by segmenting by Active or (Cancelled, rejected)
    if (flag_show_legacy_norway_status_messages) {
      if (applicationID && (loanQuotes.isError || loanApplication.isError)) {
        return t('Something went wrong');
      }
      if (!applicationID || loanQuotes.isLoading || loanApplication.isLoading) {
        return t('Loading your offers');
      }

      // Final states
      if (isFinalApplicationStatus(status)) {
        if (applicationIsDisbursed) {
          // All have answered and the loan has been paid out
          // Application status: 700 Disbursed
          return isCreditCardApplication
            ? t(
                'Your credit card has been sent from the bank and you will receive it within a few days'
              )
            : t(
                'Your loan has been disbursed and will appear in your account in a few days.'
              );
        }

        if (isCancelledAfterApproval) {
          return t('You have no active applications');
        }

        if (applicationIsFraud) {
          return t('No active loan application');
        }

        if (noActiveApplication) {
          return t('No active loan application', [siteUrl]);
        }

        if (applicationIsDuplicate) {
          return t('You already have an active loan application with us');
        }

        // Offer cancelled - no other offers
        // Application status: 480 Cancelled By Axo, 580 Cancelled by Axo?
        // Comment: Will be caught by grooming status - should be checked before
        if (hasCancelledQuotes && !hasAcceptableQuotes) {
          return isCreditCardApplication
            ? t('We have closed the application for a credit card.')
            : t(
                'Unfortunately, your offer has been cancelled. No other offers available.'
              );
        }
      }

      // Incomplete states
      if (
        isInAllApplicationStatusGroups(
          status,
          loan_application.StatusGroup.Incomplete
        )
      ) {
        if (applicationIsStarted) {
          return t('Complete your application to receive a loan offer', [
            appFormUrl,
          ]);
        }

        // Same as the above?
        if (applicationIsNotFinalized) {
          return t('Your application must be completed');
        }
      }

      // Active states
      if (
        isInAllApplicationStatusGroups(
          status,
          loan_application.StatusGroup.Active
        )
      ) {
        if (applicationIsRegistering) {
          return t('We have received your loan application and registering it');
        }

        // Waiting on all banks
        if (
          status ===
          loan_application.ApplicationStatus
            .ApplicationStatusPendingBankApproval
        ) {
          return t('Waiting for any bank to respond');
        }

        // Some have answered - some offers
        if (!AllRegisteredLendersResponded && hasAcceptableQuotes) {
          return t('As soon as you receive more offers');
        }

        // All have answered - offers
        if (
          AllRegisteredLendersResponded &&
          hasAcceptableQuotes &&
          !hasAcceptedQuotes
        ) {
          return t('All of our bank partners have responded');
        }

        // All banks responded - no offers
        // Application status: 305 Grooming
        if (
          status ===
          loan_application.ApplicationStatus.ApplicationStatusGrooming
        ) {
          if (hasAcceptedQuotes) {
            return t(
              'The selected offer has been finalized by the bank. We are working on your application to see if we can find other offers for you.'
            );
          }

          return t(
            "We'll work on your application to see if we can find a deal for you."
          );
        }

        // AXO has chosen one for the user
        if (
          hasAcceptedQuotes &&
          AcceptedLoanQuotes[0].AcceptedBy !== loan_quote.AcceptedBy.MyPage
        ) {
          return isCreditCardApplication
            ? t('Credit card offer accepted by Axo')
            : t('Loan offer accepted by Axo');
        }

        // Offer has been accepted by the user
        if (
          hasAcceptedQuotes &&
          AcceptedLoanQuotes[0].AcceptedBy === loan_quote.AcceptedBy.MyPage
        ) {
          return isCreditCardApplication
            ? t('You have selected a credit card')
            : t('You have selected an offer');
        }

        // Offer cancelled - other offers available
        if (hasCancelledQuotes && hasAcceptableQuotes) {
          return t('Unfortunately, your offer has been cancelled.');
        }
      }
    } else {
      const noOfferWasApproved = isInApplicationStatusList(status, [370]);

      // The application is in a cancelled state, but there are other offers available.
      // Application status: 480 Cancelled By Axo, 580 Cancelled by Axo?
      if (applicationIsCancelled && !noOfferWasApproved) {
        return t('Unfortunately, your offer has been cancelled.');
      }
      // Application status: 700 Disbursed ?
      if (applicationIsDisbursed) {
        return t(
          'Your loan has been disbursed and will appear in your account in a few days.'
        );
      }
      // Application status: 610 Pending disbursement ?

      if (hasAcceptedQuotes) {
        return t(
          'You have made your selection and are awaiting for the loan to be paid out.'
        );
      }
      if (AllRegisteredLendersResponded) {
        return hasAcceptableQuotes
          ? // Application status: 410 Pending customer accept ?
            t('All of our bank partners have responded')
          : // 370 Not Approved by Any Bank ?
            t('Our lenders have processed your application, but no offer');
      }
      // Application status: 400 Offer approved ?
      if (AcceptableLoanQuotes?.length === 1) {
        return t('You received your first offer!');
      }
      // Application status: 310 Pending bank approval ?

      if (AcceptableLoanQuotes?.length === 1) {
        return t('You received your first offer!');
      }

      if (!AllRegisteredLendersResponded) {
        return t('Please wait for all lenders to respond');
      }
    }
  }

  return (
    <>
      {getStatusText() && (
        <StatusBar
          isHighlighted={applicationIsAccepted && !applicationIsCancelled}
          text={{
            statusMessage: getStatusText(),
          }}
        />
      )}
    </>
  );
}
