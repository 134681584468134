import { classNames } from '@axo/shared/util/dom';
import styles from './StatusBar.module.scss';
import { ReactNode } from 'react';

export type IStatusBar = {
  isHighlighted?: boolean;
  text: {
    statusMessage: ReactNode;
    secondaryMessage?: ReactNode;
  };
};

export function StatusBar({ isHighlighted, text }: IStatusBar) {
  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.infoBar,
          isHighlighted && styles.highlighted
        )}
      >
        <span className={styles.infoText}>{text.statusMessage}</span>
      </div>
      {text?.secondaryMessage && (
        <div className={styles.statusBanner}>
          <span className={styles.idText}>{text.secondaryMessage}</span>
        </div>
      )}
    </div>
  );
}
