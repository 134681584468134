import { loan_application_api } from '@axo/shared/data-access/api';
import { LoanApplicationByCustomerIDParams } from '@axo/shared/data-access/api/loan_application';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { StatusGroup } from '@axo/shared/data-access/types';

import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { customerKeys, parseCustomerKey } from '../cache-keys/customerKeys';
import { queryFunction } from '../query-function';

type LoanApplicationFromCustomerQueryKey = ReturnType<
  typeof customerKeys.loanApplications
>;

export function useLoanApplicationFromCustomer(
  customerId: string | undefined,
  pagination: { offset: number; limit: number } = { offset: 0, limit: 100 },
  groups?: StatusGroup[]
) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();

  const cacheKey: LoanApplicationFromCustomerQueryKey =
    customerKeys.loanApplications({
      customerID: customerId ?? '',
      offset: pagination.offset,
      limit: pagination.limit,
      groups: groups ?? [],
    });

  return useQuery(
    cacheKey,
    queryFunction<
      LoanApplicationFromCustomerQueryKey,
      LoanApplicationByCustomerIDParams,
      any
    >(
      loan_application_api.findLoanApplicationByCustomerID(api),
      ({
        queryKey,
      }: QueryFunctionContext<LoanApplicationFromCustomerQueryKey>) => {
        const { customerID, offset, limit, groups } =
          parseCustomerKey(queryKey);
        return {
          customerId: customerID ?? '',
          pagination: { offset: offset ?? 0, limit: limit ?? 100 },
          groups: groups ?? [],
        };
      }
    ),
    {
      enabled: !!state.user.token && !!customerId,
    }
  );
}
