import { useMemo } from 'react';
import { currencyFormatter } from '@axo/shared/util/formatters';
import {
  loan_quote,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { useTranslation } from '@axo/mypage/util/translation';
import { useLocaleContext } from '../../LocaleProvider';

export function useOfferTypeExplanation({
  quote,
}: {
  quote: loan_quote_presentation.EnrichedLoanQuote;
}) {
  const { t } = useTranslation();
  const { currency } = useLocaleContext();

  const loanTypeExplanation = useMemo(() => {
    if (quote.LoanType === loan_quote.LoanType.ConsumerLoan)
      return t('The loan offer is a consumer loan');
    if (quote.LoanType === loan_quote.LoanType.RefinanceLoan) {
      if (quote.LenderProduct === loan_quote.LenderProduct.DualOffer) {
        return t('This loan offer is an alternative refinancing loan');
      }
      return t('The loan offer is a refinancing loan');
    }
    if (quote.LoanType === loan_quote.LoanType.TopupLoan)
      return t(
        'The loan offer above includes your existing loan with this bank'
      );
    if (
      quote.LoanType === loan_quote.LoanType.ComboLoan &&
      quote.RefinanceAmount &&
      quote.CashAmount
    ) {
      return t(
        'The loan offer is a combination of refinancing loan and consumer loan',
        [
          currencyFormatter(quote.RefinanceAmount, currency),
          currencyFormatter(quote.CashAmount, currency),
        ]
      );
    }
    if (quote.LoanType === loan_quote.LoanType.ComboLoan) {
      return t(
        'The loan offer is a combination of refinancing loan and consumer loan, where part of the loan goes to replace old and expensive debt, and the rest is paid into your account.'
      );
    }
  }, [
    quote.LoanType,
    quote.RefinanceAmount,
    quote.CashAmount,
    quote.LenderProduct,
    t,
    currency,
  ]);

  return loanTypeExplanation;
}
