export const clearInsuranceStepFromSearchParams = () => {
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.delete('insuranceStep');

  const newUrl = `${
    window.location.pathname
  }?${currentSearchParams.toString()}`;

  window.history.pushState({}, '', newUrl);
};
