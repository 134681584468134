import { clsx } from 'clsx';
import { CSSProperties, ReactElement, ReactNode } from 'react';

import styles from './skeleton.module.scss';

export const variants = ['light', 'dark'];

type SkeletonProps = {
  variant?: (typeof variants)[number];
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  width?: number | string;
  height?: number | string;
} & HTMLDataAttributes;

/**
 * Skeleton component
 */
export const Skeleton = ({
  variant = 'light',
  children,
  className,
  style,
  width,
  height,
  ...props
}: SkeletonProps): ReactElement => {
  const combinedStyle: CSSProperties = {
    ...style,
    width,
    minHeight: height,
  };

  return (
    <div
      data-variant={variant}
      className={clsx(styles.skeleton, className)}
      style={combinedStyle}
      {...props}
    >
      {children}
    </div>
  );
};
