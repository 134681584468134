import { useTranslation } from '@axo/mypage/util/translation';
import {
  AuthenticationProviders,
  AuthenticationProvidersContextProvider,
  IAuthProviderResponse,
  TProviders,
} from '@axo/shared/auth/providers';
import { useAPI } from '@axo/shared/data-access/provider';
import { useToastActions } from '@axo/shared/feature/providers';
import { MarketCountryCode } from '@axo/shared/types';
import { Button } from '@axo/ui-core/components/Button';
import { Card } from '@axo/ui-core/components/Card';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Heading, Text } from '@axo/ui-core/components/typography';
import { useCallback, useMemo } from 'react';
import styles from './loginCard.module.scss';

export const LoginCard = ({
  market,
  setAuthToken,
}: {
  market: MarketCountryCode;
  setAuthToken: (token: string, personID?: string) => void;
}) => {
  const api = useAPI();
  const { displayToast } = useToastActions();
  const { t } = useTranslation();

  const providers = useMemo(() => {
    switch (market) {
      case MarketCountryCode.Norway:
        return {
          [TProviders.Criipto]: {
            country: market,
            label: t('Login with BankID'),
          },
          [TProviders.Vipps]: {},
        };

      case MarketCountryCode.Sweden:
        return {
          [TProviders.Criipto]: {
            country: market,
            label: t('Login with BankID'),
          },
        };

      default:
        return {};
    }
  }, [market, t]);

  const handleError = useCallback(
    (response?: IAuthProviderResponse) => {
      console.error(`My Page Login failed: ${response?.errorMessage}`);

      displayToast({
        header: t('There has been an error'),
        content: t('There has been an error with your login, please try again'),
        variety: 'error',
      });
    },
    [displayToast, t]
  );

  const handleOnSuccess = useCallback(
    ({ provider, token, personID }: IAuthProviderResponse) => {
      if (!token || !personID)
        return handleError({
          provider,
          errorMessage: `Missing: PersonID: ${personID} or Token: ${token}`,
        });

      setAuthToken(token, personID);
    },
    [setAuthToken, handleError]
  );

  return (
    <Card>
      <Stack className={styles.auth}>
        <Heading size="xs">{t('Welcome to My Page')}</Heading>

        <Text>
          <b>{t('Choose your preferred method of authentication')}</b>
        </Text>

        <div>
          <AuthenticationProvidersContextProvider
            onProviderLoginError={handleError}
            onProviderLoginSuccess={handleOnSuccess}
          >
            <AuthenticationProviders
              providers={providers}
              axoApiUrl={api.url.service}
              separator={<Text className={styles.separator}>{t('or')}</Text>}
            />
          </AuthenticationProvidersContextProvider>
        </div>

        <footer className={styles.footer}>
          <Text>{t('Don’t have an active application yet')}</Text>

          <Button
            href={import.meta.env.VITE_APP_UNAUTH_REDIRECT_URL}
            variant="ghost"
            prefix={<Icon name="arrow-right" size="xs" />}
          >
            <Text>{t('Start new application')}</Text>
          </Button>
        </footer>
      </Stack>
    </Card>
  );
};
