export enum THeaderInfoImage {
  manOnDateSite = 'manOnDateSite',
  networkWorkflow = 'networkWorkflow',
  youngWomenPointing = 'youngWomenPointing',
  dataAnalysis = 'dataAnalysis',
  convertMegaphone = 'convertMegaphone',
  manReadingNewspaper = 'manReadingNewspaper',
  businessAccount = 'businessAccount',
  signingContract = 'signingContract',
  youngWomenHeadphones = 'youngWomenHeadphones',
  onlineShopping = 'onlineShopping',
}

export enum THeaderInfoState {
  loading = 'loading',
  applicationSubmitted = 'application-submitted',
  // applicationSubmittedThankYou = 'application-submitted-thank-you',

  applicationIncomplete = 'application-incomplete',
  applicationCreditCheckOngoing = 'application-credit-check-ongoing',
  applicationCreditCheckTimeout = 'application-credit-check-timeout',
  applicationGrooming = 'application-grooming',
  applicationExpiredOrCancelled = 'application-expired-or-cancelled',
  applicationHelp = 'application-help',
  applicationDuplicate = 'application-duplicate',
  applicationInProgressValidOffer = 'application-in-progress-valid-offer',
  applicationOfferAccepted = 'application-offer-accepted',
  applicationDisbursed = 'application-disbursed',

  // v.next
  applicationNoOffers = 'application-no-offers',
  applicationInProgress = 'application-in-progress',
  applicationOfferAutoAccepted = 'application-offer-auto-accepted',
  applicationSingleOfferAutoAccepted = 'application-single-offer-auto-accepted',
  applicationOfferCancelledNextAutoAccepted = 'application-offer-cancelled-next-auto-accepted',
  applicationOfferCancelled = 'application-offer-cancelled',
}

export type THeaderInfoProps = {
  title?: string;
  description?: string;
  state: THeaderInfoState;
  actionButtonText?: string | null;
  contactUsLabel?: string;
  onActionButtonClick?: () => void;
  onContactButtonClick?: () => void;
  className?: string;
};
