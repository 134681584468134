import { AuthData } from '@axo/mypage/util';
import {
  useCustomer,
  useLoanApplicationFromPerson,
  useMagicToken,
} from '@axo/shared/data-access/hooks';
import {
  DataAccessContext,
  isJwtExpired,
} from '@axo/shared/data-access/provider';
import {
  customer,
  LoanApplication,
  MagicOutput,
} from '@axo/shared/data-access/types';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthDispatch } from '../useAuth';

/**
 *  Handles the person Auth with jwt token from bankId login
 *
 *  loads all the applications of a person, finds the relevant customerId
 *  loads the customer, stores email and additional roles on data access context (keeping person jwt)
 */
export function usePersonAuthToken(
  authData: AuthData | undefined,
  onError?: () => void
) {
  const { state, dispatch } = useContext(DataAccessContext);
  const [latestLoanApplication, setLatestLoanApplication] =
    useState<LoanApplication>();
  const { identify } = useAnalytics();
  const { loggedIn } = useAuthDispatch();
  const [customer, setCustomer] = useState<{ id: string; token: string }>();

  // set the jwt token on the data access context to allow to make the api calls
  // jwt contains has the `Person` role, authData contains the `personID`
  useEffect(() => {
    !!authData &&
      authData.PersonID !== state.user.personID &&
      !isJwtExpired(authData.JWT) &&
      dispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: authData,
      });
  }, [authData, state.user.personID]);

  const _onError = () => {
    onError?.();
  };

  /**
   * `LoanApplication` / `FromPerson`
   */

  const onSuccessLoanApplicationFromPerson = (response: LoanApplication[]) => {
    const customerIds = Array.from(
      new Set(response.map((application) => application.CustomerID))
    );

    // assumption: customerId of last application is valid, use this one to load the customer
    const customerId = customerIds.length > 0 ? customerIds[0] : undefined;
    !customerId && onError?.();

    // set the application data on the loan application context

    setLatestLoanApplication(response[0]);
  };

  // the useQuery hook will only call the api if there is a customerID
  const loanApplicationFromPersonResult = useLoanApplicationFromPerson(
    state.user.personID
  );

  useEffect(() => {
    if (loanApplicationFromPersonResult.isError) _onError();
    if (loanApplicationFromPersonResult.isSuccess)
      onSuccessLoanApplicationFromPerson(loanApplicationFromPersonResult.data);
  }, [
    loanApplicationFromPersonResult.isError,
    loanApplicationFromPersonResult.isSuccess,
  ]);

  /**
   * `LoanApplication` / `createToken`
   */

  const onSuccessMagicToken = (response: MagicOutput) => {
    setCustomer({ id: response.CustomerID, token: response.JWT });
  };

  // the mutateAsync is triggered when latestLoanApplication?.MagicToken is available
  const magicTokenResult = useMagicToken(
    latestLoanApplication?.MagicToken || null,
    onSuccessMagicToken,
    _onError,
    false
  );

  useEffect(() => {
    latestLoanApplication?.MagicToken && magicTokenResult.mutateAsync();
  }, [magicTokenResult.mutateAsync, latestLoanApplication]);

  /**
   * `Customer`
   */

  const onSuccessCustomer = async (response: customer.Customer) => {
    await identify({
      uuid: response.ID,
      email: response.Email,
    });

    loggedIn({
      user: {
        id: response.ID,
      },
      authMethod: 'person sso token',
    });
  };

  // the useQuery hook will only call the api when the customer id from loan application is set
  const customerResult = useCustomer(customer?.id, customer?.token);

  useEffect(() => {
    if (customerResult.isError) _onError();
    if (customerResult.isSuccess) onSuccessCustomer(customerResult.data);
  }, [customerResult.isError, customerResult.isSuccess]);

  const isError = useMemo(() => {
    return (
      loanApplicationFromPersonResult.isError ||
      magicTokenResult.isError ||
      customerResult.isError
    );
  }, [
    loanApplicationFromPersonResult.isError,
    magicTokenResult.isError,
    customerResult.isError,
  ]);

  const isLoading = useMemo(() => {
    return (
      (loanApplicationFromPersonResult.isLoading ||
        (loanApplicationFromPersonResult.isSuccess &&
          !magicTokenResult.isSuccess) ||
        (magicTokenResult.isSuccess && !customerResult.isSuccess)) &&
      !isError
    );
  }, [
    loanApplicationFromPersonResult.isLoading,
    loanApplicationFromPersonResult.isSuccess,
    magicTokenResult.isSuccess,
    customerResult.isSuccess,
    isError,
  ]);

  return {
    isLoading,
    isError,
  };
}
