import { Stack } from '@axo/ui-core/components/layout/item';
import { Skeleton } from '@axo/ui-core/components/Skeleton';
import clsx from 'clsx';
import styles from './HeaderInfo.module.scss';
import { THeaderInfoProps } from './HeaderInfo.types';

type HeaderInfoSkeletonProps = Pick<THeaderInfoProps, 'state' | 'className'>;

export const HeaderInfoSkeleton = ({
  state,
  className,
}: HeaderInfoSkeletonProps) => {
  return (
    <div
      className={clsx(styles.headerInfo, styles.headerInfoSkeleton, className)}
      data-state={'neutral'}
      data-info-state={state}
      data-testid={'header-info'}
    >
      <div className={styles.imageContainer}>
        <div className={styles.background} />
      </div>
      <div className={styles.right}>
        <Stack gap={'s'}>
          <Skeleton width={'65%'} height={'1.2rem'} />
          <Stack className={styles.description} gap={'3xs'}>
            <Skeleton width={'92%'} height={'0.65rem'} />
            <Skeleton width={'48%'} height={'0.65rem'} />
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
