import {
  ApplicationProgressContext,
  OfferStatusBar,
} from '@axo/mypage/feature';
import { LoadingGears } from '@axo/mypage/ui/atom';
import { MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import { InsuranceEligibilityContextProvider } from '@axo/shared/auth/crossdomain/product/InsuranceEligibilityContext';

import {
  useLoanApplication,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import {
  loan_quote_accept,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { useFlags } from '@axo/shared/services/feature-flags';
import { ProgressBarWithDescription } from '@axo/ui-feature/components/ProgressBarWithDescription';
import { ComponentType, useContext } from 'react';
import styles from './LoanQuotePresentation.module.scss';
import { Presentation } from './Presentation/Presentation';
import { PresentationWithInsurance } from './Presentation/PresentationWithInsurance';
import { useApplicationProgressAnalytics } from './hooks/useApplicationProgressAnalytics';
import { useHeaderInfo } from './hooks/useHeaderInfo';
import { useProgressBarWithDescription } from './hooks/useProgressBarWithDescription';

export interface StoredOffer {
  offer: loan_quote_presentation.PresentedLoanQuote;
  acceptableIndex: number;
}
const apiBaseUrl = import.meta.env.VITE_APP_API_URL;

export function LoanQuotePresentation({
  applicationID,
  AcceptOfferModalWithInsurance,
}: {
  applicationID: string;
  AcceptOfferModalWithInsurance: ComponentType<loan_quote_accept.TAcceptOfferModal>;
}) {
  const { t } = useTranslation();
  const { state: progressBarState } = useContext(ApplicationProgressContext);
  const application = useLoanApplication(applicationID);
  const quotes = useLoanQuotePresentation(applicationID);
  const {
    'show-my-page-communication-elements': flag_show_communication_elements,
    'offer-insurance-on-accept': flag_offer_insurance_on_accept,
    'accept-insurance-widget': flag_accept_insurance_widget,
  } = useFlags(MyPageFlags);

  // HeaderInfo logic
  useHeaderInfo({ application: application.data, quotes: quotes.data });

  // Progressbar logic
  const { offersUnlocked } = useProgressBarWithDescription({
    application: application.data,
    quotes: quotes.data,
  });

  // Application progress tracking
  useApplicationProgressAnalytics({ quotes: quotes.data });

  const isWithInsurance =
    flag_offer_insurance_on_accept || flag_accept_insurance_widget;

  return (
    <>
      {flag_show_communication_elements ? (
        progressBarState.isInProgress && (
          <ProgressBarWithDescription {...progressBarState} />
        )
      ) : (
        <OfferStatusBar applicationID={applicationID} />
      )}
      {!application.data || quotes.isLoading || !quotes.data ? (
        <LoadingGears text={t('Loading your offers') as string} />
      ) : (
        <>
          {isWithInsurance ? (
            <InsuranceEligibilityContextProvider
              apiUrl={apiBaseUrl}
              marketCountry={application.data.MarketCountry}
              employmentStatus={application.data.EmploymentStatus ?? undefined}
              personID={application.data.PersonID ?? undefined}
            >
              <PresentationWithInsurance
                applicationData={application.data}
                quotesData={quotes.data}
                AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
                isUnlockStep={
                  flag_show_communication_elements ? offersUnlocked : true
                }
              />
            </InsuranceEligibilityContextProvider>
          ) : (
            <Presentation
              application={application.data}
              quotes={quotes.data}
              AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
              isUnlockStep={
                flag_show_communication_elements ? offersUnlocked : true
              }
            />
          )}

          <div className={styles.applicationID}>
            {t('Your application ID is')}: {application?.data?.HumanReadableID}
          </div>
        </>
      )}
    </>
  );
}
