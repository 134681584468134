import { isJwtExpired } from '@axo/shared/data-access/provider/utils/is-jwt-expired';
import { AuthSessionState } from '../auth.types';

export const authLocalStorageKey = 'axo.auth';

const getPersistedAuth = (): AuthSessionState | Record<string, never> => {
  const storedData = localStorage.getItem(authLocalStorageKey);
  if (!storedData) return {};

  const authData: AuthSessionState = JSON.parse(storedData);

  if (authData.jwt && isJwtExpired(authData.jwt)) {
    clearPersistedAuth();
    return {};
  }

  return authData;
};

const setPersistedAuth = (auth: AuthSessionState) => {
  localStorage.setItem(authLocalStorageKey, JSON.stringify(auth));
};

const clearPersistedAuth = () => {
  localStorage.removeItem(authLocalStorageKey);
};

export const usePersistAuth = () => {
  return {
    getPersistedAuth,
    setPersistedAuth,
    clearPersistedAuth,
  };
};
