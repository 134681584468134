import { loan_application_api } from '@axo/shared/data-access/api';
import { LoanApplicationByPersonIDParams } from '@axo/shared/data-access/api/loan_application';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { StatusGroup } from '@axo/shared/data-access/types';

import { useContext } from 'react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { parsePersonKey, personKeys } from '../cache-keys/personKeys';
import { queryFunction } from '../query-function';

type LoanApplicationFromPersonQueryKey = ReturnType<
  typeof personKeys.loanApplications
>;

export function useLoanApplicationFromPerson(
  personID: string | undefined,
  pagination: { offset: number; limit: number } = { offset: 0, limit: 100 },
  groups?: StatusGroup[]
) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();

  const cacheKey: LoanApplicationFromPersonQueryKey =
    personKeys.loanApplications({
      personID: personID ?? '',
      offset: pagination.offset,
      limit: pagination.limit,
      groups: groups ?? [],
    });

  return useQuery(
    cacheKey,
    queryFunction<
      LoanApplicationFromPersonQueryKey,
      LoanApplicationByPersonIDParams,
      any
    >(
      loan_application_api.findLoanApplicationByPersonID(api),
      ({
        queryKey,
      }: QueryFunctionContext<LoanApplicationFromPersonQueryKey>) => {
        const { personID, offset, limit, groups } = parsePersonKey(queryKey);
        return {
          personID: personID ?? '',
          pagination: { offset: offset ?? 0, limit: limit ?? 100 },
          groups: groups ?? [],
        };
      }
    ),
    {
      enabled: !!state.user.token && !!personID,
    }
  );
}
