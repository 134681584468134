import { Button, H4 } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '@axo/mypage/util/translation';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import { AcceptModalActionBar } from '../AcceptModal/AcceptModalActionBar';
import styles from './BankInfo.module.scss';

export function BankInfo({
  offer,
  closeModal,
  marketCountry,
}: {
  offer?: loan_quote_presentation.PresentedLoanQuote;
  closeModal: () => void;
  marketCountry: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <H4>{t('Congratulations!')}</H4>
      <span className={styles.subtitle}>
        {t('You have chosen the offer from')}
      </span>
      <div className={styles.lenderLogoContainer}>
        {offer?.Lender && (
          <div className={styles.lenderLogo}>
            <LenderLogo lender={offer.Lender} marketCountry={marketCountry} />{' '}
          </div>
        )}
      </div>
      <AcceptModalActionBar
        actionBar={{
          title: t('You will receive a confirmation email or SMS shortly.'),
          buttons: (
            <Button onClick={closeModal} variant="primary">
              {t('Of course')}
            </Button>
          ),
        }}
      />
    </>
  );
}
