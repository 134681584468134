import { useAnalytics } from '@axo/shared/services/analytics';
import { useCallback, useEffect, useRef } from 'react';
import { useAuthState } from '../useAuth';

type UseAuthInitialization = {
  initializeAuth: () => void;
};

type AuthInitializationParams = NonNullable<unknown>;

/**
 * @wip will replace the initialization logic from `AuthProvider`
 */

export const useAuthInitialization = (): UseAuthInitialization => {
  const state = useAuthState();
  const { track } = useAnalytics();
  const hasAuthenticatedOnce = useRef(false);

  const initializeAuth = useCallback(() => {
    console.log('Initialize Auth...');
  }, []);

  useEffect(() => {
    const handleAuthChange = async () => {
      if (state.isAuthenticated) {
        await track({
          event: 'My Page LoggedIn',
          params: {
            authMethod: state.authMethod,
            isInternal: state.isInternal,
          },
          options: {
            send_immediately: true,
          },
        });
        hasAuthenticatedOnce.current = true;
      }
      if (!state.isAuthenticated && hasAuthenticatedOnce.current) {
        await track({
          event: 'My Page LoggedOut',
          options: {
            send_immediately: true,
          },
        });
      }
    };

    handleAuthChange();
  }, [state.isAuthenticated]);

  return { initializeAuth };
};
