import styles from './InfoBar.module.scss';
import { ReactNode } from 'react';

interface IInfoBar {
  infoText: string;
  button: ReactNode;
}

export const InfoBar = ({ infoText, button }: IInfoBar) => {
  return (
    <div className={styles.actionBar}>
      <span className={styles.text}>{infoText}</span>
      <div className={styles.buttons}>{button}</div>
    </div>
  );
};
