import { QuoteState } from '@axo/mypage/ui/atom';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { ListItem } from '../../ListItem/ListItem';

export const CancelledLoanQuotes = ({
  cancelledLoanQuotes,
  marketCountry,
  offersUnlocked,
}: {
  cancelledLoanQuotes: loan_quote_presentation.EnrichedLoanQuote[];
  marketCountry: string;
  offersUnlocked?: boolean;
}) => {
  return cancelledLoanQuotes.map((quote) => (
    <ListItem
      key={quote.ID}
      quote={quote}
      state={QuoteState.Cancelled}
      marketCountry={marketCountry}
      disableUserAccept={true}
      offersUnlocked={offersUnlocked}
    />
  ));
};
