import { useMutation } from 'react-query';
import { useAPI } from '@axo/shared/data-access/provider';
import { mypage_login_api } from '@axo/shared/data-access/api';

export function useCreateMyPageLogin() {
  const api = useAPI();

  return useMutation(mypage_login_api.createMyPageLogin(api), {
    onSuccess: () => {
      return;
    },
  });
}
