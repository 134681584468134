import { Languages } from '@axo/shared/util/translation';
import { TranslationContext } from './context';
import { ReactNode } from 'react';

export type ITranslationProvider = {
  children: ReactNode;
  language: Languages;
  showDebugErrors: boolean;
};

export function TranslationProvider({
  children,
  language,
  showDebugErrors,
}: ITranslationProvider) {
  return (
    <TranslationContext.Provider value={{ language, showDebugErrors }}>
      {children}
    </TranslationContext.Provider>
  );
}
