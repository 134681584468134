import { loan_application, Product } from '@axo/shared/data-access/types';
import { useApplicationStatusUtils } from '@axo/shared/data-access/hooks';

/**
 * retrieves the most recently created active or disbursed application for each product,
 * returns the `requestedApplication` for the product if provided.
 */
export const useLatestActiveApplication = ({
  loanApplications,
  requestedApplication,
}: {
  loanApplications: loan_application.LoanApplication[];
  requestedApplication?: loan_application.LoanApplication;
}) => {
  const { isInAnyApplicationStatusGroup } = useApplicationStatusUtils();

  function getAppByProduct(product: Product) {
    if (requestedApplication?.Product === product) {
      return requestedApplication;
    }

    const activeApps = loanApplications.filter(
      (app) =>
        app.Product === product &&
        app.Status &&
        !isInAnyApplicationStatusGroup(app.Status, [
          loan_application.StatusGroup.Incomplete,
          loan_application.StatusGroup.Cancelled,
          loan_application.StatusGroup.Disbursed,
          loan_application.StatusGroup.Disbursing,
        ])
    );

    const disbursedApps = loanApplications.filter(
      (app) =>
        app.Product === product &&
        app.Status &&
        isInAnyApplicationStatusGroup(app.Status, [
          loan_application.StatusGroup.Disbursed,
          loan_application.StatusGroup.Disbursing,
        ])
    );

    if (activeApps?.length) {
      return activeApps.reduce(function (pre, cur) {
        return Date.parse(pre.CreatedAt) < Date.parse(cur.CreatedAt)
          ? cur
          : pre;
      });
    }

    if (disbursedApps?.length) {
      return disbursedApps.reduce(function (pre, cur) {
        return Date.parse(pre.CreatedAt) < Date.parse(cur.CreatedAt)
          ? cur
          : pre;
      });
    }

    return null;
  }

  const creditCardApplication = getAppByProduct(Product.CreditCard);
  const unsecuredLoanApplication = getAppByProduct(Product.UnsecuredLoan);

  return { creditCardApplication, unsecuredLoanApplication };
};
