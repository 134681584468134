import styles from './AcceptModal.module.scss';
import { ReactNode } from 'react';
import clsx from 'clsx';

interface IAcceptModalActionBar {
  actionBar: {
    title?: ReactNode;
    buttons: ReactNode;
  };
  classes?: {
    root?: string;
    buttons?: string;
  };
}

export function AcceptModalActionBar({
  actionBar,
  classes,
}: IAcceptModalActionBar) {
  return (
    <div className={clsx(styles.actionContainer, classes?.root)}>
      {actionBar.title}
      <div className={clsx(styles.buttons, classes?.buttons)}>
        {actionBar.buttons}
      </div>
    </div>
  );
}
