import { MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { HeaderWithLogo, MenuItem } from '@axo/shared/ui/core';
import { Dispatch, SetStateAction } from 'react';
import { useAuthProvider } from '../AuthProvider/useAuthProvider';
import { useLocaleContext } from '../LocaleProvider';
import { useNavigation } from './useNavigation';

const SITE_URL = import.meta.env.VITE_APP_SITE_URL;

export const Navigation = ({
  loginPageShown,
  setShowContactModal,
}: {
  loginPageShown: boolean;
  setShowContactModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { flag_show_insurance_overview } = useTypedFlags(MyPageFlags);
  const { marketCountry } = useLocaleContext();
  const { t } = useTranslation();
  const { navigateToLogin } = useNavigation();
  const { isLoggedInAsVerified, isLoggedInAsUnverified } = useAuthProvider();

  const navMenuItems: MenuItem[] = [
    {
      children: t('Loan Quotes'),
      action: () => (window.location.pathname = '/'),
      isShown: flag_show_insurance_overview,
      id: 1,
    },
    {
      children: t('Insurance'),
      action: () => (window.location.pathname = '/insurance/'),
      isShown: flag_show_insurance_overview,
      id: 2,
    },
    {
      children: t('Contact'),
      action: () => setShowContactModal((prev) => !prev),
      isShown: true,
      id: 3,
    },
    {
      children: t('Log out'),
      action: navigateToLogin,
      isShown: loginPageShown && isLoggedInAsVerified,
      id: 4,
    },
    {
      children: t('Log in'),
      action: navigateToLogin,
      isShown: loginPageShown && isLoggedInAsUnverified,
      id: 5,
    },
  ];

  return (
    <HeaderWithLogo
      marketCountry={marketCountry}
      menuItems={navMenuItems}
      url={SITE_URL}
    />
  );
};
