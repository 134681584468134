import { ReactNode, RefObject, useEffect, useRef } from 'react';
import { ErrorModel } from '../../../models';
import classNames from '../../../utils/class-names';
import ErrorText from '../ErrorText';
import styles from './PinInput.module.scss';

export interface IPinInput {
  length: number;
  onChange?: (value: string) => void;
  errors: ErrorModel[];
}

const pinValuesMatcher = /[0-9]/;

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const PinInput: React.FunctionComponent<IPinInput> = ({
  length,
  errors,
  ...props
}: IPinInput) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const inputs: ReactNode[] = [];

  useEffect(() => {
    const el = containerRef.current;
    if (el && el.classList.contains(styles.error)) {
      el.classList.remove(styles.error);
      setTimeout(() => {
        el.classList.add(styles.error);
      }, 100);
    }
  }, [errors]);

  for (let i = 0; i < length; i++) {
    inputs.push(
      <input
        key={i}
        type="phone"
        maxLength={1}
        className={styles.numberBox}
        onMouseUp={(e) => {
          e.stopPropagation();
          e.currentTarget.select();
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case 'ArrowLeft':
              selectPrevious(e.currentTarget);
              break;
            case 'ArrowRight':
              selectNext(e.currentTarget);
              break;
            default:
              e.currentTarget.select();
              break;
          }
        }}
        onKeyUp={(e) => {
          if (e.key === 'Backspace' && e.shiftKey) selectNext(e.currentTarget);
          else if (e.key === 'Backspace') selectPrevious(e.currentTarget);
        }}
        onChange={(e) => {
          props.onChange?.(getValue(containerRef));

          if (pinValuesMatcher.test(e.currentTarget.value)) {
            e.currentTarget.blur();
            selectNext(e.currentTarget);
          } else {
            e.currentTarget.value = '';
            e.currentTarget.select();
          }
        }}
        onPaste={(e) => {
          const clipData = e.clipboardData.getData('Text');
          e.preventDefault();
          setValue(containerRef, clipData);
          props.onChange?.(getValue(containerRef));
        }}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      onMouseUp={(e) => {
        (e.currentTarget.childNodes[0] as HTMLInputElement)?.select();
      }}
      className={classNames(
        styles.container,
        errors && errors.length > 0 ? styles.error : undefined
      )}
    >
      {inputs}
      <ErrorText errors={errors} align="center" />
    </div>
  );
};

const getValue = (ref: RefObject<HTMLElement>): string => {
  const el = ref.current;
  if (!el) return '';

  const inputs = getChildInputElements(el);

  return inputs.map((i) => i.value).join('');
};

const setValue = (ref: RefObject<HTMLElement>, value: string) => {
  const el = ref.current;
  if (!el) return '';

  const inputs = getChildInputElements(el);

  const segments = value.split('');

  inputs.forEach((input, index) => (input.value = segments[index]));
};

const getChildInputElements = (el: HTMLElement): HTMLInputElement[] => {
  return Array.from(el.childNodes).filter(
    (child): child is HTMLInputElement => child instanceof HTMLInputElement
  );
};

const selectNext = (el: HTMLInputElement) => {
  const nextSibling = el.nextSibling;
  if (nextSibling instanceof HTMLInputElement) nextSibling?.select();
};

const selectPrevious = (el: HTMLInputElement) => {
  const previousSibling = el.previousSibling;
  if (previousSibling instanceof HTMLInputElement) previousSibling?.select();
};
