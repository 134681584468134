import Cookie from 'js-cookie';

export const TOKEN_COOKIE_KEY = 'axo_auth';

export interface AuthData {
  JWT: string;
  PersonID?: string;
}

export const setToken = (auth: AuthData) => {
  return Cookie.set(TOKEN_COOKIE_KEY, JSON.stringify(auth));
};

export const getToken = (): AuthData | undefined => {
  const res = Cookie.get(TOKEN_COOKIE_KEY);
  if (!res) {
    return;
  }
  return JSON.parse(res) as AuthData;
};

export const removeToken = () => {
  return Cookie.remove(TOKEN_COOKIE_KEY);
};
