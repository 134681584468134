import { useCustomer, useMagicToken } from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import {
  customer,
  MagicOutput,
  permissions,
} from '@axo/shared/data-access/types';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { useContext, useEffect, useState } from 'react';
import { useAuthDispatch } from '../useAuth';

/**
 *  Handles the magic access Auth with an application specific magic token
 *
 *  loads the application, stores on loan application context, retrieves the jwt, stores on data access context
 *  loads the customer, stores on data access context
 */
export function useApplicationMagicAuthToken(
  magicToken: string | null,
  onSuccess?: ((response: MagicOutput) => void) | null,
  onError?: () => void,
  isInternal = false
) {
  const { state } = useContext(DataAccessContext);
  const { dispatch: appDispatch } = useContext(LoanApplicationContext);
  const { identify } = useAnalytics();
  const { loggedIn } = useAuthDispatch();
  const [customer, setCustomer] = useState<{ id: string }>();

  const _onError = () => {
    onError?.();
  };

  /**
   * `LoanApplication` / `createToken`
   */

  const onSuccessMagicToken = (response: MagicOutput) => {
    // set the application data on the loan application context
    appDispatch({
      type: 'Set application',
      scope: { parentType: 'application' },
      payload: response,
    });

    onSuccess?.(response);
  };

  // the mutateAsync is triggered outside this hook
  const magicTokenResult = useMagicToken(
    magicToken,
    onSuccessMagicToken,
    _onError
  );

  /**
   * `Customer`
   */

  const onSuccessCustomer = async (response: customer.Customer) => {
    await identify({
      uuid: response.ID,
      email: response.Email,
    });

    loggedIn({
      user: {
        id: response.ID,
      },
      authMethod: 'application magic token',
      isInternal,
    });
  };

  const customerResult = useCustomer(customer?.id);

  // only set the `customer.id` for a `Customer` role (avoiding other tokens to trigger)
  useEffect(() => {
    state.user.customerID &&
      state.user.roles.some(
        (role) => role.Name === permissions.RoleName.CustomerVerifiedEmail
      ) &&
      setCustomer({ id: state.user.customerID });
  }, [state.user.customerID, state.user.roles]);

  useEffect(() => {
    if (customerResult.isError) _onError();
    if (customerResult.isSuccess) onSuccessCustomer(customerResult.data);
  }, [customerResult.isError, customerResult.isSuccess]);

  return {
    isLoading: magicTokenResult.isLoading || customerResult.isLoading,
    isError: magicTokenResult.isError || customerResult.isError,
    mutateAsync: magicTokenResult.mutateAsync,
  };
}
