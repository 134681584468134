import { MarketCountryCode } from '@axo/shared/types';
import { ITrustpilot } from '@axo/shared/ui/core';
import { createContext, useContext } from 'react';

export type AllowedMarketCountry = Extract<
  MarketCountryCode,
  | MarketCountryCode.Norway
  | MarketCountryCode.Sweden
  | MarketCountryCode.Finland
>;

export interface ILocaleContext {
  marketCountry: AllowedMarketCountry;
  locale: string;
  trustPilot: Pick<ITrustpilot, 'businessId' | 'templateId' | 'link'>;
  currency: string;
}

export const LocaleContext = createContext<ILocaleContext | null>(null);

// Provide a hook for easy context usage
export function useLocaleContext() {
  const context = useContext(LocaleContext) as ILocaleContext;
  if (!context) {
    throw new Error('useLocaleContext must be used within a LocaleContext');
  }
  return context;
}
