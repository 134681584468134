import { ReactNode, useContext, useEffect } from 'react';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useThrowAsyncError } from '@axo/shared/feature/error-boundary';

export function AppErrorProvider({ children }: { children: ReactNode }) {
  const { state } = useContext(LoanApplicationContext);
  const throwError = useThrowAsyncError();

  useEffect(() => {
    if (state.error) {
      throwError(state.error.error);
    }
  }, [state.error, throwError]);

  return <>{children}</>;
}
