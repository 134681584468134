import { Icons } from '@axo/deprecated/util/ui-components';
import styles from './QuoteInfoItem.module.scss';
import { ReactNode } from 'react';

export function QuoteInfoItem({
  icon = 'filled',
  children,
}: {
  icon?: 'outline' | 'filled';
  children: ReactNode;
}) {
  return (
    <div className={styles.info}>
      {icon === 'filled' && (
        <Icons.CircleInfo
          size="xs"
          color={{ primary: '--dark-500' }}
          classes={{ root: styles.icon }}
        />
      )}
      {icon === 'outline' && (
        <Icons.CircleInfoOutline
          size="xs"
          color={{ primary: '--dark-500' }}
          classes={{ root: styles.icon }}
        />
      )}
      <span>{children}</span>
    </div>
  );
}
