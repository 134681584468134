import decode from 'jwt-decode';
import { useEffect, useRef } from 'react';
import { AxoToken } from '../../../AxoToken.types';
import { IAuthProviderConfig } from '../../../IAuthProviderConfig.types';
import { TProviders } from '../../../IAuthProviders.types';
import {
  clearHashedParameter,
  getHashedParameter,
  getSearchParameter,
} from '../../../utils';

export const useCriiptoProvider = ({
  onError,
  onSuccess,
}: Omit<
  IAuthProviderConfig,
  'serviceSourceUrl' | 'onCanceled' | 'axoApiUrl'
>) => {
  const hasOnSuccessBeenCalled = useRef(false);

  useEffect(() => {
    if (hasOnSuccessBeenCalled.current) return;

    const isCriiptoLogin = getSearchParameter('provider') === 'criipto';

    if (!isCriiptoLogin) return;

    const provider = TProviders.Criipto;

    // Criipto returned token is already signed by Axo, no need to convert to AxoToken
    const token = getHashedParameter('token');

    if (!token) {
      onError({
        errorMessage: 'Failed to get token',
        provider,
      });
      return;
    }

    const axoToken = decode(token) satisfies AxoToken;
    const personID = axoToken?.PersonID;

    onSuccess({
      token,
      provider,
      personID,
    });

    hasOnSuccessBeenCalled.current = true;

    clearHashedParameter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
