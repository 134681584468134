import { useState } from 'react';

export const useThrowAsyncError = () => {
  const [, setState] = useState();

  return (error: Error) => {
    setState(() => {
      throw error;
    });
  };
};

// Credit: https://www.developerway.com/posts/how-to-handle-errors-in-react
