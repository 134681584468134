import { useContext, useEffect, useRef } from 'react';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { useCustomerAnalytics } from '@axo/mypage/util';
import { ApplicationProgressContext } from '@axo/mypage/feature';

export const useApplicationProgressAnalytics = ({
  quotes,
}: {
  quotes: loan_quote_presentation.GetQuotesResponse | undefined;
}) => {
  const {
    state: {
      allBanksResponded,
      currentStep,
      offersUnlocked,
      amountOfOffers,
      isInProgress,
    },
  } = useContext(ApplicationProgressContext);
  const { trackOfferProgress } = useCustomerAnalytics();

  const hasInitialOfferRef = useRef(false);
  const hasOffersUnlockedRef = useRef(false);
  const amountOfOffersRef = useRef(0);

  useEffect(() => {
    if (allBanksResponded) {
      const event =
        amountOfOffers === 0
          ? 'My Page Offer None Notified'
          : 'My Page Offer All Notified';
      trackOfferProgress({
        event,
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
    }

    if (!isInProgress) return;

    if (offersUnlocked && !hasOffersUnlockedRef.current) {
      trackOfferProgress({
        event: 'My Page Offer Selection Unlocked',
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
      hasOffersUnlockedRef.current = true;
    }

    if (amountOfOffers === 1 && !hasInitialOfferRef.current) {
      trackOfferProgress({
        event: 'My Page Offer First Notified',
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
      hasInitialOfferRef.current = true;
    }

    if (amountOfOffers > amountOfOffersRef.current) {
      trackOfferProgress({
        event: 'My Page Offer Notified',
        progress: {
          amountOfOffers,
          currentStep,
        },
      });
      amountOfOffersRef.current = amountOfOffers;
    }
  }, [
    quotes,
    amountOfOffers,
    offersUnlocked,
    isInProgress,
    allBanksResponded,
    currentStep,
  ]);
};
