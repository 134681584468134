import { FeedbackState } from '@axo/ui-core/models/feedback-state.const.ts';
import { THeaderInfoState } from './HeaderInfo.types';

type TStateProperties = {
  [key in THeaderInfoState]: {
    imageSrc: string;
    imageSrcSet?: string;
    theme: FeedbackState;
    isContactUsButtonVisible: boolean;
    isActionButtonVisible: boolean;
    actionButtonText?: string;
    contactUsLabel?: string;
    title: string;
    description: string;
  };
};

export const stateProperties: TStateProperties = {
  [THeaderInfoState.loading]: {
    theme: 'neutral',
    imageSrc: '',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: '',
    description: '',
  },
  [THeaderInfoState.applicationIncomplete]: {
    imageSrc: '/images/header-info/man-on-date-site.svg',
    theme: 'error',
    isContactUsButtonVisible: false,
    isActionButtonVisible: true,
    // TODO: Still need translations, translation key will come here instead of hardcoded string
    actionButtonText: 'Go to application',
    title: 'Complete your application',
    description:
      'Complete your application to receive offers. lick the button below to get to the application form.',
  },
  [THeaderInfoState.applicationSubmitted]: {
    imageSrc: '/images/header-info/happy-man-success.png',
    imageSrcSet:
      '/images/header-info/happy-man-success.png 1x, /images/header-info/happy-man-success@2x.png 2x, /images/header-info/happy-man-success@3x.png 3x',
    theme: 'neutral',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Your application has been successfully submitted',
    description:
      'Our bank partners are carefully reviewing your details and will make a decision in a matter of minutes.',
  },
  [THeaderInfoState.applicationCreditCheckOngoing]: {
    imageSrc: '/images/header-info/network-workflow.svg',
    theme: 'info',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Please wait for banks to process your application',
    description:
      'We have received your application and are working on registering it with the banks. The process is quick, it will take under 1 minute for banks to receive your application.',
  },
  // TODO
  [THeaderInfoState.applicationCreditCheckTimeout]: {
    imageSrc: '/images/header-info/young-women-headphones.svg',
    theme: 'warn',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'We will contact you',
    description:
      'We are taking longer than expected to process your application. Don’t worry, we will send you a text message as soon as you receive your first offer.',
  },
  [THeaderInfoState.applicationGrooming]: {
    imageSrc: '/images/header-info/looking-through-resumes.svg',
    theme: 'warn',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Your application is being worked on',
    description:
      'We have received your application and are working to give you the best offer.',
  },
  [THeaderInfoState.applicationExpiredOrCancelled]: {
    imageSrc: '/images/header-info/young-women-pointing.svg',
    theme: 'warn',
    isContactUsButtonVisible: false,
    isActionButtonVisible: true,
    // TODO: Still need translations, translation key will come here instead of hardcoded string
    actionButtonText: 'Go to application',
    title: 'Start a new application',
    description:
      'You have no active application, it has either expired or has been canceled. Click the button below to get to the application form and start a new application.',
  },
  [THeaderInfoState.applicationNoOffers]: {
    imageSrc: '/images/header-info/data-analysis.svg',
    theme: 'neutral',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Check for alternatives ',
    description:
      'The banks have processed your application, but unfortunately have not made any offers. Check the option below on how to improve your application.',
  },
  [THeaderInfoState.applicationDuplicate]: {
    imageSrc: '/images/header-info/convert-megaphone.svg',
    theme: 'success',
    isContactUsButtonVisible: true,
    isActionButtonVisible: true,
    actionButtonText: 'Login for existing offers',
    contactUsLabel: 'Contact us',
    title: 'Check your existing offers',
    description:
      'You already have an active application with us. Not satisfied with the offers or do you want to make changes to the application? Please contact us or check your existing offers.',
  },
  [THeaderInfoState.applicationInProgress]: {
    imageSrc: '/images/header-info/man-reading-newspaper.svg',
    theme: 'info',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Wait for banks response',
    description:
      'We are still waiting for a response from some banks, and it may pay to wait before choosing an offer. We will send you an SMS as soon as the application has been processed.',
  },
  [THeaderInfoState.applicationInProgressValidOffer]: {
    imageSrc: '/images/header-info/business-account.svg',
    theme: 'success',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Choose an offer',
    description:
      'Your application has been processed and it is now time to choose an offer.',
  },
  [THeaderInfoState.applicationOfferAccepted]: {
    imageSrc: '/images/header-info/signing-contract.svg',
    theme: 'success',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Sign the loan agreement',
    description:
      "To complete the signing and receive the payment, you continue on the bank's website. Click on the “Choose” button to continue.",
  },
  [THeaderInfoState.applicationOfferCancelled]: {
    imageSrc: '/images/header-info/business-account.svg',
    theme: 'success',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Choose a new offer',
    description:
      'The selected offer is no longer valid, but you have other options. Select a new offer from the list below.',
  },
  [THeaderInfoState.applicationOfferAutoAccepted]: {
    imageSrc: '/images/header-info/signing-contract.svg',
    theme: 'success',
    isContactUsButtonVisible: true,
    isActionButtonVisible: false,
    contactUsLabel: 'Contact us',
    title: 'Sign the loan agreement',
    description:
      "We have chosen the offer we think suits you best. To complete the signing and receive the payment, continue on the bank's website. If you want another loan offer, you can Contact us, and we will help you.",
  },
  [THeaderInfoState.applicationSingleOfferAutoAccepted]: {
    imageSrc: '/images/header-info/signing-contract.svg',
    theme: 'success',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Sign the loan agreement',
    description:
      "We have selected the loan offer you received. To complete the signing and receive the payment, continue on the bank's website.",
  },
  [THeaderInfoState.applicationHelp]: {
    imageSrc: '/images/header-info/young-women-headphones.svg',
    theme: 'warn',
    isContactUsButtonVisible: true,
    contactUsLabel: 'Contact us',
    isActionButtonVisible: false,
    title: 'Ask for help',
    description:
      'The selected offer is no longer valid. It may be because the bank has refused after they have checked the documents, or that you have chosen to decline. Please contact us if you have any questions.',
  },
  [THeaderInfoState.applicationOfferCancelledNextAutoAccepted]: {
    imageSrc: '/images/header-info/signing-contract.svg',
    theme: 'success',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Sign the loan agreement for your new offer',
    description:
      "The selected offer is no longer valid. We have chosen another loan offer that we think may be suitable for you. Continue on the bank's website to sign and have the loan disbursed.",
  },
  [THeaderInfoState.applicationDisbursed]: {
    imageSrc: '/images/header-info/online-shopping.svg',
    theme: 'success',
    isContactUsButtonVisible: false,
    isActionButtonVisible: false,
    title: 'Thank you for choosing AxoFinans',
    description: 'Your loan has been paid out',
  },
};
