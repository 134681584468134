import { ReactNode } from 'react';
import classNames from '../../../utils/class-names';
import Button from '../../atoms/Button';
import { Icons } from '../../atoms/Icons';
import { IIconColor } from '../../atoms/Icons/IconWrapper';
import { H3 } from '../../atoms/StyledElements';
import styles from './Toast.module.scss';

export type IVariant = 'error' | 'info' | 'warning' | 'success';

const varietyIconColor: Record<IVariant, IIconColor> = {
  error: { primary: '--secondary-700', secondary: 'none' },
  info: { primary: '--info-700', secondary: 'none' },
  warning: { primary: '--warning-700', secondary: 'none' },
  success: { primary: '--success-700', secondary: 'none' },
};

export type IToast = {
  dismiss?: () => void;
  header?: ReactNode;
  children?: ReactNode;
  variant?: IVariant;
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const Toast = ({
  children,
  dismiss,
  header,
  variant = 'error',
}: IToast) => (
  <div className={classNames(styles.container, styles[variant])} role="dialog">
    <div className={classNames(styles.content)}>
      <div className={styles.iconContainer}>
        <Icons.CircleInfo size="md" color={varietyIconColor[variant]} />
      </div>
      <div className={styles.textContainer}>
        <H3 className={styles[`${variant}Header`]}>{header}</H3>
        {children}
      </div>
      {dismiss && (
        <div className={styles.closeButtonContainer}>
          <Button
            variant="secondary"
            onClick={dismiss}
            classes={{ root: styles.button }}
            endIcon={
              <Icons.CloseButton
                color={{ primary: 'none', secondary: '--dark-500' }}
              />
            }
          />
        </div>
      )}
    </div>
  </div>
);

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export default Toast;
