import { loan_quote_accept } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export function getLoanQuoteAccept({ url: { service }, token }: IAPI) {
  return async function getLoanQuoteAccept(id: string) {
    const url = new URL(`/loan-quote-accept/accept/${id}`, service);
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: token,
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not OK: ${response.status}`);
    }
    return (await response.json()) as loan_quote_accept.LoanQuoteAccept;
  };
}
