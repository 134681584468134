import { getTranslation } from '@axo/shared/util/translation';
import { useTranslationContext } from './context';
import { translationsObj } from './translations';

export function useTranslation() {
  const { language, showDebugErrors } = useTranslationContext();
  return getTranslation<typeof translationsObj, typeof language>({
    translations: translationsObj,
    preferredLanguage: language,
    showDebugErrors: showDebugErrors,
  });
}
