import classNames from 'clsx';
import { useMemo } from 'react';

import { Icon } from '@axo/ui-core/components/Icon';
import styles from './ProgressBarWithDescription.module.scss';
import clsx from 'clsx';

export type TState = 'neutral' | 'warn' | 'success';

export type TProgressBarWithDescriptionProps = {
  title?: string;
  description?: string;
  progress: number;
  state: TState;
  className?: string;
};

export const ProgressBarWithDescription = ({
  title,
  description,
  progress = 0,
  state = 'neutral',
  className,
}: TProgressBarWithDescriptionProps) => {
  const { _progress, isAnimated } = useMemo(
    () => ({
      _progress: Math.min(Math.max(progress, 0), 100),
      isAnimated: progress < 100,
    }),
    [progress]
  );

  const isCompleted = progress === 100;
  const isTextContentVisible = title || description || isCompleted;

  return (
    <div
      className={classNames(styles.progressBarWithDescription, className)}
      data-state={state}
    >
      {isTextContentVisible && (
        <div className={styles.textContainer}>
          {(isCompleted || title) && (
            <div className={styles.title}>
              {isCompleted && (
                <Icon name="check-double" size="xs" className={styles.icon} />
              )}
              {title}
            </div>
          )}
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      )}
      <div className={styles.progressContainer}>
        <div className={clsx(styles.progress, !!progress && styles.hasProgress)} style={{ width: `${_progress}%` }} />
      </div>
      {isAnimated && (
        <div
          className={classNames(styles.animation, styles[`animation-${state}`])}
        />
      )}
    </div>
  );
};
