import { MyPageFlags } from '@axo/mypage/util';
import { useEffect, useMemo } from 'react';
import { useNavigation } from '../../Navigation/useNavigation';
import { useAuthDispatch, useAuthState } from '../useAuth';
import { useFlags } from '@axo/shared/services/feature-flags/useFlags';

const redirectToSite = () => {
  window.location.href = import.meta.env.VITE_APP_UNAUTH_REDIRECT_URL;
};

export const useUnAuthRedirection = () => {
  const { isUnAuthenticating } = useAuthState();
  const { loggedOut } = useAuthDispatch();
  const { navigateToLogin: redirectToLogin } = useNavigation();
  const { flag_show_bankID_login, flag_show_otp_login } = useFlags(MyPageFlags);

  const loginPageShown = flag_show_bankID_login || flag_show_otp_login;

  const handleRedirection = useMemo(
    () => (loginPageShown ? redirectToLogin : redirectToSite),
    [loginPageShown, redirectToLogin]
  );

  useEffect(() => {
    if (isUnAuthenticating) {
      handleRedirection();
      loggedOut();
    }
  }, [isUnAuthenticating, handleRedirection, loggedOut]);

  return null;
};
