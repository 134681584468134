import { useEffect } from 'react';
import { useWSSubscriberContext } from './context';
import { ISubscription } from './types';

/**
 * Custom hook to subscribe to a list of WebSocket topics.
 *
 * @param subscriptions - An array of objects describing each subscription.
 * Each object should contain a 'topic' to subscribe to and a 'callback' function
 * that will be executed with the incoming message data.
 *
 * @returns A cleanup function that unsubscribes from all topics upon unmounting.
 */
export function useWSSubscriber(subscriptions: ISubscription[]) {
  const { subscribe } = useWSSubscriberContext();

  useEffect(() => {
    const unsubscribers = subscriptions.map((subscription) =>
      subscribe({
        subscriberCallback: (message) =>
          subscription.subscriberCallback(message),
        source: subscription.source,
        code: subscription.code,
      })
    );

    // Return a single cleanup function that runs all unsubscribers
    return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
  }, [subscribe, subscriptions]);
}
