import {
  rateFormatter,
  useDurationFormatter,
} from '@axo/mypage/util/formatters';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { currencyFormatter } from '@axo/shared/util/formatters';

type TGetLoanQuoteDetailValues = {
  quote: loan_quote_presentation.EnrichedLoanQuote;
  currency: string;
  durationFormatter: ReturnType<typeof useDurationFormatter>;
};

export const getLoanDetailValues = ({
  quote,
  currency,
  durationFormatter,
}: TGetLoanQuoteDetailValues) => {
  const subLoanQuote = quote.SubLoanQuote;

  const amount = quote.Amount + (subLoanQuote?.Amount ?? 0);
  const monthlyPay = quote.MonthlyPayment + (subLoanQuote?.MonthlyPayment ?? 0);

  return {
    quoteAmount: currencyFormatter(amount, currency),
    duration: durationFormatter(quote.Duration, quote.DurationMonths),
    nominalInterestRate: rateFormatter(quote.NominalRate || 0),
    effectiveInterestRate: rateFormatter(quote.EffectiveRate || 0),
    loanCost: currencyFormatter(quote.StartupFee, currency),
    monthlyPay: currencyFormatter(monthlyPay, currency),
    startupFee: currencyFormatter(quote.StartupFee, currency),
    administrationFee: currencyFormatter(quote.AdministrationFee, currency),
  };
};
