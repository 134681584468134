/* eslint-disable @nx/enforce-module-boundaries */
import { useTranslation } from '@axo/mypage/util/translation';
import { useAnalytics } from '@axo/shared/services/analytics';
import { classNames } from '@axo/shared/util/dom';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './InsuranceOffer.module.scss';

export const insuranceOfferDeclinedKey = 'insuranceOfferDeclined';

type TInsuranceOfferProps = {
  onAcceptInsurance?: () => void;
  onDeclineInsurance?: () => void;
};

const InsuranceOffer = ({
  onAcceptInsurance,
  onDeclineInsurance,
}: TInsuranceOfferProps) => {
  const insuranceDeclined = sessionStorage.getItem(insuranceOfferDeclinedKey);
  const isComponentViewEventSent = useRef(false);

  const { track } = useAnalytics();
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(insuranceDeclined === 'true');

  useEffect(() => {
    if (isComponentViewEventSent.current) return;

    isComponentViewEventSent.current = true;

    track({
      event: 'Insurance Offer Viewed',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onAcceptInsurance = useCallback(() => {
    track({
      event: 'Insurance Offer Accepted',
    });

    onAcceptInsurance?.();
  }, [track, onAcceptInsurance]);

  const _onDeclineInsurance = useCallback(() => {
    track({
      event: 'Insurance Offer Declined',
    });

    setIsDisabled(true);
    sessionStorage.setItem(insuranceOfferDeclinedKey, 'true');

    onDeclineInsurance?.();
  }, [track, onDeclineInsurance]);

  const _onLearnMore = useCallback(() => {
    track({
      event: 'Insurance Offer Learn More',
    });

    sessionStorage.setItem(insuranceOfferDeclinedKey, 'false');
    setIsDisabled(false);
  }, [track]);

  return (
    <div
      className={classNames(
        styles.insuranceOffer,
        isDisabled && styles.disabled
      )}
    >
      <div className={styles.tag}>{t('Popular')}</div>
      <div className={styles.header}>
        <div className={styles.left}>
          <Icon name="shield-solid" size="l" className={styles.icon} />
          <span className={styles.text}>{t('Axolaina Insurance')}</span>
        </div>
        <div className={styles.right}>
          {!isDisabled ? (
            <>
              <Button
                variant="primary"
                className={classNames(styles.button)}
                onClick={_onAcceptInsurance}
              >
                {t('Get Insurance')}
              </Button>
              <Button
                variant="ghost"
                className={classNames(styles.button, styles.buttonDecline)}
                onClick={_onDeclineInsurance}
              >
                {t("I don't want insurance")}
              </Button>
            </>
          ) : (
            <Button
              variant="tertiary"
              className={classNames(styles.button, styles.buttonDecline)}
              onClick={_onLearnMore}
            >
              {t('Learn more about insurance')}
            </Button>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.content, isDisabled && styles.disabled)}
      >
        <div className={styles.description}>
          <p>{t('Expense insurance can give you...')}</p>
        </div>
        <ul className={styles.advantages}>
          <li className={styles.advantage}>
            <Icon name="money-multiple" size="s" className={styles.icon} />
            <span className={styles.text}>{t('Monthly payment up to...')}</span>
          </li>
          <li className={styles.advantage}>
            <Icon
              name="buoy"
              size="s"
              className={classNames(styles.icon, styles.fill)}
            />
            <span className={styles.text}>
              {t('The money is paid directly...')}
            </span>
          </li>
          <li className={styles.advantage}>
            <Icon name="shield" size="s" className={styles.icon} />
            <span className={styles.text}>
              {t('You can cancel your insurance at any time')}
            </span>
          </li>
          <li className={styles.advantage}>
            <Icon name="earth" size="s" className={styles.icon} />
            <span className={styles.text}>{t('In event of death...')}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InsuranceOffer;
