import * as Tabs from '@radix-ui/react-tabs';
import styles from './SectionTabs.module.scss';
import { classNames } from '@axo/shared/util/dom';

export const SectionTabs = {
  Root: ({ className, ...props }: Tabs.TabsProps) => (
    <Tabs.Root className={classNames(styles.root, className)} {...props} />
  ),
  List: ({ className, ...props }: Tabs.TabsListProps) => (
    <Tabs.List className={classNames(styles.list, className)} {...props} />
  ),
  Trigger: ({ className, ...props }: Tabs.TabsTriggerProps) => (
    <Tabs.Trigger
      className={classNames(styles.trigger, className)}
      {...props}
    />
  ),
  Content: ({ className, ...props }: Tabs.TabsContentProps) => (
    <Tabs.Content
      className={classNames(styles.content, className)}
      {...props}
    />
  ),
};
