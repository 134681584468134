import { customer_api } from '@axo/shared/data-access/api';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { customerKeys, parseCustomerKey } from '../cache-keys/customerKeys';
import { queryFunction } from '../query-function';

export function useCustomer(
  customerID: string | null | undefined,
  customToken?: string | null | undefined
) {
  const { state } = useContext(DataAccessContext);
  const _api = useAPI();
  const api = {
    ..._api,
    token: customToken ?? _api.token,
  };
  const cacheKey = customerKeys.root({
    customerID,
  });

  return useQuery(
    cacheKey,
    queryFunction(
      customer_api.get(api),
      ({ queryKey }) => parseCustomerKey(queryKey).customerID ?? ''
    ),
    {
      // enabled: state.user.token.length > 0 && !!customerID,
      enabled: api.token.length > 0 && !!customerID,
    }
  );
}
