const BASE_URL = import.meta.env.VITE_APP_API_URL;

export async function getLoginOtp(email: string): Promise<any> {
  const url = new URL(`/otp`, BASE_URL);
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      throw new Error('Failed to get otp');
    }
    return await response.json();
  } catch (e) {
    throw new Error('Failed to get otp');
  }
}

export async function verifyLoginOtp({
  code,
  RequestID,
}: {
  code: string;
  RequestID: string;
}): Promise<any> {
  const url = new URL(`/otp/verify`, BASE_URL);
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, RequestID }),
    });

    if (!response.ok) {
      console.log(response.body);
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch {
    throw new Error('Failed to verify otp');
  }
}
