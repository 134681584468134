import { ContentBox, Icons } from '@axo/deprecated/util/ui-components';
import styles from './QuoteItemContainer.module.scss';
import { ReactNode } from 'react';
import { classNames } from '@axo/shared/util/dom';

export enum QuoteState {
  Recommended,
  Selected,
  PreviouslySelected,
  Disbursed,
  None,
  Cancelled,
  Rejected,
}

interface ILoanQuoteListItem {
  state: QuoteState;
  children: ReactNode;
  getText: (state: QuoteState) => string;
}

export function QuoteItemContainer({
  state,
  children,
  getText,
}: ILoanQuoteListItem) {
  const getIcon = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return <Icons.ThumbUp size="xs" />;
      case QuoteState.Selected:
        return <Icons.CheckMarkWhite size="xs" />;
      case QuoteState.PreviouslySelected:
        return <Icons.Archive size="xs" />;
      case QuoteState.Disbursed:
        return <Icons.ThumbUp size="xs" />;
      case QuoteState.Cancelled:
        return <Icons.Archive size="xs" />;
      case QuoteState.Rejected:
        return <Icons.Archive size="xs" />;
      default:
        return '';
    }
  };

  const getColour = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return '--success-500';
      case QuoteState.Selected:
        return '--info-700';
      case QuoteState.PreviouslySelected:
        return '--light-700';
      case QuoteState.Disbursed:
        return '--success-500';
      case QuoteState.Cancelled:
        return '--light-700';
      case QuoteState.Rejected:
        return '--light-700';
      default:
        return '--success-500';
    }
  };

  const hasBackgroundColor = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Cancelled:
        return true;
      case QuoteState.Rejected:
        return true;
      default:
        return false;
    }
  };

  const hasBorder = (state: QuoteState) => {
    switch (state) {
      case QuoteState.Recommended:
        return true;
      case QuoteState.Selected:
        return true;
      case QuoteState.PreviouslySelected:
        return false;
      case QuoteState.Disbursed:
        return true;
      default:
        return false;
    }
  };

  return (
    <ContentBox
      classes={{
        root: classNames(
          styles.contentBox,
          hasBackgroundColor(state) && styles.cancelled
        ),
        tag: styles.recommendedTag,
      }}
      tagText={`${getText(state)}`}
      tagIcon={getIcon(state)}
      active={hasBorder(state)}
      activeColour={getColour(state)}
    >
      {children}
    </ContentBox>
  );
}
