import { createContext, Dispatch, ReactNode, useReducer } from 'react';
import {
  THeaderInfoState,
  THeaderInfoProps,
} from '@axo/ui-feature/components/HeaderInfo/HeaderInfo.types';
import { useTranslation } from '@axo/mypage/util/translation';

export type StatusData = Omit<THeaderInfoProps, 'className'>;

type State = StatusData;
type Action = {
  type: 'Set status';
  payload: StatusData;
};

const initialState: State = {
  state: THeaderInfoState.loading,
};

export const ApplicationStatusContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'Set status':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const ApplicationStatusProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    title: t('Please wait'),
    description: t(
      'We have received your application and are awaiting responses'
    ),
  });
  return (
    <ApplicationStatusContext.Provider value={{ state, dispatch }}>
      {children}
    </ApplicationStatusContext.Provider>
  );
};
