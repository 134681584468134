import styles from './Loading.module.scss';
import { LoadingSpinner } from '@axo/deprecated/util/ui-components';

export const Loading = () => {
  return (
    <div className={styles.spinnerContainer} role="progressbar">
      <LoadingSpinner colorVariable="--primary-500" />
    </div>
  );
};
