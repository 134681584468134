import {
  DataAccessContext,
  isJwtExpired,
} from '@axo/shared/data-access/provider';
import * as Sentry from '@sentry/browser';
import { useContext, useEffect } from 'react';
import { useAuthDispatch } from '../useAuth';

export const useJWTExpiration = () => {
  const { state } = useContext(DataAccessContext);
  const { token, tokenExpiration } = state.user;
  const { error } = state;
  const { logout } = useAuthDispatch();

  useEffect(() => {
    if (!token || !tokenExpiration) return;

    const currentTime = Date.now() / 1000;

    if (tokenExpiration > currentTime) {
      const timeoutDuration = (tokenExpiration - currentTime) * 1000;
      const timeout = setTimeout(() => {
        Sentry.captureMessage(
          'JWT expired - automatically logged out',
          'warning'
        );
        logout();
      }, timeoutDuration);

      return () => clearTimeout(timeout);
    }
  }, [token, tokenExpiration]);

  useEffect(() => {
    if (error && error === '401 Unauthorized' && isJwtExpired(token)) {
      Sentry.captureMessage(
        '401 Unauthorized due to expired JWT - User logged out',
        'warning'
      );
      logout();
    }
    if (error && error === 'JWT expired') {
      Sentry.captureMessage('JWT expired - User logged out', 'warning');
      logout();
    }
  }, [error, token]);

  return null;
};
