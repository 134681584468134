import { Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '@axo/mypage/util/translation';
import { IAuthProviderResponse } from '@axo/shared/auth/providers';
import { BankIdLogin } from '@axo/shared/auth/providers/components/bankId';
import { useAPI } from '@axo/shared/data-access/provider';
import { useToastActions } from '@axo/shared/feature/providers';
import { Card } from '@axo/shared/ui/core';
import { Stack } from '@axo/ui-core/components/layout/item';
import { noop } from 'lodash';
import { useCallback } from 'react';

const serviceSourceUrl = import.meta.env.VITE_APP_ASSENTLY_URL as string;

export const BankIDCard = ({
  setAuthToken,
}: {
  setAuthToken: (token: string, personID?: string) => void;
}) => {
  const api = useAPI();
  const { displayToast } = useToastActions();
  const { t } = useTranslation();

  const handleError = useCallback(() => {
    displayToast({
      header: t('There has been an error'),
      content: t('There has been an error with BankID, please try again'),
      variety: 'error',
    });
  }, [displayToast, t]);

  const handleOnSuccess = useCallback(
    ({ token, personID }: IAuthProviderResponse) => {
      if (!token || !personID) return handleError();

      setAuthToken(token, personID);
    },
    [setAuthToken, handleError]
  );

  return (
    <Card title={t('Login with BankID')} icon={<Icons.BankID size="xlg" />}>
      <Stack gap={'xs'}>
        <p>{t('Use BankID to log in and check your offers on MyPage')}</p>
        <p>{t("Don't have BankID? Contact customer service at")}</p>
        <BankIdLogin
          label={t('Login with BankID')}
          axoApiUrl={api.url.service}
          serviceSourceUrl={serviceSourceUrl}
          onSuccess={handleOnSuccess}
          onError={handleError}
          onCanceled={noop}
          onStart={noop}
        />
      </Stack>
    </Card>
  );
};
