import { useQuery } from 'react-query';
import { useAPI } from '@axo/shared/data-access/provider';
import {
  applicationKeys,
  parseApplicationKey,
} from '../cache-keys/applicationKeys';
import { loan_quote_presentation_api } from '@axo/shared/data-access/api';
import { queryFunction } from '../query-function';

export function useLoanQuotePresentation(applicationID: string | undefined) {
  const api = useAPI();
  const cacheKey = applicationKeys.loanQuotes({
    applicationID,
  });

  return useQuery(
    cacheKey,
    queryFunction(
      loan_quote_presentation_api.getLoanQuotes(api),
      ({ queryKey }) => parseApplicationKey(queryKey).applicationID
    ),
    {
      enabled: !!applicationID,
    }
  );
}
