import { Languages } from '@axo/shared/util/translation';
import { createContext, useContext } from 'react';

export type ITranslationContext = {
  language: Languages;
  showDebugErrors: boolean;
};

export const TranslationContext = createContext<ITranslationContext | null>(
  null
);

// Provide a hook for easy context usage
export function useTranslationContext() {
  const context = useContext(TranslationContext) as ITranslationContext;
  if (!context) {
    throw new Error(
      'useTranslationContext must be used within a ITranslationContext'
    );
  }
  return context;
}
