import styles from './Card.module.scss';
import { ReactNode } from 'react';
import { H3 } from '@axo/deprecated/util/ui-components';
import { classNames } from '@axo/shared/util/dom';

export const Card = ({
  children,
  icon,
  title,
  className,
}: {
  children: ReactNode;
  icon?: ReactNode;
  title: string;
  className?: string;
}) => {
  return (
    <div className={classNames(styles.card, className)}>
      <div className={styles.title}>
        {icon}
        <H3>{title}</H3>
      </div>
      <div>{children}</div>
    </div>
  );
};
